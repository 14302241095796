
import React, { useState } from "react";
import { connect } from 'react-redux';
import { addNewManager } from "actions/profileActions";
import { useHistory } from "react-router-dom";

// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    Label,
    FormGroup,
    Form,
    Input,
    FormText,
    Row,
    Col,
} from "reactstrap";

// core components
import PanelHeader from "components/PanelHeader/PanelHeader.js";



const AddManager = ({ addNewManager }) => {
    const history = useHistory();


    const [formData, setFormData] = useState({
        fname: '',
        lname: '',
        email: '',
        address: '',
        post_code: '',
        town: '',
        phone_no: '',
        mobile_no: '',
        password: ''


    })

    const { fname, lname, address, post_code, mobile_no, phone_no, email, town, password } = formData;
    const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value })

    const onSubmit = e => {
        e.preventDefault();

        var formData = new FormData();
        formData.append("company_id", '1');
        formData.append("fname", fname);
        formData.append("lname", lname);
        formData.append("address", address);
        formData.append("post_code", post_code);
        formData.append("mobile_no", mobile_no);
        formData.append("phone_no", phone_no);
        formData.append("email", email);
        formData.append("town", town);
        formData.append("password", password);
        addNewManager(formData, history)

        setFormData({

            fname: '',
            lname: '',
            email: '',
            address: '',
            post_code: '',
            town: '',
            phone_no: '',
            mobile_no: '',
            password: ''

        })


    }


    return (
        <>
            <PanelHeader size="sm" />
            <div className="content">
                <Row>
                    <Col md="12">
                        <Card>
                            <CardHeader>
                                <CardTitle tag="h5">Add Manager</CardTitle>
                            </CardHeader>
                            <CardBody>
                                <Form onSubmit={e => onSubmit(e)} encType="multipart/form-data">
                                    <Row>
                                        <Col md="6">
                                            <label>First Name</label>
                                            <FormGroup>
                                                <Input type="text"
                                                    name="fname"
                                                    value={fname}
                                                    onChange={onChange}




                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md="6">
                                            <label>Last Name</label>
                                            <FormGroup>
                                                <Input type="text"
                                                    name="lname"
                                                    value={lname}
                                                    onChange={onChange}


                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md="12">
                                            <label>Address</label>
                                            <FormGroup>
                                                <Input type="text" name="address"
                                                    value={address}
                                                    onChange={onChange} />
                                            </FormGroup>
                                        </Col>
                                        <Col md="3">
                                            <label>Town</label>
                                            <FormGroup>
                                                <Input type="text" name="town"
                                                    value={town}
                                                    onChange={onChange} />
                                            </FormGroup>
                                        </Col>
                                        <Col md="3">
                                            <label>Postal Code</label>
                                            <FormGroup>
                                                <Input type="number" name="post_code"
                                                    value={post_code}
                                                    onChange={onChange} />
                                            </FormGroup>
                                        </Col>
                                        <Col md="3">
                                            <label>Telephone</label>
                                            <FormGroup>
                                                <Input type="number" name="phone_no"
                                                    value={phone_no}
                                                    onChange={onChange} />
                                            </FormGroup>
                                        </Col>
                                        <Col md="3">
                                            <label>Contact Number</label>
                                            <FormGroup>
                                                <Input type="text" name="mobile_no"
                                                    value={mobile_no}
                                                    onChange={onChange} />
                                            </FormGroup>
                                        </Col>
                                        <Col md="6">
                                            <label>Email</label>
                                            <FormGroup>
                                                <Input type="email" name="email"
                                                    value={email}
                                                    onChange={onChange} />
                                            </FormGroup>
                                        </Col>
                                        <Col md="6">
                                            <label>Password</label>
                                            <FormGroup>
                                                <Input type="password" name="password"
                                                    value={password}
                                                    onChange={onChange} />
                                            </FormGroup>
                                        </Col>

                                        {/* <Col md="12">
                                            <label htmlFor="">Notes</label>
                                            <textarea name="" id="" cols="30" rows="10" className="form-control border rounded">

                                            </textarea>
                                        </Col> */}
                                    </Row>
                                    <div className="text-center">
                                        <Button className="btn-fill" color="info" type="submit">
                                            Add Manager
                                        </Button>
                                    </div>


                                </Form>
                            </CardBody>


                        </Card>
                    </Col>

                </Row>
            </div>
        </>
    );
}
const mapStateToProps = state => ({
    newManager: state.profileReducer.newManager
})
export default connect(mapStateToProps, { addNewManager })(AddManager);
