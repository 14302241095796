
import React, {
  useState,
  useEffect
} from "react";
import {
  useHistory
} from "react-router-dom";
import SweetAlert from "react-bootstrap-sweetalert";
import {
  removeSweetAlert
} from "../../actions/profileActions";
import InputMask from 'react-input-mask';
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Form,
  Input,
  FormGroup,
} from "reactstrap";
import PanelHeader from "components/PanelHeader/PanelHeader.js";
import {
  connect
} from 'react-redux';
import {
  updateAdminProfile,
  updateProfilePicture,
} from '../../actions/profileActions';
import { postalCodeRegex } from "config/constants";
import { postalCodeErrTxt } from "config/constants";

const EditProfile = ({
  updateAdminProfile,
  updateProfilePicture,
  user,
  isLoading,
  successMsg,
  sweetAlert,
  removeSweetAlert
}) => {

  console.log("user===>", user)

  const history = useHistory();
  const singleFileRef = React.useRef();

  const handleSingleFileInput = (e) => {
    singleFileRef.current.click(e);
  };
  const [loadingSpinner, setLoadingSpinner] = useState(false)

  useEffect(() => {

    setLoadingSpinner(isLoading)

  }, [isLoading])

  const [alert, setAlert] = React.useState(null);
  React.useEffect(() => {
    return function cleanup() {
      var id = window.setTimeout(null, 0);
      while (id--) {
        window.clearTimeout(id);
      }
    };
  }, []);

  useEffect(() => {
    if (sweetAlert) {

      successAlert()
    } else {
      hideAlert()
    }
  }, [sweetAlert])

  const successAlert = () => {
    setAlert(
      <SweetAlert
        success
        style={{ display: "block", marginTop: "-100px" }}
        title="Success!"
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        confirmBtnBsStyle="info"
      >
        {successMsg}
      </SweetAlert>
    );
  }
  const hideAlert = () => {
    setAlert(null);
    removeSweetAlert()
  };


  const [formData, setFormData] = useState({
    fname: '',
    lname: '',
    address: '',
    post_code: '',
    post_code: '',
    mobile_no: '',
    email: '',
    pic: '',
    town: '',
    notes: '',
    status: '1',
    phone_no: '',
    password: '',
    c_password: ''
  })
  const [errors, setErrors] = useState({})

  var {
    fname,
    lname,
    address,
    post_code,
    mobile_no,
    phone_no,
    email,
    pic,
    town,
    notes,
    status,
    password,
    c_password
  } = formData;


  const onChange = e => {

    if (e.target.name == "post_code") {

      const result
        = postalCodeRegex
          .test(
            e.target.value
          )

      if (!result) {
        setFormData((prev) => ({
          ...prev,
          [e.target.name]:
            null
        }))
        setErrors((prev) => ({
          ...prev,
          [e.target.name]:
            postalCodeErrTxt
        }))
      } else {
        setFormData((prev) => ({
          ...prev,
          [e.target.name]:
            e.target.value
        }))
        setErrors((prev) => ({
          ...prev,
          [e.target.name]:
            null
        }))

      }
    } else
      setFormData((prev) => ({
        ...prev,
        [e.target.name]:
          e.target.value
      }))
  }

  const handleImageUpdate = (e) => {
    var formdata = new FormData();
    formdata.append("attachment", e.target.files[0]);

    if (e.target.files[0])
    updateProfilePicture(formdata, history)
  }

  const onSubmit = e => {
    e.preventDefault();

    if (password === c_password) {

      if (fname === '') {
        fname = user.fname
      }
      if (lname === '') {
        lname = user.lname
      }
      if (address === '') {
        address = user.address
      }
      if (post_code === '') {
        post_code = user.post_code
      }
      if (mobile_no === '') {
        mobile_no = user.mobile_no
      }
      if (phone_no === '') {
        phone_no = user.phone_no
      }
      if (email === '') {
        email = user.email
      }
      if (town === '') {
        town = user.town
      }
      if (notes === '') {
        notes = user.notes
      }

      if (status === '') {
        status = user.status
      }
      if (pic === '') {
        pic = user?.attachment
      }




      var formdata = new FormData();
      formdata.append("fname", fname);
      formdata.append("lname", lname);
      formdata.append("address", address);
      formdata.append("post_code", post_code);
      formdata.append("mobile_no", mobile_no);
      formdata.append("phone_no", mobile_no);
      formdata.append("email", email);
      formdata.append("attachment", pic);
      formdata.append("town", town);
      formdata.append("notes", notes);
      formdata.append("status", status);
      formdata.append("password", password);



      updateAdminProfile(formdata, history)

      setFormData({
        fname: '',
        lname: '',
        address: '',
        post_code: '',
        post_code: '',
        mobile_no: '',
        email: '',
        pic: '',
        town: '',
        notes: '',
        status: '1',
        phone_no: '',
        password: '',
        c_password: ''
      })

    } else {
      window.alert('Passwords don\'t match')
    }



  }

  return (
    <>
      <PanelHeader size="sm" />
      <div className="content">
        {alert}
        <Form onSubmit={e => onSubmit(e)} encType="multipart/form-data">

          <Row>
            <Col md="8" className="">
              <Card>
                <CardHeader>
                  <h5 className="title">Edit Profile</h5>
                </CardHeader>
                <CardBody>
                  <Row>

                    <Col md="6">
                      <FormGroup>
                        <label>First Name</label>
                        <Input

                          type="text"
                          placeholder={user.fname}
                          name="fname"
                          value={fname}
                          onChange={onChange}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <label>Last Name</label>
                        <Input

                          type="text"
                          placeholder={user.lname}
                          name="lname"
                          value={lname}
                          onChange={onChange}
                        />
                      </FormGroup>
                    </Col>

                  </Row>

                  <Row>
                    <Col md="12">
                      <FormGroup>
                        <label>Address</label>
                        <Input

                          type="text"
                          placeholder={user.address}
                          name="address"
                          value={address}
                          onChange={onChange}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="" md="4">
                      <FormGroup>
                        <label>City</label>
                        <Input
                          type="text"
                          placeholder={user.town}
                          name="town"
                          value={town}
                          onChange={onChange}
                        />
                      </FormGroup>
                    </Col>
                    <Col className="" md="4">
                      <FormGroup>
                        <label>Postal Code</label>
                        <Input
                          placeholder={user.post_code}
                          name="post_code"
                          value={post_code}
                          onChange={onChange}
                        />
                        <p
                          style={{
                            fontSize: '10px',
                            color: '#D63343'
                          }}
                        >
                          {errors?.post_code}
                        </p>
                      </FormGroup>
                    </Col>
                    <Col md="4" className="">
                      <FormGroup>
                        <label>Contact #</label>
                        <Input
                          placeholder={user.mobile_no}
                          name="mobile_no"
                          value={mobile_no}
                          onChange={onChange}
                          type="text"
                          mask="+44 99 9999 9999"
                          tag={InputMask}
                        />
                      </FormGroup>
                    </Col>

                  </Row>
                  <Row>
                    <Col md="4" className="">
                      <FormGroup>
                        <label>Email</label>
                        <Input
                          type="email"
                          placeholder={user.email}
                          name="email"
                          value={email}
                          onChange={onChange}

                        />
                      </FormGroup>
                    </Col>
                    <Col md="4" className="">
                      <FormGroup>
                        <label>Password</label>
                        <Input type="password" name="password"
                          value={password}
                          onChange={onChange} />
                      </FormGroup>
                    </Col>
                    {/* <Col md="4" className="pl-1">
                      <FormGroup>
                        <label>Confirm Password</label>
                        <Input type="password" name="c_password"
                          value={c_password}
                          onChange={onChange} />
                      </FormGroup>
                    </Col> */}
                    {/* <Col md="12">
                      <FormGroup>
                        <label>Notes Box</label>
                        <Input
                          cols="80"
                          className="border"
                          rows="4"
                          type="textarea"
                          placeholder={user.notes}

                          name="notes"
                          value={notes}
                          onChange={onChange}

                        />
                      </FormGroup>
                    </Col> */}
                  </Row>
                  <div className="text-right">
                    {
                      loadingSpinner ? (
                        <div class="spinner-border text-info" role="status">
                          <span class="sr-only">Loading...</span>
                        </div>

                      ) : (
                        <Button color="info" size="sm" type="submit" >
                          Update
                        </Button>
                      )
                    }
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col md="4">
              <Card className="card-user">
                <div className="image" style={{ height: "190px" }}>
                  <img alt="..." src={require("assets/img/bg5.jpg").default} />
                </div>
                <CardBody>
                  <div className="author">
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <img
                        alt="..."
                        className="avatar border-gray"
                        src={pic instanceof File ?
                          URL.createObjectURL(pic)
                          :
                          `https://api.eagleiq.online/${user.attachment}`
                        }
                      />
                      <h5 className="title">{user.fname + ' ' + user.lname}</h5>
                    </a>
                    <p className="description">{user.email}</p>

                    <FormGroup className="form-file-upload form-file-simple">
                      <Input
                        type="text"
                        className="inputFileVisible btn btn-info bg-info white-input-field text-white rounded"
                        placeholder="Update Profile Picture"
                        onClick={(e) => handleSingleFileInput(e)}
                        defaultValue={"Update Profile Picture"}
                      />
                      <input
                        type="file"
                        className="inputFileHidden"
                        style={{ zIndex: -1 }}
                        ref={singleFileRef}
                        name="pic"
                        onChange={e => handleImageUpdate(e)}

                      />
                    </FormGroup>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Form>

      </div>
    </>
  );
}

const mapStateToProps = state => ({

  user: state.authReducer.user,
  isLoading: state.profileReducer.isLoading,
  sweetAlert: state.profileReducer.sweetAlert,

  successMsg: state.profileReducer.updatedAdminProfile.message,




})

export default connect(mapStateToProps, { updateAdminProfile, removeSweetAlert, updateProfilePicture })(EditProfile);
