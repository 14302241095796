export const SET_ALERT = 'SET_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT = 'LOGOUT'
export const AUTH_ERROR = 'AUTH_ERROR';
export const GET_USER = 'GET_USER';
export const USER_LOADED = 'USER_LOADED';
export const GET_BASE_URL = 'GET_BASE_URL';
export const GET_ALL_USERS = 'GET_ALL_USERS';
export const GET_ALL_MANAGERS = 'GET_ALL_MANAGERS';
export const ADD_NEW_MANAGER = 'ADD_NEW_MANAGER';
export const UPDATE_MANAGER = 'UPDATE_MANAGER';
export const DELETE_MANAGER = 'DELETE_MANAGER';
export const GET_ALL_STAFF = 'GET_ALL_STAFF';
export const ADD_NEW_STAFF = 'ADD_NEW_STAFF';
export const UPDATE_STAFF = 'UPDATE_STAFF';
export const DELETE_STAFF = 'DELETE_STAFF';
export const GET_ALL_CLIENTS = 'GET_ALL_CLIENTS';
export const ADD_NEW_CLIENT = 'ADD_NEW_CLIENT';
export const UPDATE_CLIENT = 'UPDATE_CLIENT';
export const DELETE_CLIENT = 'DELETE_CLIENT';
export const GET_ALL_SITES = 'GET_ALL_SITES';
export const ADD_NEW_SITE = 'ADD_NEW_SITE';
export const UPDATE_SITE = 'UPDATE_SITE';
export const GET_SITE_PROFILE = 'GET_SITE_PROFILE';
export const DELETE_SITE = 'DELETE_SITE';
export const GET_ALL_STOCKS = 'GET_ALL_STOCKS';
export const GET_STOCK_REQUESTS = 'GET_STOCK_REQUESTS';
export const CHANGE_STOCK_STATUS = 'CHANGE_STOCK_STATUS';
export const ADD_NEW_STOCK = 'ADD_NEW_STOCK';
export const UPDATE_STOCK = 'UPDATE_STOCK';
export const DELETE_STOCK = 'DELETE_STOCK';
export const ADD_STOCK_CATEGORY = 'ADD_STOCK_CATEGORY';
export const GET_ALL_STOCK_CATEGORIES = 'GET_ALL_STOCK_CATEGORIES';
export const GET_ALL_VIDEOS = 'GET_ALL_VIDEOS';
export const ADD_NEW_VIDEO = 'ADD_NEW_VIDEO';
export const GET_VIDEO_CATEGORIES = 'GET_VIDEO_CATEGORIES';
export const UPDATE_VIDEO = 'UPDATE_VIDEO';
export const DELETE_VIDEO = 'DELETE_VIDEO';
export const ADD_VIDEO_CATEGORY = 'ADD_VIDEO_CATEGORY';
export const UPDATE_ADMIN_PROFILE = 'UPDATE_ADMIN_PROFILE';
export const GET_USER_PROFILE = 'GET_USER_PROFILE';
export const GET_ALL_JOBS = 'GET_ALL_JOBS';
export const ADD_NEW_JOB = 'ADD_NEW_JOB';
export const UPLOAD_FILE = 'UPLOAD_FILE';
export const DELETE_JOB = 'DELETE_JOB';
export const GET_ALL_LEAVE_REQUESTS = 'GET_ALL_LEAVE_REQUESTS';
export const CHANGE_LEAVE_STATUS = 'CHANGE_LEAVE_STATUS';
export const ADD_LEAVE = 'ADD_LEAVE'
export const GET_ALL_TIMESHEETS = 'GET_ALL_TIMESHEETS';
export const GET_SITES_BY_USER_ID = 'GET_SITES_BY_USER_ID';
export const UPDATE_TIMESHEET_STATUS = 'UPDATE_TIMESHEET_STATUS';
export const ADD_TIMESHEET = 'ADD_TIMESHEET';
export const DELETE_TIMESHEET = 'DELETE_TIMESHEET';
export const UPDATE_TIMESHEET = 'UPDATE_TIMESHEET';
export const SET_IS_LOADING = 'SET_IS_LOADING';
export const IS_CREATING_JOB = 'IS_CREATING_JOB'
export const SET_SWEET_ALERT = 'SET_SWEET_ALERT';
export const REMOVE_SWEET_ALERT = 'REMOVE_SWEET_ALERT';
export const EMPTY_USER_PROFILE = 'EMPTY_USER_PROFILE';
export const CHANGE_LOADING_AUTH = 'CHANGE_LOADING_AUTH';
export const FETCH_ERROR = 'FETCH_ERROR';
export const CHANGE_IS_AUTHENTICATED = 'CHANGE_IS_AUTHENTICATED';
export const CHANGE_GLOBAL_LOADER = 'CHANGE_GLOBAL_LOADER';
export const CHANGE_ADD_NOTE = 'CHANGE_ADD_NOTE';
export const SELECTED_JOB_DATA = 'SELECTED_JOB_DATA';
export const CHANGE_GET_ALL_NOTES = 'CHANGE_GET_ALL_NOTES';
export const GET_TODAY_JOBS = 'GET_TODAY_JOBS';
export const GET_TODAY_TASKS = 'GET_TODAY_TASKS';
export const GET_ALL_AREAS = 'GET_ALL_AREAS';
export const GET_ALL_BUILDINGS = 'GET_ALL_BUILDINGS';
export const GET_SINGLE_SITE_DETAIL = 'GET_SINGLE_SITE_DETAIL';