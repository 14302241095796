
import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import Select from "react-select";
import { Link } from 'react-router-dom'
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import PerfectScrollbar from "perfect-scrollbar";
import { connect } from 'react-redux';
import { getAllJobs } from "actions/profileActions";
// react component used to create sweet alerts
// reactstrap components
import {

    Card,
    Container, Col,
    Row, Modal,
    ModalBody,
    ModalFooter,
    ModalHeader, Button, FormGroup, CardHeader, Form, Input, Label, UncontrolledTooltip

} from "reactstrap";
// core components
import PanelHeader from "components/PanelHeader/PanelHeader.js";
// import { dataTable } from "variables/general";
const dataTable = [
    {
        id: '1',
        title: "Manager",
        client: "lorem",
        site: "lorem",
        specification: "lorem",
        date: "22-03-2021",
        manager: "Harry Edward",


    },
    {
        id: '2',
        title: "Manager",
        client: "lorem",
        site: "lorem",
        specification: "lorem",
        date: "22-03-2021",
        manager: "Harry Edward",


    },
    {
        id: '3',
        title: "Manager",
        client: "lorem",
        site: "lorem",
        specification: "lorem",
        date: "22-03-2021",
        manager: "Harry Edward",


    },
    {
        id: '4',
        title: "Manager",
        client: "lorem",
        site: "lorem",
        specification: "lorem",
        date: "22-03-2021",
        manager: "Harry Edward",


    },
    {
        id: '5',
        title: "Manager",
        client: "lorem",
        site: "lorem",
        specification: "lorem",
        date: "22-03-2021",
        manager: "Harry Edward",


    },
    {
        id: '6',
        title: "Manager",
        client: "lorem",
        site: "lorem",
        specification: "lorem",
        date: "22-03-2021",
        manager: "Harry Edward",


    },



];
var selectOptions = [
    { value: "one", label: "One" },
    { value: "two", label: "Two" },
    { value: "three", label: "Three" },
    { value: "four", label: "Four" },
    { value: "five", label: "Five" },
    { value: "six", label: "Six" },
];
const pagination = paginationFactory({
    page: 1,
    alwaysShowAllBtns: true,
    showTotal: true,
    withFirstAndLast: false,
    sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
        <div className="dataTables_length " id="datatable-basic_length">
            <label className="d-flex justify-content-start">
                Show{" "}
                {
                    <select
                        name="datatable-basic_length"
                        aria-controls="datatable-basic"
                        className="form-control"
                        onChange={(e) => onSizePerPageChange(e.target.value)}
                    >
                        <option value="10">10</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                    </select>
                }{" "}
                entries.
            </label>
        </div>
    ),
});


const { SearchBar } = Search;


const TasksList = ({ getAllJobs, allJobs }) => {
    const [modalClassic, setModalClassic] = React.useState(false);
    const toggleModalClassic = () => {
        setModalClassic(!modalClassic);
    };
    const [multipleSelect, setMultipleSelect] = React.useState(null);
    const [multipleSelect1, setMultipleSelect1] = React.useState(null);
    React.useEffect(() => {
        var container = document.getElementById('scroll-bar');
        var ps = new PerfectScrollbar(container)
    })

    const [jobs, setJobs] = useState([]);


    // Set Table

    useEffect(() => {
        getAllJobs();


    }, [])

    useEffect(() => {
        if (allJobs.length !== 0) {
            setJobs(allJobs.jobs)
        } else {
            return null
        }
    })
    function iconFormatter(cell, row) {

        if (row.client !== null) {
            return (
                <span>  {row.client.fname + ' ' + row.client.lname} </span>
            );
        } else {
            return null
        }

    }
    return (
        <>
            <PanelHeader size="sm" />
            <div className="content">



                <Row>
                    <div className="col">
                        <Card className="p-3">


                            <ToolkitProvider
                                data={jobs}
                                keyField="id"
                                bootstrap4

                                columns={[
                                    {
                                        dataField: "title",
                                        text: "Project Title",
                                        sort: true,
                                    },
                                    {
                                        dataField: "client.fname",
                                        text: "Client",
                                        sort: true,
                                    },
                                    {
                                        dataField: "site.id",
                                        text: "Site",
                                        sort: true,
                                    },
                                    {
                                        dataField: "specification",
                                        text: "Specification",
                                        sort: true,
                                    },
                                    {
                                        dataField: "date",
                                        text: "Date Created",
                                        sort: true,
                                    },
                                    // {
                                    //     dataField: "date",
                                    //     text: "Status",
                                    //     sort: false,
                                    // },

                                    // {
                                    //     dataField: 'df2',
                                    //     isDummyField: true,
                                    //     text: 'Create a Job',
                                    //     formatter: (cellContent, row) => {
                                    //         return (
                                    //             <h5>
                                    //                 <Link id="tooltip590841487" to="/admin/add-job" className="btn btn-info btn-sm m-1">  <i className="fas fa-plus-circle"></i></Link>
                                    //                 <UncontrolledTooltip
                                    //                     delay={0}
                                    //                     target="tooltip590841487"
                                    //                 >Add Job</UncontrolledTooltip>
                                    //             </h5>
                                    //         );

                                    //     }
                                    // },
                                    {
                                        dataField: "manager",
                                        text: "Manager",
                                        sort: true,
                                    },
                                    {
                                        dataField: 'df2',
                                        isDummyField: true,
                                        text: 'Action',
                                        formatter: (cellContent, row) => {
                                            return (
                                                <h5 className="d-flex flex-wrap">
                                                    <span className="btn btn-info btn-sm m-1" onClick={toggleModalClassic} > <i className="fas fa-eye"></i></span>
                                                    <span className="btn btn-danger btn-sm m-1" > <i className="fas fa-times"></i></span>
                                                    <Link id="tooltip590841497" to="/admin/notes" className="btn btn-success btn-sm m-1">  <i className="fas fa-plus-circle"></i></Link>
                                                    <UncontrolledTooltip
                                                        delay={0}
                                                        target="tooltip590841497"
                                                    >Add Notes</UncontrolledTooltip>
                                                </h5>
                                            );

                                        }
                                    }


                                ]}
                                search
                            >
                                {(props) => (
                                    <div className="py-4 table-responsive" id="scroll-bar">
                                        <div
                                            id="datatable-basic_filter"
                                            className="dataTables_filter px-4 pb-1 d-flex  align-items-center justify-content-between"
                                        >

                                            <label>
                                                Search:
                                                <SearchBar
                                                    className="form-control"
                                                    placeholder=""
                                                    {...props.searchProps}
                                                />
                                            </label>
                                            <Link className="btn btn-info btn-sm" to="/admin/add-new-job">Add New Job</Link>

                                        </div>
                                        <BootstrapTable
                                            {...props.baseProps}
                                            bootstrap4={true}
                                            pagination={pagination}
                                            bordered={false}
                                        />
                                    </div>
                                )}
                            </ToolkitProvider>
                        </Card>

                    </div>


                </Row>
                <Modal
                    isOpen={modalClassic}
                    toggle={toggleModalClassic}
                    className="text-center"
                    size="lg"
                >
                    <ModalHeader
                        className="justify-content-center uppercase title"
                        toggle={toggleModalClassic}
                        tag="h4"
                    >
                        Job Details
                    </ModalHeader>
                    <ModalBody>
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>Client Name</th>
                                    <th>Client Site</th>
                                    <th>Start Date</th>
                                    <th>Areas</th>
                                    <th>Manager</th>
                                    <th>Staff</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Harry Styles</td>
                                    <td>NewYork</td>
                                    <td>22/02/2020</td>
                                    <td>Lorem</td>
                                    <td>Meghan Markle</td>
                                    <td>Harry , Ryan , Blake</td>
                                </tr>
                            </tbody>
                        </table>
                        <div className="text-right mr-2">
                            <Button color="info" onClick={toggleModalClassic}>
                                Next
                            </Button>
                        </div>
                    </ModalBody>


                </Modal>
            </div>

        </>
    );
}
const mapStateToProps = state => ({
    allJobs: state.profileReducer.allJobs
})
export default connect(mapStateToProps, { getAllJobs })(TasksList);






















