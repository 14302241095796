
import React, { useState, useEffect } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import Select from "react-select";
import { Link, useHistory } from 'react-router-dom'
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import PerfectScrollbar from "perfect-scrollbar";
import { connect } from 'react-redux';
import { getAllManagers, updateManager, deleteManager } from "actions/profileActions";
// react component used to create sweet alerts
// reactstrap components
import {

    Card,
    Container, Col,
    Row, Modal,
    ModalBody,
    ModalFooter,
    ModalHeader, Button, FormGroup, CardHeader, Form, Input, Label, UncontrolledTooltip

} from "reactstrap";
// core components
import PanelHeader from "components/PanelHeader/PanelHeader.js";

const pagination = paginationFactory({
    page: 1,
    alwaysShowAllBtns: true,
    showTotal: true,
    withFirstAndLast: false,
    sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
        <div className="dataTables_length " id="datatable-basic_length">
            <label className="d-flex justify-content-start">
                Show{" "}
                {
                    <select
                        name="datatable-basic_length"
                        aria-controls="datatable-basic"
                        className="form-control"
                        onChange={(e) => onSizePerPageChange(e.target.value)}
                    >
                        <option value="10">10</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                    </select>
                }{" "}
                entries.
            </label>
        </div>
    ),
});


const { SearchBar } = Search;


const ManagerDatabase = ({ getAllManagers, allManagers, updateManager, deleteManager }) => {
    const history = useHistory()
    const [modalClassic, setModalClassic] = React.useState(false);
    const toggleModalClassic = () => {
        setModalClassic(!modalClassic);
    };

    const [modalClassic1, setModalClassic1] = React.useState(false);
    const toggleModalClassic1 = () => {
        setModalClassic1(!modalClassic1);
    };
    const [userProfile, setUserProfile] = useState([])

    const [multipleSelect, setMultipleSelect] = React.useState(null);
    const [multipleSelect1, setMultipleSelect1] = React.useState(null);

    const [managers, setManagers] = useState([]);

    const setModal = (row) => {
        toggleModalClassic()
        setUserProfile(row)


    }
    const setUpdateProfile = (row) => {
        toggleModalClassic1()
        setUserProfile(row)


    }

    useEffect(() => {
        getAllManagers();
        var container = document.getElementById('scroll-bar');
        var ps = new PerfectScrollbar(container);

    }, [])

    useEffect(() => {
        if (allManagers.allManagers.length !== 0) {
            setManagers(allManagers.allManagers.managers)


        } else {
            return null
        }
    })


    const [formData, setFormData] = useState({
        fname: '',
        lname: '',
        email: '',
        address: '',
        post_code: '',
        town: '',
        phone_no: '',
        mobile_no: '',
        manager_id: '',


    })

    var { manager_id, fname, lname, address, post_code, mobile_no, phone_no, email, town } = formData;
    const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value })

    const onSubmit = e => {
        e.preventDefault();

        if (fname === '') {
            fname = userProfile.fname
        }
        if (lname === '') {
            lname = userProfile.lname
        }
        if (address === '') {
            address = userProfile.address
        }
        if (post_code === '') {
            post_code = userProfile.post_code
        }
        if (mobile_no === '') {
            mobile_no = userProfile.mobile_no
        }
        if (phone_no === '') {
            phone_no = userProfile.phone_no
        }
        if (email === '') {
            email = userProfile.email
        }
        if (town === '') {
            town = userProfile.town
        }




        var formData = new FormData();
        formData.append("manager_id", userProfile.id);
        formData.append("fname", fname);
        formData.append("lname", lname);
        formData.append("address", address);
        formData.append("post_code", post_code);
        formData.append("mobile_no", mobile_no);
        formData.append("phone_no", phone_no);
        formData.append("email", email);
        formData.append("town", town);
        updateManager(formData, history)
        setFormData({

            fname: '',
            lname: '',
            email: '',
            address: '',
            post_code: '',
            town: '',
            phone_no: '',
            mobile_no: '',
            manager_id: '',

        })

    }


    function iconFormatter(cell, row) {


        return (
            <span>   {cell === 0 ? 'InActive' : 'Active'} </span>
        );
    }





    return (
        <>
            <PanelHeader size="sm" />
            <div className="content">



                <Row>
                    <div className="col">
                        <Card className="p-3">

                            <ToolkitProvider
                                data={managers}
                                keyField="id"
                                bootstrap4

                                columns={[
                                    {
                                        dataField: "fname",
                                        text: "First Name",
                                        sort: true,
                                    },
                                    {
                                        dataField: "lname",
                                        text: "Last Name",
                                        sort: true,
                                    },

                                    {
                                        dataField: "email",
                                        text: "Email",
                                        sort: true,
                                    },
                                    {
                                        dataField: "phone_no",
                                        text: "Contact",
                                        sort: true,
                                    },
                                    {
                                        dataField: "status",
                                        text: "Status",
                                        sort: true,
                                        formatter: iconFormatter
                                    },

                                    {
                                        dataField: 'df2',
                                        isDummyField: true,
                                        text: 'Action',
                                        formatter: (cellContent, row) => {
                                            return (
                                                <h5 className="d-flex  flex-wrap justify-content-start">
                                                    <span className="btn btn-info btn-sm m-1" onClick={() => setModal(row)}> <i className="fas fa-eye"></i></span>
                                                    <span className="btn btn-info btn-sm m-1" onClick={() => setUpdateProfile(row)}> <i className="fas fa-edit"></i></span>


                                                    <span className="btn btn-danger btn-sm m-1" onClick={() => deleteManager(row.id, history)} > <i className="fas fa-times"></i></span>
                                                </h5>
                                            );

                                        }
                                    }


                                ]}
                                search
                            >
                                {(props) => (
                                    <div className="py-4 table-responsive" id="scroll-bar">
                                        <div
                                            id="datatable-basic_filter"
                                            className="dataTables_filter px-4 pb-1 d-flex  align-items-center justify-content-between"
                                        >

                                            <label>
                                                Search:
                                                <SearchBar
                                                    className="form-control"
                                                    placeholder=""
                                                    {...props.searchProps}
                                                />
                                            </label>
                                            <Link className="btn btn-info btn-sm" to="/admin/add-manager">Add New Manager</Link>

                                        </div>
                                        <BootstrapTable
                                            {...props.baseProps}
                                            bootstrap4={true}
                                            pagination={pagination}
                                            bordered={false}
                                        />
                                    </div>
                                )}
                            </ToolkitProvider>




                        </Card>

                    </div>


                </Row>

                {/* View Profile Modal */}
                <Modal
                    isOpen={modalClassic}
                    toggle={toggleModalClassic}
                    className="text-center"
                    size="lg"
                >
                    <ModalHeader
                        className="justify-content-center uppercase title text-left"
                        toggle={toggleModalClassic}
                        tag="h6"
                    >
                        {userProfile.fname + ' ' + userProfile.lname}
                    </ModalHeader>
                    <ModalBody>
                        <table className="table table-bordered">
                            <tbody>
                                <tr className="border">
                                    <td className="border-0 font-weight-bold">First Name</td>
                                    <td className="border-0">{userProfile.fname}</td>
                                    <td className="border-0 font-weight-bold">Last Name</td>
                                    <td className="border-0">{userProfile.lname}</td>

                                </tr>
                                <tr className="border">
                                    <td className="border-0 font-weight-bold">Email</td>
                                    <td className="border-0">{userProfile.email}</td>
                                    <td className="border-0 font-weight-bold">Address</td>
                                    <td className="border-0">{userProfile.address}</td>
                                </tr>
                                <tr className="border">
                                    <td className="border-0 font-weight-bold">Phone No</td>
                                    <td className="border-0">{userProfile.phone_no}</td>
                                    <td className="border-0 font-weight-bold">Postal Code</td>
                                    <td className="border-0">{userProfile.post_code}</td>
                                </tr>
                            </tbody>
                        </table>

                    </ModalBody>
                    {/* <div className="text-right">
                        <Button color="primary" onClick={toggleModalClassic}>
                            Next
                        </Button>
                    </div> */}

                </Modal>

                {/* Update Manager Modal */}

                <Modal
                    isOpen={modalClassic1}
                    toggle={toggleModalClassic1}
                    className="text-center"
                    size="lg"
                >
                    <ModalHeader
                        className="justify-content-start uppercase title text-left"
                        toggle={toggleModalClassic1}
                        tag="h6"
                    >
                        {userProfile.fname + ' ' + userProfile.lname}
                    </ModalHeader>
                    <ModalBody>
                        <Form onSubmit={e => onSubmit(e)} encType="multipart/form-data">
                            <Row>

                                <Col md="6" className="mb-2">
                                    <FormGroup className="text-left">
                                        <Label className="text-left font-weight-bold"> First Name </Label>
                                        <Input type="text" placeholder={userProfile.fname} value={fname} name="fname" onChange={onChange} />
                                    </FormGroup>
                                </Col>
                                <Col md="6" className="mb-2">
                                    <FormGroup className="text-left">
                                        <Label className="text-left font-weight-bold"> Last Name </Label>
                                        <Input type="text" placeholder={userProfile.lname} value={lname} name="lname" onChange={onChange} />
                                    </FormGroup>
                                </Col>
                                <Col md="6" className="mb-2">
                                    <FormGroup className="text-left">
                                        <Label className="text-left font-weight-bold"> Email </Label>
                                        <Input type="email" placeholder={userProfile.email} value={email} name="email" onChange={onChange} />
                                    </FormGroup>
                                </Col>
                                <Col md="6" className="mb-2">
                                    <FormGroup className="text-left">
                                        <Label className="text-left font-weight-bold"> Address </Label>
                                        <Input type="text" placeholder={userProfile.address} value={address} name="address" onChange={onChange} />
                                    </FormGroup>
                                </Col>
                                <Col md="6" className="mb-2">
                                    <FormGroup className="text-left">
                                        <Label className="text-left font-weight-bold"> Postal Code </Label>
                                        <Input type="number" placeholder={userProfile.post_code} value={post_code} name="post_code" onChange={onChange} />
                                    </FormGroup>
                                </Col>
                                <Col md="6" className="mb-2">
                                    <FormGroup className="text-left">
                                        <Label className="text-left font-weight-bold"> Town </Label>
                                        <Input type="text" placeholder={userProfile.town} value={town} name="town" onChange={onChange} />
                                    </FormGroup>
                                </Col>
                                <Col md="6" className="mb-2">
                                    <FormGroup className="text-left">
                                        <Label className="text-left font-weight-bold"> Phone No </Label>
                                        <Input type="text" placeholder={userProfile.phone_no} value={phone_no} name="phone_no" onChange={onChange} />
                                    </FormGroup>
                                </Col>
                                <Col md="6" className="mb-2">
                                    <FormGroup className="text-left">
                                        <Label className="text-left font-weight-bold"> Contact No </Label>
                                        <Input type="text" placeholder={userProfile.mobile_no} value={mobile_no} name="mobile_no" onChange={onChange} />
                                    </FormGroup>
                                </Col>



                            </Row>
                            <div className="text-center">
                                <Button color="info" type="submit" onClick={toggleModalClassic1} >
                                    Update
                                </Button>
                            </div>

                        </Form>

                    </ModalBody>


                </Modal>
            </div>

        </>
    );
}

const mapStateToProps = state => ({
    allManagers: state.profileReducer,
})

export default connect(mapStateToProps, { getAllManagers, updateManager, deleteManager })(ManagerDatabase);






















