
import React, { useRef } from "react";
// react plugin used to create charts
import { Line } from "react-chartjs-2";
// react plugin for creating vector maps
import { getAllClients, getAllManagers, getAllSites, getAllStaff, getAllStocks } from "actions/profileActions";
import ReactHtmlParser from 'react-html-parser';
import { connect } from 'react-redux';

// reactstrap components
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardTitle,
  Col,
  Row,
  Table
} from "reactstrap";
import { Link } from "react-router-dom";

// core components
import PanelHeader from "components/PanelHeader/PanelHeader.js";

import {
  dashboardActiveUsersChart,
  dashboardSummerChart
} from "variables/charts.js";


import { getAllJobs, getTodayJobs, getTodayTasks } from "actions/profileActions";

var mapData = {
  AU: 760,
  BR: 550,
  CA: 120,
  DE: 1300,
  FR: 540,
  GB: 690,
  GE: 200,
  IN: 200,
  RO: 600,
  RU: 300,
  US: 2920,
};

const Dashboard = ({ getAllClients, getAllManagers, getAllSites, getAllStaff, getAllStocks, clients, managers, sites, staff, stocks, getTodayJobs, todayJobs, getAllJobs, allJobs, getTodayTasks, todayTasks }) => {

  React.useEffect(() => {
    getAllClients()
    getAllManagers()
    getAllSites()
    getAllStaff()
    getAllStocks()
    getTodayJobs()
    getAllJobs()
    getTodayTasks()
  }, [])

  const todayJobsRef = useRef(null)

  return (
    <>
      <PanelHeader
        size="sm"
        content=""
      />
      <div className="content">
        <Row>
          <Col xs={4} md={4}>
            <Link to="/admin/staff-database">
              <Card className="card-stats card-raised">
                <CardBody>
                  <div className="statistics">
                    <div className="info">
                      <div className="icon icon-primary">
                        <i className="fas fa-user-friends" />
                      </div>
                      <h3 className="info-title">{managers ? (managers.length) : null}</h3>
                      <h6 className="stats-title">Total Managers</h6>
                    </div>
                  </div>

                </CardBody>
              </Card>
            </Link>

          </Col>
          <Col xs={4} md={4}>
            <Link to="/admin/staff-database">
              <Card className="card-stats card-raised">
                <CardBody>
                  <div className="statistics">
                    <div className="info">
                      <div className="icon icon-success">
                        <i className="fas fa-users" />
                      </div>
                      <h3 className="info-title">
                        {/* <small>$</small> */}
                        {staff ? (staff.length) : '0'}
                      </h3>
                      <h6 className="stats-title">Total Staff</h6>
                    </div>
                  </div>
                </CardBody>

              </Card>
            </Link>
          </Col>
          <Col xs={4} md={4}>
            <Link to="/admin/manage-clients">
              <Card className="card-stats card-raised">
                <CardBody>
                  <div className="statistics">
                    <div className="info">
                      <div className="icon icon-info">
                        <i className="fas fa-users" />
                      </div>
                      <h3 className="info-title">{clients ? (clients.length) : '0'}</h3>
                      <h6 className="stats-title">Total Clients</h6>
                    </div>
                  </div>
                </CardBody>

              </Card>
            </Link>
          </Col>
          <Col xs={4} md={4}>
            <Link to="/admin/manage-jobs">
              <Card className="card-stats card-raised">
                <CardBody>
                  <div className="statistics">
                    <div className="info">
                      <div className="icon icon-primary">
                        <i className="fas fa-user-tie" />
                      </div>
                      <h3 className="info-title">{allJobs?.total_jobs}</h3>
                      <h6 className="stats-title">Total Jobs</h6>
                    </div>
                  </div>
                </CardBody>

              </Card>
            </Link>
          </Col>
          <Col xs={4} md={4}>
            <Link to="/admin/manage-jobs">
              <Card className="card-stats card-raised">
                <CardBody>
                  <div className="statistics">
                    <div className="info">
                      <div className="icon icon-info">
                        <i className="fas fa-user-tie" />
                      </div>
                      <h3 className="info-title">{allJobs?.total_jobs}</h3>
                      <h6 className="stats-title">Active Jobs</h6>
                    </div>
                  </div>
                </CardBody>

              </Card>
            </Link>
          </Col>
          <Col xs={4} md={4}>
            <div
              onClick={() => {
                todayJobsRef.current.scrollIntoView()
              }}
              style={{
                cursor: 'pointer'
              }}
            >
              <Card className="card-stats card-raised">
                <CardBody>
                  <div className="statistics">
                    <div className="info">
                      <div className="icon icon-danger">
                        <i className="fas fa-user-tie" />
                      </div>
                      <h3 className="info-title">{todayJobs?.today_jobs}</h3>
                      <h6 className="stats-title"> Jobs Today</h6>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </div>
          </Col>
          <Col xs={4} md={4}>
            <Link to="/admin/manage-sites">
              <Card className="card-stats card-raised">
                <CardBody>
                  <div className="statistics">
                    <div className="info">
                      <div className="icon icon-danger">
                        <i className="fas fa-building" />
                      </div>
                      <h3 className="info-title">{sites ? (sites.length) : '0'}</h3>
                      <h6 className="stats-title">Total Sites</h6>
                    </div>
                  </div>
                </CardBody>

              </Card>
            </Link>
          </Col>
          <Col xs={4} md={4}>
            <Link to="/admin/all-stocks">
              <Card className="card-stats card-raised">
                <CardBody>
                  <div className="statistics">
                    <div className="info">
                      <div className="icon icon-danger">
                        <i className="now-ui-icons objects_diamond" />
                      </div>
                      <h3 className="info-title">{stocks ? (stocks.length) : '0'}</h3>
                      <h6 className="stats-title">Total Stocks</h6>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Link>
          </Col>
        </Row>
        <div ref={todayJobsRef}>
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <CardTitle tag="h4">Jobs Today</CardTitle>
                </CardHeader>
                <CardBody>
                  <Table responsive>
                    <thead className="text-info">
                      <tr>
                        <th>Site</th>
                        <th>Location</th>
                        <th>Staff</th>
                        <th>Manager</th>
                        <th>Summary</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        todayTasks?.today_task?.map(task => (

                          <tr>
                            <td>{task?.job?.site?.site_name}</td>
                            <td>{task?.job?.site?.location}</td>
                            <td>{`${task?.job?.client?.fname} ${task?.job?.client?.lname}`}</td>
                            <td>{`${task?.job?.manager?.fname} ${task?.job?.manager?.lname}`}</td>
                            <td>{`${task?.job?.summary}`}</td>
                            <td>
                              <div
                                style={{
                                  display: 'flex',
                                  gap: 1
                                }}
                              >
                                <div
                                  style={{
                                    width: '15px',
                                    height: '15px',
                                    borderRadius: '15px',
                                    background: '#FF0000'
                                  }}
                                ></div>
                                {/* <div
                                  style={{
                                    width: '15px',
                                    height: '15px',
                                    borderRadius: '15px',
                                    background: '#FFBF00'
                                  }}
                                ></div>
                                <div
                                  style={{
                                    width: '15px',
                                    height: '15px',
                                    borderRadius: '15px',
                                    background: '#00FF00'
                                  }}
                                ></div> */}
                              </div>
                            </td>
                          </tr>
                        ))
                      }
                    </tbody>
                  </Table>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
        <Row>
          <Col xs={12} md={4}>
            <Card className="card-chart">
              <CardHeader>
                <h5 className="card-category">Active Users</h5>
                {/* <CardTitle tag="h4">34,252</CardTitle> */}

              </CardHeader>
              <CardBody>
                <div className="chart-area">
                  <Line
                    data={dashboardActiveUsersChart.data}
                    options={dashboardActiveUsersChart.options}
                  />
                </div>

              </CardBody>
              <CardFooter>
                <div className="stats">
                  <i className="now-ui-icons arrows-1_refresh-69" />
                  Just Updated
                </div>
              </CardFooter>
            </Card>
          </Col>
          <Col xs={12} md={4}>
            <Card className="card-chart">
              <CardHeader>
                <h5 className="card-category">Annual Earnings</h5>
                {/* <CardTitle tag="h4">55,300</CardTitle> */}

              </CardHeader>
              <CardBody>
                <div className="chart-area">
                  <Line
                    data={dashboardSummerChart.data}
                    options={dashboardSummerChart.options}
                  />
                </div>

              </CardBody>
              <CardFooter>
                <div className="stats">
                  <i className="now-ui-icons arrows-1_refresh-69" />
                  Just Updated
                </div>
              </CardFooter>
            </Card>
          </Col>

        </Row>


      </div>
    </>
  );
}


const mapStateToProps = state => ({
  clients: state?.profileReducer?.allClients?.clients,
  managers: state?.profileReducer?.allManagers?.managers,
  sites: state?.profileReducer?.allSites?.sites,
  staff: state?.profileReducer?.allStaff?.staff,
  stocks: state?.profileReducer?.allStocks?.Stocks,
  todayJobs: state.profileReducer.todayJobs,
  todayTasks: state.profileReducer.todayTasks,
  allJobs: state.profileReducer.allJobs,
})

export default connect(mapStateToProps, { getAllClients, getAllManagers, getAllSites, getAllStaff, getAllStocks, getTodayJobs, getAllJobs, getTodayTasks })(Dashboard);
