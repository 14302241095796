import {
  GET_BASE_URL,
  GET_ALL_MANAGERS,
  ADD_NEW_MANAGER,
  GET_ALL_STAFF,
  ADD_NEW_STAFF,
  GET_ALL_CLIENTS,
  GET_ALL_SITES,
  GET_ALL_STOCKS,
  GET_ALL_VIDEOS,
  UPDATE_ADMIN_PROFILE,
  UPDATE_MANAGER,
  DELETE_MANAGER,
  UPDATE_STAFF,
  DELETE_STAFF,
  ADD_NEW_CLIENT,
  UPDATE_CLIENT,
  DELETE_CLIENT,
  ADD_NEW_SITE,
  UPDATE_SITE,
  GET_SITE_PROFILE,
  DELETE_SITE,
  GET_USER_PROFILE,
  ADD_NEW_STOCK,
  UPDATE_STOCK,
  DELETE_STOCK,
  GET_ALL_STOCK_CATEGORIES,
  ADD_NEW_VIDEO,
  GET_VIDEO_CATEGORIES,
  UPDATE_VIDEO,
  DELETE_VIDEO,
  ADD_STOCK_CATEGORY,
  ADD_VIDEO_CATEGORY,
  SET_IS_LOADING,
  SET_SWEET_ALERT,
  FETCH_ERROR,
  REMOVE_SWEET_ALERT,
  GET_ALL_JOBS,
  ADD_NEW_JOB,
  UPLOAD_FILE,
  DELETE_JOB,
  GET_ALL_LEAVE_REQUESTS,
  GET_ALL_USERS,
  GET_ALL_TIMESHEETS,
  CHANGE_LEAVE_STATUS,
  IS_CREATING_JOB,
  ADD_LEAVE,
  UPDATE_TIMESHEET_STATUS,
  ADD_TIMESHEET,
  DELETE_TIMESHEET,
  UPDATE_TIMESHEET,
  GET_STOCK_REQUESTS,
  CHANGE_STOCK_STATUS,
  GET_TODAY_JOBS,
  GET_TODAY_TASKS,
  GET_ALL_AREAS,
  GET_ALL_BUILDINGS,
  GET_SITES_BY_USER_ID,
  EMPTY_USER_PROFILE,
  GET_SINGLE_SITE_DETAIL
} from "../actions/types";

const initialState = {
  baseUrl: "",
  isLoading: false,
  isCreatingJob: false,
  sweetAlert: false,
  userProfile: {},
  allUsers: [],
  allJobs: [],
  newJob: {},
  deletedJob: {},
  newManager: {},
  uploadedFile: {},
  todayJobs: {},
  todayTasks: {},

  allManagers: [],
  updatedManager: {},

  allStaff: [],
  newStaff: {},
  updatedStaff: {},
  deletedStaff: {},
  allClients: [],
  newClient: {},

  updatedClient: {},
  deletedClient: {},
  allSites: [],
  siteProfile: {},
  newSite: {},
  updatedSite: {},
  deletedSite: {},
  allStocks: [],
  allStockCategories: [],
  newStock: {},
  newStockCategory: {},
  updatedStock: {},
  deletedStock: {},
  allVideos: [],
  newVideo: {},
  videoCategories: [],
  newVideoCategory: {},
  deletedVideo: {},
  updatedVideo: {},
  updatedAdminProfile: {},
  deletedManager: {},

  leaveRequests: [],
  newLeave: {},
  leaveStatus: {},
  allTimesheets: [],
  userSites: {},
  timesheetStatus: {},
  newTimesheet: {},
  deletedTimesheet: {},
  updatedTimesheet: {},
  stockRequests: [],
  stockStatus: {},

  allAreas: [],
  allBuildings: [],
  singleSiteDetail: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_IS_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case GET_STOCK_REQUESTS:
      return {
        ...state,
        isLoading: false,

        stockRequests: action.payload,
      };
    case CHANGE_STOCK_STATUS:
      // alert(action.payload.message)
      // window.location.reload()
      return {
        ...state,
        isLoading: false,
        sweetAlert: true,

        stockStatus: action.payload,
      };
    case IS_CREATING_JOB:
      return {
        ...state,
        isCreatingJob: true,
      };
    case SET_SWEET_ALERT:
      return {
        ...state,
        sweetAlert: true,
      };
    case REMOVE_SWEET_ALERT:
      return {
        ...state,
        sweetAlert: false,
      };

    case FETCH_ERROR:
      return {
        ...state,
        isLoading: false,
      };
    case GET_BASE_URL:
      return {
        ...state,
        baseUrl: action.payload,
      };

    case GET_USER_PROFILE:
      return {
        ...state,
        userProfile: action.payload,
      };
    case EMPTY_USER_PROFILE:
      return {
        ...state,
        userProfile: {},
      };
    //All Users

    case GET_ALL_USERS:
      return {
        ...state,
        allUsers: action.payload,
      };

    // Jobs
    case GET_ALL_JOBS:
      return {
        ...state,
        allJobs: action.payload,
      };
    case ADD_NEW_JOB:
      // alert(action.payload.message)
      // window.location.reload()
      return {
        ...state,
        isLoading: false,
        isCreatingJob: false,
        sweetAlert: true,

        newJob: action.payload,
      };
    case DELETE_JOB:
      // alert(action.payload.message)
      // window.location.reload()
      return {
        ...state,
        isLoading: false,
        sweetAlert: true,
        deletedJob: action.payload,
      };
    case UPLOAD_FILE:
      window.alert(action.payload.message);

      return {
        ...state,
        isLoading: false,
        uploadedFile: action.payload,
      };
    // Managers

    case GET_ALL_MANAGERS:
      return {
        ...state,
        allManagers: action.payload,
      };

    case ADD_NEW_MANAGER:
      // alert(action.payload.message)

      return {
        ...state,
        isLoading: false,
        sweetAlert: true,
        newManager: action.payload,
      };
    case UPDATE_MANAGER:
      // alert(action.payload.message)
      // window.location.reload()
      return {
        ...state,
        isLoading: false,
        sweetAlert: true,
        updatedManager: action.payload,
      };
    case DELETE_MANAGER:
      // alert(action.payload.message)
      // window.location.reload()
      return {
        ...state,
        isLoading: false,
        sweetAlert: true,
        deletedManager: action.payload,
      };

    // Staff
    case GET_ALL_STAFF:
      return {
        ...state,
        allStaff: action.payload,
      };

    case ADD_NEW_STAFF:
      // alert(action.payload.message)
      // window.location.reload()
      return {
        ...state,
        isLoading: false,
        sweetAlert: true,
        newStaff: action.payload,
      };
    case UPDATE_STAFF:
      // alert(action.payload.message)
      // window.location.reload()
      return {
        ...state,
        isLoading: false,
        sweetAlert: true,
        updatedStaff: action.payload,
      };

    case DELETE_STAFF:
      // alert(action.payload.message)
      // window.location.reload()
      return {
        ...state,
        isLoading: false,
        sweetAlert: true,
        deletedStaff: action.payload,
      };

    // Clients

    case GET_ALL_CLIENTS:
      return {
        ...state,
        allClients: action.payload,
      };

    case ADD_NEW_CLIENT:
      // alert(action.payload.message)
      // window.location.reload()
      return {
        ...state,
        isLoading: false,
        sweetAlert: true,
        newClient: action.payload,
      };
    case UPDATE_CLIENT:
      window.alert(action.payload.message);
      // window.location.reload()
      return {
        ...state,
        updatedClient: action.payload,
      };

    case DELETE_CLIENT:
      // alert(action.payload.message)
      // window.location.reload()
      return {
        ...state,
        isLoading: false,
        sweetAlert: true,
        deletedClient: action.payload,
      };

    // Sites
    case GET_ALL_SITES:
      return {
        ...state,
        allSites: action.payload,
      };
    case GET_SITE_PROFILE:
      return {
        ...state,
        siteProfile: action.payload,
      };

    case ADD_NEW_SITE:
      console.log("reducer");
      // alert(action.payload.message)
      // window.location.reload()
      return {
        ...state,
        isLoading: false,
        sweetAlert: true,
        newSite: action.payload,
      };
    case UPDATE_SITE:
      // alert(action.payload.message)
      // window.location.reload()
      return {
        ...state,
        isLoading: false,
        sweetAlert: true,
        updatedSite: action.payload,
      };

    case DELETE_SITE:
      // alert(action.payload.message)
      // window.location.reload()
      return {
        ...state,
        isLoading: false,
        sweetAlert: true,
        deletedSite: action.payload,
      };

    // Stocks
    case GET_ALL_STOCKS:
      return {
        ...state,
        allStocks: action.payload,
      };

    case GET_TODAY_JOBS:
      return {
        ...state,

        todayJobs: action.payload,
      };
    case GET_TODAY_TASKS:
      return {
        ...state,

        todayTasks: action.payload,
      };

    case GET_ALL_STOCK_CATEGORIES:
      return {
        ...state,
        allStockCategories: action.payload,
      };
    case ADD_NEW_STOCK:
      // alert(action.payload.message)
      // window.location.reload()
      return {
        ...state,
        isLoading: false,
        sweetAlert: true,
        newStock: action.payload,
      };
    case ADD_STOCK_CATEGORY:
      window.alert(action.payload.message);
      // window.location.reload()
      return {
        ...state,
        newStockCategory: action.payload,
      };
    case UPDATE_STOCK:
      // alert(action.payload.message)
      // window.location.reload()
      return {
        ...state,
        isLoading: false,
        sweetAlert: true,
        updatedStock: action.payload,
      };
    case DELETE_STOCK:
      // alert(action.payload.message)
      // window.location.reload()
      return {
        ...state,
        isLoading: false,
        sweetAlert: true,
        deletedStock: action.payload,
      };

    // Videos
    case GET_ALL_VIDEOS:
      return {
        ...state,
        allVideos: action.payload,
      };
    case ADD_NEW_VIDEO:
      // alert(action.payload.message)
      // window.location.reload()
      return {
        ...state,
        isLoading: false,
        sweetAlert: true,

        newVideo: action.payload,
      };

    case UPDATE_VIDEO:
      // alert(action.payload.message)
      // window.location.reload()
      return {
        ...state,
        isLoading: false,
        sweetAlert: true,

        updatedVideo: action.payload,
      };
    case GET_VIDEO_CATEGORIES:
      return {
        ...state,
        videoCategories: action.payload,
      };

    case DELETE_VIDEO:
      // alert(action.payload.message)
      // window.location.reload()
      return {
        ...state,
        isLoading: false,

        sweetAlert: true,

        deletedVideo: action.payload,
      };
    case ADD_VIDEO_CATEGORY:
      window.alert(action.payload.message);
      // window.location.reload()
      return {
        ...state,
        newVideoCategory: action.payload,
      };

    // Leave Requests

    case GET_ALL_LEAVE_REQUESTS:
      return {
        ...state,
        leaveRequests: action.payload,
      };

    case CHANGE_LEAVE_STATUS:
      // alert(action.payload.message)
      // window.location.reload()
      return {
        ...state,
        isLoading: false,
        sweetAlert: true,

        leaveStatus: action.payload,
      };
    case ADD_LEAVE:
      // alert(action.payload.message)

      return {
        ...state,
        isLoading: false,
        sweetAlert: true,
        newLeave: action.payload,
      };

    // Timsheets

    case GET_ALL_TIMESHEETS:
      return {
        ...state,
        allTimesheets: action.payload,
      };

    case GET_SITES_BY_USER_ID:
      return {
        ...state,
        userSites: action.payload,
      };
    case UPDATE_TIMESHEET_STATUS:
      return {
        ...state,
        isLoading: false,
        sweetAlert: true,

        timesheetStatus: action.payload,
      };
    case ADD_TIMESHEET:
      // alert(action.payload.message)

      return {
        ...state,
        isLoading: false,
        sweetAlert: true,
        newTimesheet: action.payload,
      };

    case DELETE_TIMESHEET:
      // alert(action.payload.message)
      // window.location.reload()
      return {
        ...state,
        isLoading: false,

        sweetAlert: true,

        deletedTimesheet: action.payload,
      };

    case UPDATE_TIMESHEET:
      // alert(action.payload.message)
      // window.location.reload()
      return {
        ...state,
        isLoading: false,
        sweetAlert: true,

        updatedTimesheet: action.payload,
      };

    // ADmin PRofile

    case UPDATE_ADMIN_PROFILE:
      // alert(action.payload.message)
      return {
        ...state,
        isLoading: false,
        sweetAlert: true,
        updatedAdminProfile: action.payload,
      };

    case GET_ALL_AREAS:
      return {
        ...state,
        allAreas: action.payload,
      };

    case GET_ALL_BUILDINGS:
      return {
        ...state,
        allBuildings: action.payload,
      };
    case GET_SINGLE_SITE_DETAIL:
      return {
        ...state,
        singleSiteDetail: action.payload,
      };

    default:
      return state;
  }
}
