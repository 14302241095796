
import React, { useState, useEffect } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import Select from "react-select";
import { Link, useHistory } from 'react-router-dom'
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import { connect } from 'react-redux';
import { getAllClients, addNewClient, updateClient, deleteClient, getTimesheets, updateTimsheetStatus, removeSweetAlert, addNewTimeSheet, deleteTimesheet, getUserSitesById } from "actions/profileActions";
import { CSVReader } from 'react-papaparse'
import SweetAlert from "react-bootstrap-sweetalert";

// react component used to create sweet alerts
// reactstrap components
import {

    Card,
    Container, Col,
    Row, Modal,
    ModalBody,
    ModalFooter,
    ModalHeader, Button, FormGroup, CardHeader, Form, Input, Label, UncontrolledTooltip

} from "reactstrap";
// core components
import PanelHeader from "components/PanelHeader/PanelHeader.js";
import { updateTimesheet } from "actions/profileActions";

const buttonRef = React.createRef()

const pagination = paginationFactory({
    page: 1,
    alwaysShowAllBtns: true,
    showTotal: true,
    withFirstAndLast: false,
    sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
        <div className="dataTables_length " id="datatable-basic_length">
            <label className="d-flex justify-content-start">
                Show{" "}
                {
                    <select
                        name="datatable-basic_length"
                        aria-controls="datatable-basic"
                        className="form-control"
                        onChange={(e) => onSizePerPageChange(e.target.value)}
                    >
                        <option value="10">10</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                    </select>
                }{" "}
                entries.
            </label>
        </div>
    ),
});


const { SearchBar } = Search;


const TimeSheets = ({ getAllClients, allClients, addNewClient, updateClient, deleteClient, allTimesheets, userSites, getTimesheets, updateTimsheetStatus, isLoading, timesheetStatus, removeSweetAlert, sweetAlert, user, addNewTimeSheet, getUserSitesById, newTimesheetmsg, deletedTimesheet, deleteTimesheet, updateTimesheet, updatedTimesheet }) => {


    // Sweet Alert

    const [alert, setAlert] = React.useState(null);
    React.useEffect(() => {
        return function cleanup() {
            var id = window.setTimeout(null, 0);
            while (id--) {
                window.clearTimeout(id);
            }
        };
    }, []);

    useEffect(() => {
        if (sweetAlert) {
            successAlert()


        } else {
            hideAlert()
        }
    }, [sweetAlert])

    const successAlert = () => {
        setAlert(
            <SweetAlert
                success
                style={{ display: "block", marginTop: "-100px" }}
                title="Success!"
                onConfirm={() => hideAlert()}
                onCancel={() => hideAlert()}
                confirmBtnBsStyle="info"
            >
                {timesheetStatus ? timesheetStatus : newTimesheetmsg ? newTimesheetmsg : deletedTimesheet ? deletedTimesheet : updatedTimesheet ? updatedTimesheet : null}
            </SweetAlert>
        );
    }
    const hideAlert = () => {
        setAlert(null);
        removeSweetAlert()
    };

    const history = useHistory()
    const [modalClassic, setModalClassic] = React.useState(false);
    const toggleModalClassic = () => {
        setModalClassic(!modalClassic);
    };

    const [modalClassic1, setModalClassic1] = React.useState(false);
    const toggleModalClassic1 = () => {
        setModalClassic1(!modalClassic1);
    };
    const [modalClassic2, setModalClassic2] = React.useState(false);
    const toggleModalClassic2 = () => {
        setModalClassic2(!modalClassic1);
    };

    const [userProfile, setUserProfile] = useState([])

    const setModal = (row) => {
        toggleModalClassic1()
        setUserProfile(row)
        console.log(row)
    }


    const setUpdateProfile = (row) => {
        toggleModalClassic2()
        setUserProfile(row)


    }

    // Set Table
    const [sheets, setsheets] = useState([]);


    useEffect(() => {
        getTimesheets();
    }, [])

    useEffect(() => {
        if (allTimesheets.length !== 0) {
            setsheets(allTimesheets.timesheet)
        } else {
            return null
        }
    })

    var [formData, setFormData] = useState({
        fname: '',
        lname: '',
        email: '',
        address: '',
        post_code: '',
        town: '',
        phone_no: '',
        mobile_no: '',
        password: '',
        task: '',
        start_date: '',
        end_date: '',


    })

    var { fname, lname, address, post_code, mobile_no, phone_no, email, town, password, task, start_date, start_time, site_id, end_time, status } = formData;
    const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value })




    const updateStaffSubmit = e => {
        e.preventDefault();

        if (site_id === '') {
            site_id = userProfile.site_id
        }
        if (start_date === '') {
            start_date = userProfile.start_date
        }
        if (start_time === '') {
            start_date = userProfile?.start_time
        }
        if (end_time === '') {
            end_time = userProfile?.end_time
        }
        if (status === '') {
            status = userProfile.status
        }

        var formData = new FormData();
        formData.append("timesheet_id", userProfile.id);
        formData.append("site_id", site_id);
        formData.append("start_date", start_date);
        formData.append("start_time", start_time);
        formData.append("end_time", end_time);
        formData.append("status", status);

        updateTimesheet(formData, history)

        setFormData({
            fname: '',
            lname: '',
            email: '',
            address: '',
            post_code: '',
            town: '',
            phone_no: '',
            mobile_no: '',
            password: '',
            start_date: '',
            site_id: '',
            start_time: '',
            end_time: '',
            status: '',
        })
    }

    function nameFormatter(cell, row) {
        return (
            <span>   {row.employee.fname + ' ' + row.employee.lname} </span>
        );
    }

    function titleFormatter(cell, row) {
        return (
            <span>   {cell === 1 ? 'Manager' : cell === 2 ? 'Staff' : cell === 3 ? 'Client' : null} </span>
        );
    }

    function iconFormatter(cell, row) {
        return (
            <span>   {cell === 0 ? 'InActive' : 'Active'} </span>
        );
    }

    const addTimeSheetSubmit = e => {
        e.preventDefault();
        console.log(site_id, start_date, start_time, end_time, status)
        var formData = new FormData();
        formData.append("user_id", user.id);
        formData.append("site_id", site_id);
        formData.append("date", start_date);
        formData.append("start_time", start_time);
        formData.append("end_time", end_time);

        addNewTimeSheet(formData, history)

        setFormData({
            start_date: '',
            start_time: '',
            end_time: '',
        })
    }

    useEffect(() => {
        getUserSitesById(user.id)
    }, [user.id])

    return (
        <>
            <PanelHeader size="sm" />
            <div className="content">
                {alert}
                <Row>
                    <div className="col">
                        <Card>
                            <Container>
                                <ToolkitProvider
                                    data={sheets}
                                    keyField="id"
                                    bootstrap4

                                    columns={
                                        [
                                            {
                                                dataField: "employee.fname",
                                                text: "Employee Name",
                                                sort: true,
                                                formatter: nameFormatter

                                            },
                                            {
                                                dataField: "employee.role_id",
                                                text: "Role",
                                                sort: true,
                                                formatter: titleFormatter

                                            },

                                            {
                                                dataField: "site.site_name",
                                                text: "Site",
                                                sort: true,
                                            },
                                            {
                                                dataField: "date",
                                                text: "Date",
                                                sort: true,
                                            },
                                            {
                                                dataField: "start_time",
                                                text: "Start Time",
                                                sort: true,
                                            },
                                            {
                                                dataField: "end_time",
                                                text: "End Time",
                                                sort: true,
                                            },
                                        ]
                                    }
                                    search
                                >
                                    {(props) => (
                                        <div className="py-4 ">
                                            <div
                                                id="datatable-basic_filter"
                                                className="dataTables_filter px-4 pb-1 d-flex  align-items-center justify-content-between"
                                            >

                                                <label>
                                                    Search:
                                                    <SearchBar
                                                        className="form-control"
                                                        placeholder=""
                                                        {...props.searchProps}
                                                    />
                                                </label>
                                                <div>
                                                    <Link className="btn btn-info btn-sm" onClick={toggleModalClassic} >Create Timesheet</Link>
                                                    {/* <Link className="btn btn-info btn-sm" >Import Timesheet</Link> */}
                                                    {/* <CSVReader
                                                        ref={buttonRef}
                                                        onFileLoad={handleOnFileLoad}
                                                        onError={handleOnError}
                                                        noClick
                                                        noDrag
                                                        onRemoveFile={handleOnRemoveFile}
                                                    >
                                                        {({ file }) => (
                                                            <aside
                                                                style={{
                                                                    display: 'flex',
                                                                    flexDirection: 'row',
                                                                    marginBottom: 10
                                                                }}
                                                            >
                                                                <button
                                                                    type='button'
                                                                    onClick={handleOpenDialog}
                                                                    style={{
                                                                        borderRadius: 0,
                                                                        marginLeft: 0,
                                                                        marginRight: 0,
                                                                        width: '40%',
                                                                        paddingLeft: 0,
                                                                        paddingRight: 0
                                                                    }}
                                                                >
                                                                    Browse file
                                                                </button>
                                                                <div
                                                                    style={{
                                                                        borderWidth: 1,
                                                                        borderStyle: 'solid',
                                                                        borderColor: '#ccc',
                                                                        height: 45,
                                                                        lineHeight: 2.5,
                                                                        marginTop: 5,
                                                                        marginBottom: 5,
                                                                        paddingLeft: 13,
                                                                        paddingTop: 3,
                                                                        width: '60%'
                                                                    }}
                                                                >
                                                                    {file && file.name}
                                                                </div>
                                                                <button
                                                                    style={{
                                                                        borderRadius: 0,
                                                                        marginLeft: 0,
                                                                        marginRight: 0,
                                                                        paddingLeft: 20,
                                                                        paddingRight: 20
                                                                    }}
                                                                    onClick={handleRemoveFile}
                                                                >
                                                                    Remove
                                                                </button>
                                                            </aside>
                                                        )}
                                                    </CSVReader> */}

                                                </div>

                                            </div>
                                            <BootstrapTable
                                                {...props.baseProps}
                                                bootstrap4={true}
                                                pagination={pagination}
                                                bordered={false}
                                            />
                                        </div>
                                    )}
                                </ToolkitProvider>
                            </Container>
                        </Card>

                    </div>


                </Row>


                {/* View Profile Modal */}
                <Modal
                    isOpen={modalClassic1}
                    toggle={toggleModalClassic1}
                    className="text-center"
                    size="lg"
                >
                    <ModalHeader
                        className="justify-content-center uppercase title text-left"
                        toggle={toggleModalClassic1}
                        tag="h6"
                    >
                        {userProfile.task}
                    </ModalHeader>
                    <ModalBody>
                        {
                            modalClassic1 ? (
                                <table className="table table-bordered">
                                    <tbody>
                                        <tr className="border">
                                            <td className="border-0 font-weight-bold">Employee</td>
                                            <td className="border-0">{userProfile.employee.fname + ' ' + userProfile.employee.lname}</td>
                                            <td className="border-0 font-weight-bold">Email</td>
                                            <td className="border-0">{userProfile.employee.email}</td>

                                        </tr>
                                        <tr className="border">
                                            <td className="border-0 font-weight-bold">Start Date</td>
                                            <td className="border-0">{userProfile.start_date}</td>
                                            <td className="border-0 font-weight-bold">End Date</td>
                                            <td className="border-0">{userProfile.end_date}</td>

                                        </tr>
                                        <tr className="border">
                                            <td className="border-0 font-weight-bold">Created At</td>
                                            <td className="border-0">{userProfile.created_at}</td>
                                            <td className="border-0 font-weight-bold">Status</td>
                                            <td className="border-0">{userProfile.status === 0 ? 'Inactive' : 'Active'}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            ) : null
                        }


                    </ModalBody>
                    {/* <div className="text-right">
                        <Button color="primary" onClick={toggleModalClassic}>
                            Next
                        </Button>
                    </div> */}

                </Modal>

                {/* Update Timesheet Modal */}

                <Modal
                    isOpen={modalClassic2}
                    toggle={toggleModalClassic2}
                    className="text-center"
                    size="lg"
                >
                    <ModalHeader
                        className="justify-content-start uppercase title text-left"
                        toggle={toggleModalClassic2}
                        tag="h6"
                    >
                        Update Timesheet
                    </ModalHeader>
                    <ModalBody>
                        <Form onSubmit={e => updateStaffSubmit(e)} encType="multipart/form-data">
                            <Row>

                                <Col md="6" className="mb-2">
                                    <FormGroup className="text-left">
                                        <Label className="text-left font-weight-bold"> Task </Label>
                                        <Input type="text" placeholder={userProfile.task} value={task} name="task" onChange={onChange} />
                                    </FormGroup>
                                </Col>
                                <Col md="6" className="mb-2">
                                    <FormGroup className="text-left">
                                        <Label className="text-left font-weight-bold"> Date </Label>
                                        <Input type="date" placeholder={userProfile.start_date} value={start_date} name="start_date" onChange={onChange} />
                                    </FormGroup>
                                </Col>
                                <Col md="6" className="mb-2">
                                    <FormGroup className="text-left">
                                        <Label className="text-left font-weight-bold"> Start Time </Label>
                                        <Input type="time" placeholder={userProfile.start_time} value={start_time} name="start_time" onChange={onChange} />
                                    </FormGroup>
                                </Col>
                                <Col md="6" className="mb-2">
                                    <FormGroup className="text-left">
                                        <Label className="text-left font-weight-bold"> End Time </Label>
                                        <Input type="time" placeholder={userProfile.end_time} value={end_time} name="end_time" onChange={onChange} />
                                    </FormGroup>
                                </Col>
                                <Col md="6" className="mb-2">
                                    <FormGroup className="text-left">
                                        <Label className="text-left font-weight-bold"> Status </Label>
                                        <select name="status" value={status} onChange={onChange} id="" className="form-control">
                                            <option value="">Select</option>
                                            <option value="1">Active</option>
                                            <option value="0">InActive</option>
                                        </select>
                                    </FormGroup>
                                </Col>



                            </Row>
                            <div className="text-center">
                                <Button color="info" type="submit"  >
                                    Update
                                </Button>
                            </div>

                        </Form>

                    </ModalBody>


                </Modal>


                {/* Add New Timesheet Modal */}

                <Modal
                    isOpen={modalClassic}
                    toggle={toggleModalClassic}
                    className="text-center"
                    size="lg"
                >
                    <ModalHeader
                        className="justify-content-start uppercase title text-left"
                        toggle={toggleModalClassic}
                        tag="h6"
                    >
                        Timesheet
                    </ModalHeader>
                    <ModalBody>
                        <Form onSubmit={e => addTimeSheetSubmit(e)} encType="multipart/form-data">
                            <Row>
                                <Col md="6" className="mb-2">
                                    <FormGroup className="text-left">
                                        <Label className="text-left font-weight-bold">Site</Label>
                                        <select name="site_id" value={site_id} id="" onChange={onChange} className="form-control">
                                            {
                                                userSites?.sites?.map(site => (
                                                    <option value={site.id} key={site?.id}>{site?.site_name}</option>
                                                ))
                                            }
                                        </select>
                                    </FormGroup>
                                </Col>

                                <Col md="6" className="mb-2">
                                    <FormGroup className="text-left">
                                        <Label className="text-left font-weight-bold"> Date </Label>
                                        <Input type="date" value={start_date} name="start_date" onChange={onChange} />
                                    </FormGroup>
                                </Col>
                                <Col md="6" className="mb-2">
                                    <FormGroup className="text-left">
                                        <Label className="text-left font-weight-bold"> Start Time </Label>
                                        <Input type="time" value={start_time} name="start_time" onChange={onChange} />
                                    </FormGroup>
                                </Col>
                                <Col md="6" className="mb-2">
                                    <FormGroup className="text-left">
                                        <Label className="text-left font-weight-bold"> End Time </Label>
                                        <Input type="time" value={end_time} name="end_time" onChange={onChange} />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <div className="text-center">
                                {
                                    isLoading ? (
                                        <div className="spinner-border text-info" role="status">
                                            <span className="sr-only">Loading...</span>
                                        </div>
                                    ) : (
                                        <Button color="info" type="submit"  >
                                            Apply
                                        </Button>
                                    )
                                }

                            </div>

                        </Form>

                    </ModalBody>


                </Modal>
            </div>

        </>
    );
}
const mapStateToProps = state => ({
    allClients: state.profileReducer.allClients,
    allTimesheets: state.profileReducer.allTimesheets,
    userSites: state.profileReducer.userSites,
    isLoading: state.profileReducer.isLoading,
    timesheetStatus: state.profileReducer.timesheetStatus.message,
    sweetAlert: state.profileReducer.sweetAlert,
    user: state.authReducer.user,
    newTimesheetmsg: state.profileReducer.newTimesheet.message,
    deletedTimesheet: state.profileReducer.deletedTimesheet.message,
    updatedTimesheet: state.profileReducer.updatedTimesheet.message

})

export default connect(mapStateToProps, { getAllClients, updateClient, addNewClient, deleteClient, getTimesheets, updateTimsheetStatus, removeSweetAlert, addNewTimeSheet, getUserSitesById, deleteTimesheet, updateTimesheet })(TimeSheets);






















