import {
    GET_ALL_MANAGERS,
    ADD_NEW_MANAGER,
    GET_BASE_URL,
    GET_ALL_STAFF,
    GET_ALL_CLIENTS,
    GET_ALL_SITES,
    GET_ALL_STOCKS,
    GET_ALL_VIDEOS,
    UPDATE_ADMIN_PROFILE,
    UPDATE_MANAGER,
    DELETE_MANAGER,
    ADD_NEW_STAFF,
    UPDATE_STAFF,
    DELETE_STAFF,
    ADD_NEW_CLIENT,
    UPDATE_CLIENT,
    DELETE_CLIENT,
    UPDATE_SITE,
    ADD_NEW_SITE,
    DELETE_SITE,
    GET_USER_PROFILE,
    UPDATE_STOCK,
    ADD_NEW_STOCK,
    DELETE_STOCK,
    GET_ALL_STOCK_CATEGORIES,
    ADD_NEW_VIDEO,
    GET_VIDEO_CATEGORIES,
    UPDATE_VIDEO,
    DELETE_VIDEO,
    ADD_STOCK_CATEGORY,
    ADD_VIDEO_CATEGORY,
    SET_IS_LOADING,
    SET_SWEET_ALERT,
    FETCH_ERROR,
    REMOVE_SWEET_ALERT,
    GET_ALL_JOBS,
    ADD_NEW_JOB,
    UPLOAD_FILE,
    DELETE_JOB,
    GET_ALL_LEAVE_REQUESTS,
    GET_ALL_USERS,
    GET_ALL_TIMESHEETS,
    GET_SITES_BY_USER_ID,
    CHANGE_LEAVE_STATUS,
    IS_CREATING_JOB,
    GET_SITE_PROFILE,
    ADD_LEAVE,
    UPDATE_TIMESHEET_STATUS,
    ADD_TIMESHEET,
    DELETE_TIMESHEET,
    UPDATE_TIMESHEET,
    GET_STOCK_REQUESTS,
    CHANGE_STOCK_STATUS,
    CHANGE_GLOBAL_LOADER,
    GET_TODAY_JOBS,
    GET_TODAY_TASKS,
    GET_ALL_BUILDINGS,
    GET_ALL_AREAS
} from "./types";
import { setAlert } from './alertActions';

export const getBaseURl = () => dispatch => {
    var baseUrl = 'https://api.eagleiq.online/';
    dispatch({
        type: GET_BASE_URL,
        payload: baseUrl
    })
}
// All Users
export const getAllUsers = () => dispatch => {

    var myHeaders = new Headers();
    myHeaders.append("Cookie", "XSRF-TOKEN=eyJpdiI6Imh4bG1BdzZhc2NrZ1Z3NlhCNEtGSFE9PSIsInZhbHVlIjoicHdhNmxscUdJM1phUzVRTktqNU4wRnJGT0dISUMwOWJuYnhjUVI5ejZlQXozWUxCY050eXRyMEJDZk51WkszUklOcmplRGhGNkZqd0UrRzlBZkJzK2FBcDdERGtxV2pUKzJnallyR1AvL21IQ0h4Y014RzZpUmNSbEVjT25iZjMiLCJtYWMiOiI3MWM2NjcyZTA1NTIyMjllOTdmOWIwZGE3YjM5YTY2NDBhYWU1MTQ5N2M4YjQ2MTJjZjMxNzYxNDNkZDdlZTZiIn0%3D; laravel_session=eyJpdiI6IjNZbHJnTE1Ld0cvaisrQU4ya2Y3VkE9PSIsInZhbHVlIjoiZ0RlMVJLMjZGZ1h0VmNocWVHeVZYQWlHaUxDNkZxS0M4T0lLNS9UL1dKVHpsTFBqTXhEV1pYY1RHaTU2NUNtSmNLOEY4K0ZaWXloSjh1WUJIejBOeSthT0RRbko2aW9rVW4ydER2aE5GOVpDWERzU3BFNTR2ZjBMZ1plVkxLcngiLCJtYWMiOiIzMTkwZDAxM2JjOTdhMGI3MmYwN2NhMmVjMmRjMzJhYjQxNTNiNjU2Y2FlN2E3ZmY3ZGUzMGQxNGI0ZjZlYTNmIn0%3D");

    var formdata = new FormData();
    formdata.append("token", `${localStorage.getItem('token')}`);

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
    };

    fetch(`https://api.eagleiq.online/api/manager/all-users`, requestOptions)
        .then(res => res.json())
        .then(data => dispatch({
            type: GET_ALL_USERS,
            payload: data
        })).catch((error) => {
            console.error(error)
        })
}
// Managers
export const getAllManagers = () => dispatch => {



    var myHeaders = new Headers();
    myHeaders.append("Cookie", "XSRF-TOKEN=eyJpdiI6Imh4bG1BdzZhc2NrZ1Z3NlhCNEtGSFE9PSIsInZhbHVlIjoicHdhNmxscUdJM1phUzVRTktqNU4wRnJGT0dISUMwOWJuYnhjUVI5ejZlQXozWUxCY050eXRyMEJDZk51WkszUklOcmplRGhGNkZqd0UrRzlBZkJzK2FBcDdERGtxV2pUKzJnallyR1AvL21IQ0h4Y014RzZpUmNSbEVjT25iZjMiLCJtYWMiOiI3MWM2NjcyZTA1NTIyMjllOTdmOWIwZGE3YjM5YTY2NDBhYWU1MTQ5N2M4YjQ2MTJjZjMxNzYxNDNkZDdlZTZiIn0%3D; laravel_session=eyJpdiI6IjNZbHJnTE1Ld0cvaisrQU4ya2Y3VkE9PSIsInZhbHVlIjoiZ0RlMVJLMjZGZ1h0VmNocWVHeVZYQWlHaUxDNkZxS0M4T0lLNS9UL1dKVHpsTFBqTXhEV1pYY1RHaTU2NUNtSmNLOEY4K0ZaWXloSjh1WUJIejBOeSthT0RRbko2aW9rVW4ydER2aE5GOVpDWERzU3BFNTR2ZjBMZ1plVkxLcngiLCJtYWMiOiIzMTkwZDAxM2JjOTdhMGI3MmYwN2NhMmVjMmRjMzJhYjQxNTNiNjU2Y2FlN2E3ZmY3ZGUzMGQxNGI0ZjZlYTNmIn0%3D");

    var formdata = new FormData();
    formdata.append("token", `${localStorage.getItem('token')}`);

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
    };


    fetch(`https://api.eagleiq.online/api/manager/all-managers`, requestOptions)
        .then(res => res.json())
        .then(data => dispatch({
            type: GET_ALL_MANAGERS,
            payload: data
        })).catch((error) => {
            console.error(error)
        })

}
export const addNewManager = (formData, history) => dispatch => {
    dispatch({
        type: SET_IS_LOADING
    })


    formData.append("token", `${localStorage.getItem('token')}`);


    fetch(`https://api.eagleiq.online/api/manager/add-manager`, {
        method: 'POST',
        body: formData
    })
        .then(res => res.json())
        .then(data => {
            if (data.error) {
                dispatch({
                    type: FETCH_ERROR
                })

                for (var key in data.error) {
                    if (data.error.hasOwnProperty(key)) {
                        console.info(data.error[key])
                    }
                }


            } else {
                dispatch({
                    type: ADD_NEW_MANAGER,
                    payload: data
                })
                dispatch({
                    type: SET_SWEET_ALERT
                })
                history.push('/')
                history.push('/admin/staff-database')
            }

        }).catch((error) => {
            console.error(error)
        })
}
export const deleteManager = (id, history) => dispatch => {
    dispatch({
        type: SET_IS_LOADING
    })


    var formData = new FormData();


    formData.append("token", `${localStorage.getItem('token')}`);
    formData.append("manager_id", id);

    fetch(`https://api.eagleiq.online/api/manager/delete-manager`, {
        method: 'POST',
        body: formData
    })
        .then(res => res.json())
        .then(data => {
            if (data.error) {
                dispatch({
                    type: FETCH_ERROR
                })

                for (var key in data.error) {
                    if (data.error.hasOwnProperty(key)) {
                        console.info(data.error[key])
                    }
                }


            } else {
                dispatch({
                    type: DELETE_MANAGER,
                    payload: data
                })
                dispatch({
                    type: SET_SWEET_ALERT
                })


                history.push('/')
                history.push('/admin/manager-database')

            }

        }).catch((error) => {
            console.error(error)
        })
}
export const updateManager = (formData, history) => dispatch => {
    dispatch({
        type: SET_IS_LOADING
    })


    var myHeaders = new Headers();
    myHeaders.append("Cookie", "XSRF-TOKEN=eyJpdiI6InBEQm5yWWdpWFUzUEl4eUFDK2xvYWc9PSIsInZhbHVlIjoiN0NsdllKSlJYVzAyU1VBVjJodVJIcUFWTCtLaG0rZkxSYVZZbUp5K244Mmgyb01tditzRmF0VG1CeXdaS2JpRTZzMXVUai9BbmlubTZjcks1ZjRhNXZxakhjT1ZEN2Z5YUFvNDRtRHhNbFFGQkdZeHJjR1Z6V0NadGtrR1JVUTAiLCJtYWMiOiJhZmJhZTBiYTU0YWZhNjhlN2UxMGEwMWU5OTgyMDlmNDBlZDg5Mjc2MzFjYzEzNTFlNzI0MTIzNTFmM2IyZTc2In0%3D; laravel_session=eyJpdiI6IlE3MEFvV1Rhd2g2d3crM1phbVpTV1E9PSIsInZhbHVlIjoiNWZpS0NIeGRjQWd4dnN2bXRsMEtmSUwxcnhjWXkyRWZTRERTWkFvdElVU2M4cXI0REtKNEFmNlpQMTFQU3AzUzQrd1RtYTFNVFZwVVY0RzRpcko1T0o2dGJKbmxYb3ptcW1ZME8rQmYzcU1OejI5UkZpcXBONHpWVlBVcTBsV2giLCJtYWMiOiJkNzM0NGFjM2NkNjIwYWFjY2E2MjBkN2QxZDgyMzg1MTQ1YWI2ZGU2OTcxNzU1ZDc5OWE3NDdjNjE0YmM4MDgzIn0%3D");

    formData.append("token", `${localStorage.getItem('token')}`);


    fetch(`https://api.eagleiq.online/api/manager/update-manager`, {
        method: 'POST',
        body: formData
    })
        .then(res => res.json())
        .then(data => {
            if (data.error) {
                dispatch({
                    type: FETCH_ERROR
                })
                for (var key in data.error) {
                    if (data.error.hasOwnProperty(key)) {
                        console.info(data.error[key])
                    }
                }

            } else {
                dispatch({
                    type: UPDATE_MANAGER,
                    payload: data
                })
                dispatch({
                    type: SET_SWEET_ALERT
                })
                history.push('/')
                history.push('/admin/manager-database')
            }

        }).catch((error) => {
            console.error(error)
        })
}
// Staff

export const getAllStaff = () => dispatch => {



    var myHeaders = new Headers();
    myHeaders.append("Cookie", "XSRF-TOKEN=eyJpdiI6Imh4bG1BdzZhc2NrZ1Z3NlhCNEtGSFE9PSIsInZhbHVlIjoicHdhNmxscUdJM1phUzVRTktqNU4wRnJGT0dISUMwOWJuYnhjUVI5ejZlQXozWUxCY050eXRyMEJDZk51WkszUklOcmplRGhGNkZqd0UrRzlBZkJzK2FBcDdERGtxV2pUKzJnallyR1AvL21IQ0h4Y014RzZpUmNSbEVjT25iZjMiLCJtYWMiOiI3MWM2NjcyZTA1NTIyMjllOTdmOWIwZGE3YjM5YTY2NDBhYWU1MTQ5N2M4YjQ2MTJjZjMxNzYxNDNkZDdlZTZiIn0%3D; laravel_session=eyJpdiI6IjNZbHJnTE1Ld0cvaisrQU4ya2Y3VkE9PSIsInZhbHVlIjoiZ0RlMVJLMjZGZ1h0VmNocWVHeVZYQWlHaUxDNkZxS0M4T0lLNS9UL1dKVHpsTFBqTXhEV1pYY1RHaTU2NUNtSmNLOEY4K0ZaWXloSjh1WUJIejBOeSthT0RRbko2aW9rVW4ydER2aE5GOVpDWERzU3BFNTR2ZjBMZ1plVkxLcngiLCJtYWMiOiIzMTkwZDAxM2JjOTdhMGI3MmYwN2NhMmVjMmRjMzJhYjQxNTNiNjU2Y2FlN2E3ZmY3ZGUzMGQxNGI0ZjZlYTNmIn0%3D");

    var formdata = new FormData();
    formdata.append("token", `${localStorage.getItem('token')}`);

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
    };


    fetch(`https://api.eagleiq.online/api/manager/all-staff`, requestOptions)
        .then(res => res.json())
        .then(data => dispatch({
            type: GET_ALL_STAFF,
            payload: data
        })).catch((error) => {
            console.error(error)
        })

}
export const addNewStaff = (data, history) => dispatch => {
    dispatch({
        type: SET_IS_LOADING
    })

    var myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Cookie", "XSRF-TOKEN=eyJpdiI6IjYvTlR3dUc3ZGJMaUxmRGJsTnk2R1E9PSIsInZhbHVlIjoiSTl6c29mQTg1bk9TWEtQWldlU3NmNDk3TnNJZVBVY2dOSFQ2TktIZFZrc2NJd2wyQXNxVlpYdk55UXQ4TTFLeVBjbXd5WVF6WUtLQTF3ci9na0F6a0hJN0ltMkdES21qUGx5b0hHbHJ2UlBhMGpMVU1acWVMS1k2RVZqWDVjRUUiLCJtYWMiOiIxNzVjZDZkNmRlNGM2OGJhMzIwNDc3Mzk1OTgxZjIwYzIwY2FmZTA0YmRmMzAyM2FkN2RmYjQ1NjU1MWZlZjM2In0%3D; laravel_session=eyJpdiI6Imd0S1hsWVBDejVJT1d3VVlNS1NrMVE9PSIsInZhbHVlIjoiRXpjNjBPbSsvRHROUkI0Z2R1ZDhsMGcyWG9GS2tZU3YzR3AwS3ltT0xOWm1CeURBNXZpaHREanFpTzdUZVFMTWlHd1ZNdFUxK2k1NTIxcmlIVjVGMXlyOHU0cEs3dG1ka2o5SW55ViszL2Y2blNuV0dxRDBxUUl6WWNFY0lZaUQiLCJtYWMiOiJhMzUyMGI4OWZkZjgwOTI0YjIwMjk1YmFlNzRjZjllODM0ZTU4YzFlZjE3OTdlZTg5YzM4ZTc5YTY5MzBkYmU1In0%3D");

    console.log(`localStorage.getItem("token")===>`, localStorage.getItem("token"))

    var formdata = new FormData();
    formdata.append("company_id", data?.company_id)
    formdata.append("fname", data?.fname)
    formdata.append("lname", data?.lname)
    formdata.append("title", data?.title)
    formdata.append("address", data?.address)
    formdata.append("town", data?.town)
    formdata.append("post_code", data?.post_code)
    formdata.append("phone_no", data?.phone_no)
    formdata.append("mobile_no", data?.mobile_no)
    formdata.append("email", data?.email)
    formdata.append("password", data?.password)
    formdata.append("engagement_date", data?.engagement_date)
    formdata.append("dob", data?.dob)
    formdata.append("ni_no", data?.ni_no)
    formdata.append("bank", data?.bank)
    formdata.append("sort_code", data?.sort_Code)
    formdata.append("sites", JSON.stringify(data?.sites))
    formdata.append("next_of_kin_username", data.next_of_kin_username)
    formdata.append("next_of_kin_email", data.next_of_kin_email)
    formdata.append("account_no", data?.account_no)
    // formdata.append("file", data?.attachement)
    data?.attachement.forEach((data, index) => {
        formdata.append(`file[]`, data);
    });
    formdata.append("rate_of_pay", data.rate_of_pay)
    formdata.append("division", data?.division)
    formdata.append("service_team", data?.service_team)
    formdata.append("invoice_date", data?.invoice_date)
    formdata.append("visits", data?.visits)
    formdata.append("token", localStorage.getItem("token"))

    console.log("formdata===>", formdata)

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
    };




    fetch(`https://api.eagleiq.online/api/manager/add-staff`, requestOptions)
        .then(res => res.json())
        .then(data => {
            if (data.error) {
                dispatch({
                    type: FETCH_ERROR
                })

                for (var key in data.error) {
                    if (data.error.hasOwnProperty(key)) {
                        window.alert(data.error[key])
                        console.info(data.error[key])
                    }
                }
            } else {
                dispatch({
                    type: ADD_NEW_STAFF,
                    payload: data
                })
                dispatch({
                    type: SET_SWEET_ALERT
                })

                history.push('/')

                history.push('/admin/staff-database')
            }

        }).catch((error) => {
            console.error(error)
        })
}
export const updateStaff = (data, history) => dispatch => {

    dispatch({
        type: SET_IS_LOADING
    })

    var myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Cookie", "XSRF-TOKEN=eyJpdiI6IjYvTlR3dUc3ZGJMaUxmRGJsTnk2R1E9PSIsInZhbHVlIjoiSTl6c29mQTg1bk9TWEtQWldlU3NmNDk3TnNJZVBVY2dOSFQ2TktIZFZrc2NJd2wyQXNxVlpYdk55UXQ4TTFLeVBjbXd5WVF6WUtLQTF3ci9na0F6a0hJN0ltMkdES21qUGx5b0hHbHJ2UlBhMGpMVU1acWVMS1k2RVZqWDVjRUUiLCJtYWMiOiIxNzVjZDZkNmRlNGM2OGJhMzIwNDc3Mzk1OTgxZjIwYzIwY2FmZTA0YmRmMzAyM2FkN2RmYjQ1NjU1MWZlZjM2In0%3D; laravel_session=eyJpdiI6Imd0S1hsWVBDejVJT1d3VVlNS1NrMVE9PSIsInZhbHVlIjoiRXpjNjBPbSsvRHROUkI0Z2R1ZDhsMGcyWG9GS2tZU3YzR3AwS3ltT0xOWm1CeURBNXZpaHREanFpTzdUZVFMTWlHd1ZNdFUxK2k1NTIxcmlIVjVGMXlyOHU0cEs3dG1ka2o5SW55ViszL2Y2blNuV0dxRDBxUUl6WWNFY0lZaUQiLCJtYWMiOiJhMzUyMGI4OWZkZjgwOTI0YjIwMjk1YmFlNzRjZjllODM0ZTU4YzFlZjE3OTdlZTg5YzM4ZTc5YTY5MzBkYmU1In0%3D");

    var formdata = new FormData();
    formdata.append("staff_id", data.staff_id)
    formdata.append("company_id", data.company_id)
    formdata.append("fname", data.fname)
    formdata.append("lname", data.lname)
    formdata.append("title", data.title)
    formdata.append("address", data.address)
    formdata.append("town", data.town)
    formdata.append("post_code", data.post_code)
    formdata.append("phone_no", data.phone_no)
    formdata.append("mobile_no", data.mobile_no)
    formdata.append("email", data.email)
    formdata.append("password", data.password)
    formdata.append("engagement_date", data.engagement_date)
    formdata.append("dob", data.dob)
    formdata.append("ni_no", data.ni_no)
    formdata.append("bank", data.bank)
    formdata.append("sort_Code", data.sort_Code)
    formdata.append("sites", JSON.stringify(data.sites))
    formdata.append("next_of_kin_username", data.next_of_kin_username)
    formdata.append("next_of_kin_email", data.next_of_kin_email)
    formdata.append("account_no", data.account_no)
    // formdata.append("file", data.file)
    data.file.forEach((data, index) => {
        formdata.append(`file[]`, data);
    });
    formdata.append("division", data.division)
    formdata.append("service_team", data.service_team)
    formdata.append("invoice_date", data.invoice_date)
    formdata.append("visits", data.visits)
    formdata.append("token", localStorage.getItem("token"))

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
    };

    fetch(`https://api.eagleiq.online/api/manager/update-staff`,
        requestOptions
    ).then(res =>
        res.json()
    ).then(data => {
        if (data.error) {
            dispatch({
                type: FETCH_ERROR
            })
            for (var key in data.error) {
                if (data.error.hasOwnProperty(key)) {
                    console.info(data.error[key])
                }
            }
        } else {
            dispatch({
                type: UPDATE_STAFF,
                payload: data
            })
            dispatch({
                type: SET_SWEET_ALERT
            })
            history.push('/')

            history.push('/admin/staff-database')

        }

    }).catch((error) => {
        console.error(error)
    })
}
export const deleteStaff = (id, history) => dispatch => {
    dispatch({
        type: SET_IS_LOADING
    })

    var formData = new FormData();


    formData.append("token", `${localStorage.getItem('token')}`);
    formData.append("staff_id", id);

    fetch(`https://api.eagleiq.online/api/manager/delete-staff`, {
        method: 'POST',
        body: formData
    })
        .then(res => res.json())
        .then(data => {
            if (data.error) {
                dispatch({
                    type: FETCH_ERROR
                })
                for (var key in data.error) {
                    if (data.error.hasOwnProperty(key)) {
                        console.info(data.error[key])
                    }
                }


            } else {
                dispatch({
                    type: DELETE_STAFF,
                    payload: data
                })
                dispatch({
                    type: SET_SWEET_ALERT
                })
                history.push('/')
                history.push('/admin/staff-database')

            }

        }).catch((error) => {
            console.error(error)
        })
}
// Clients
export const getAllClients = () => {
    return async (dispatch) => {
        dispatch({ type: CHANGE_GLOBAL_LOADER, payload: true })

        var myHeaders = new Headers();
        myHeaders.append("Cookie", "XSRF-TOKEN=eyJpdiI6IndVSTRZR3VQRlkybGtQcnVKcUpuUVE9PSIsInZhbHVlIjoiVUg3ZnVubEQyNEFRWlpDYWdYNTIreWNIcmNkbDltWVpDaTNRWGNodXNmMVNCdm9zam5XWDlzVlo4R0JhSzZ6emdPRW1MYmlITmgwVDlZUFZVWXhxSHk4aXRjZFgxak1qbVIxVThyVlQwK1NKRzJzcHhRZ3QrZnlIUktOT1lJRFgiLCJtYWMiOiJhMmY1ZDA2OThlYWQ3YTRlMmE3YzAzZmZlZWQ1ZTU2ZTA3NjM4NjYyNTE0ODNjOTU2NmYwNGFmNzc1NWZmOWZiIn0%3D; laravel_session=eyJpdiI6IlhUZ3pXaXhUbGVIZjZUZVlMM0Y3dGc9PSIsInZhbHVlIjoiWWg5SE9XNkJ3OG1VcUs2RThwdU0wY3F5RkdTRmhsTzNzRURBVFlCVFBCWXQyUmRjNkNLUnVFQk03cjJXWGsrWXdrb3pPcHcxZjdLVDB1Q2hSb243VVhnMi9wRCtXeXhITjJPMkYzVE9QVXRoNi9aTE9YZTF3Mlh4ZFJhTEZxR0giLCJtYWMiOiJkMjZiMzY5YzZlMzMwYTdiYjgyNDRhYTRkMGQ5NzdjNzIyMjRiNWFjNThhMWQ3MzM0MDJhOTIxZWU1ZThmYzZhIn0%3D");
        var formdata = new FormData();
        formdata.append("token", `${localStorage.getItem('token')}`);
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        let data = await fetch(`https://api.eagleiq.online/api/manager/all-clients`, requestOptions)
            .then(res => res.json())
            .catch((error) => {
                console.error(error)
            })


        dispatch({ type: GET_ALL_CLIENTS, payload: data })
        dispatch(getAllLeaveRequests())
    }
}
export const addNewClient = (formData, history) => dispatch => {
    dispatch({ type: SET_IS_LOADING })


    formData.append("token", `${localStorage.getItem('token')}`);


    fetch(`https://api.eagleiq.online/api/manager/add-client`, {
        method: 'POST',
        body: formData
    })
        .then(res => res.json())
        .then(data => {
            if (data.error) {
                dispatch({
                    type: FETCH_ERROR
                })

                for (var key in data.error) {
                    if (data.error.hasOwnProperty(key)) {
                        console.info(data.error[key])
                    }
                }


            } else {
                dispatch({
                    type: ADD_NEW_CLIENT,
                    payload: data
                })
                dispatch({ type: SET_SWEET_ALERT })
                history.push('/')
                history.push('/admin/manage-clients')
            }

        }).catch((error) => {
            console.error(error)
        })
}

export const updateClient = (formData, history) => dispatch => {

    var myHeaders = new Headers();
    myHeaders.append("Cookie", "XSRF-TOKEN=eyJpdiI6InBEQm5yWWdpWFUzUEl4eUFDK2xvYWc9PSIsInZhbHVlIjoiN0NsdllKSlJYVzAyU1VBVjJodVJIcUFWTCtLaG0rZkxSYVZZbUp5K244Mmgyb01tditzRmF0VG1CeXdaS2JpRTZzMXVUai9BbmlubTZjcks1ZjRhNXZxakhjT1ZEN2Z5YUFvNDRtRHhNbFFGQkdZeHJjR1Z6V0NadGtrR1JVUTAiLCJtYWMiOiJhZmJhZTBiYTU0YWZhNjhlN2UxMGEwMWU5OTgyMDlmNDBlZDg5Mjc2MzFjYzEzNTFlNzI0MTIzNTFmM2IyZTc2In0%3D; laravel_session=eyJpdiI6IlE3MEFvV1Rhd2g2d3crM1phbVpTV1E9PSIsInZhbHVlIjoiNWZpS0NIeGRjQWd4dnN2bXRsMEtmSUwxcnhjWXkyRWZTRERTWkFvdElVU2M4cXI0REtKNEFmNlpQMTFQU3AzUzQrd1RtYTFNVFZwVVY0RzRpcko1T0o2dGJKbmxYb3ptcW1ZME8rQmYzcU1OejI5UkZpcXBONHpWVlBVcTBsV2giLCJtYWMiOiJkNzM0NGFjM2NkNjIwYWFjY2E2MjBkN2QxZDgyMzg1MTQ1YWI2ZGU2OTcxNzU1ZDc5OWE3NDdjNjE0YmM4MDgzIn0%3D");

    formData.append("token", `${localStorage.getItem('token')}`);


    fetch(`https://api.eagleiq.online/api/manager/update-client`, {
        method: 'POST',
        body: formData
    })
        .then(res => res.json())
        // .then(data => dispatch({
        //     type: UPDATE_MANAGER,
        //     payload: data
        // })

        .then(data => {
            if (data.error) {
                console.info(data.error)
            } else {
                dispatch({
                    type: UPDATE_CLIENT,
                    payload: data
                })
                history.push('/')
                history.push('/admin/manage-clients')
            }

        }).catch((error) => {
            console.error(error)
        })
}
export const deleteClient = (id, history) => dispatch => {
    dispatch({ type: SET_IS_LOADING })

    var formData = new FormData();


    formData.append("token", `${localStorage.getItem('token')}`);
    formData.append("client_id", id);

    fetch(`https://api.eagleiq.online/api/manager/delete-client`, {
        method: 'POST',
        body: formData
    })
        .then(res => res.json())
        .then(data => {
            if (data.error) {
                dispatch({
                    type: FETCH_ERROR
                })
                for (var key in data.error) {
                    if (data.error.hasOwnProperty(key)) {
                        console.info(data.error[key])
                    }
                }


            } else {
                dispatch({
                    type: DELETE_CLIENT,
                    payload: data
                })
                dispatch({
                    type: SET_SWEET_ALERT
                })
                history.push('/')
                history.push('/admin/manage-clients')
            }

        }).catch((error) => {
            console.error(error)
        })
}
// Sites
export const getAllSites = () => {
    return async (dispatch) => {
        dispatch({ type: CHANGE_GLOBAL_LOADER, payload: true })

        var myHeaders = new Headers();
        myHeaders.append("Cookie", "XSRF-TOKEN=eyJpdiI6Ikc5bCtzZ0s1cHo3cEFqeDZ3dzNybVE9PSIsInZhbHVlIjoicWhpcXJCalRlWjB1d3VIaEZYOTVvcWl0dEpDVFhBZnZOTjlPTFZSeGRMUWVjSGlGQ0FxbDBDcnZ6Ni9NdGZwVURtMGxWT1B3aEgwbGJJQVdLVzdqeXcwdjEwS3lPdTB3UzM1T2xHZnFQMjhLOUhaSERTUmVtcmhpWk01bmwybkwiLCJtYWMiOiJjMGFiYzk4MTE5OGNlODc2ZDBmNDQ2NTkxNmM1ZWM1MzdjMDYxYTI3NmVlZGVkMzNiOTQ0YjMyNWRlZTE5ZTNkIn0%3D; laravel_session=eyJpdiI6ImRlVVJNak55c1ZicXJVWmU2d3pWWVE9PSIsInZhbHVlIjoibEQzR1BrSUZNN2dFd1ZVL1lROE4zZG5MOXRSQ0RXWStpQ3hHRFQ3ZGVkOS9HcDdJLzRXemVxZnFwdVh1aWdlYjdMck1MQWtqQ2RjaERmL0RBN0ZqM0dkN2t6WmdyZU96L2ROSEZmOGlxb1FMamtqZlJoSXZsTjdybWRTeGc4MW8iLCJtYWMiOiI0NDdlODExZDlhZTUxNWUyYmE0YjdmZjJkODFjM2I5MDlhOTRjZTgxNzlmZWY5MjVmNjZkOTljOWRlZDVlMzU3In0%3D");

        var formdata = new FormData();
        formdata.append("token", `${localStorage.getItem('token')}`);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        let data = await fetch(`https://api.eagleiq.online/api/manager/all-sites`, requestOptions)
            .then(res => res.json())
            .catch((error) => {
                console.error(error)
            })

        dispatch({ type: GET_ALL_SITES, payload: data })
        dispatch({ type: CHANGE_GLOBAL_LOADER, payload: false })
    }
}
export const getSiteProfile = (formdata) => dispatch => {



    var myHeaders = new Headers();
    myHeaders.append("Cookie", "XSRF-TOKEN=eyJpdiI6IndVSTRZR3VQRlkybGtQcnVKcUpuUVE9PSIsInZhbHVlIjoiVUg3ZnVubEQyNEFRWlpDYWdYNTIreWNIcmNkbDltWVpDaTNRWGNodXNmMVNCdm9zam5XWDlzVlo4R0JhSzZ6emdPRW1MYmlITmgwVDlZUFZVWXhxSHk4aXRjZFgxak1qbVIxVThyVlQwK1NKRzJzcHhRZ3QrZnlIUktOT1lJRFgiLCJtYWMiOiJhMmY1ZDA2OThlYWQ3YTRlMmE3YzAzZmZlZWQ1ZTU2ZTA3NjM4NjYyNTE0ODNjOTU2NmYwNGFmNzc1NWZmOWZiIn0%3D; laravel_session=eyJpdiI6IlhUZ3pXaXhUbGVIZjZUZVlMM0Y3dGc9PSIsInZhbHVlIjoiWWg5SE9XNkJ3OG1VcUs2RThwdU0wY3F5RkdTRmhsTzNzRURBVFlCVFBCWXQyUmRjNkNLUnVFQk03cjJXWGsrWXdrb3pPcHcxZjdLVDB1Q2hSb243VVhnMi9wRCtXeXhITjJPMkYzVE9QVXRoNi9aTE9YZTF3Mlh4ZFJhTEZxR0giLCJtYWMiOiJkMjZiMzY5YzZlMzMwYTdiYjgyNDRhYTRkMGQ5NzdjNzIyMjRiNWFjNThhMWQ3MzM0MDJhOTIxZWU1ZThmYzZhIn0%3D");


    formdata.append("token", `${localStorage.getItem('token')}`);

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
    };

    fetch(`https://api.eagleiq.online/api/manager/show-site`, requestOptions)
        .then(res => res.json())
        .then(data => dispatch({
            type: GET_SITE_PROFILE,
            payload: data
        })).catch((error) => {
            console.error(error)
        })

}

export const addNewSite = (raw, history) => dispatch => {

    dispatch({
        type: SET_IS_LOADING
    })

    const {
        company_id,
        client_id,
        site_name,
        emergency_contact_no,
        emergency_phone_no,
        out_of_hours,
        alarm_call,
        lat,
        lan,
        location,
        town,
        post_code,
        address,
        email,
        client_contact,
        file,
        areas,
        buildings,
    } = raw || {}


    var myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Cookie", "XSRF-TOKEN=eyJpdiI6IjYvTlR3dUc3ZGJMaUxmRGJsTnk2R1E9PSIsInZhbHVlIjoiSTl6c29mQTg1bk9TWEtQWldlU3NmNDk3TnNJZVBVY2dOSFQ2TktIZFZrc2NJd2wyQXNxVlpYdk55UXQ4TTFLeVBjbXd5WVF6WUtLQTF3ci9na0F6a0hJN0ltMkdES21qUGx5b0hHbHJ2UlBhMGpMVU1acWVMS1k2RVZqWDVjRUUiLCJtYWMiOiIxNzVjZDZkNmRlNGM2OGJhMzIwNDc3Mzk1OTgxZjIwYzIwY2FmZTA0YmRmMzAyM2FkN2RmYjQ1NjU1MWZlZjM2In0%3D; laravel_session=eyJpdiI6Imd0S1hsWVBDejVJT1d3VVlNS1NrMVE9PSIsInZhbHVlIjoiRXpjNjBPbSsvRHROUkI0Z2R1ZDhsMGcyWG9GS2tZU3YzR3AwS3ltT0xOWm1CeURBNXZpaHREanFpTzdUZVFMTWlHd1ZNdFUxK2k1NTIxcmlIVjVGMXlyOHU0cEs3dG1ka2o5SW55ViszL2Y2blNuV0dxRDBxUUl6WWNFY0lZaUQiLCJtYWMiOiJhMzUyMGI4OWZkZjgwOTI0YjIwMjk1YmFlNzRjZjllODM0ZTU4YzFlZjE3OTdlZTg5YzM4ZTc5YTY5MzBkYmU1In0%3D");

    var formdata = new FormData();
    formdata.append("company_id", company_id)
    formdata.append("client_id", client_id)
    formdata.append("site_name", site_name)
    formdata.append("emergency_contact_no", emergency_contact_no)
    formdata.append("emergency_phone_no", emergency_phone_no)
    formdata.append("out_of_hours", out_of_hours)
    formdata.append("alarm_call", alarm_call)
    formdata.append("lat", lat)
    formdata.append("lan", lan)
    formdata.append("location", location)
    formdata.append("town", town)
    formdata.append("post_code", post_code)
    formdata.append("address", address)
    formdata.append("email", email)
    formdata.append("client_contact", client_contact)
    // formdata.append("file", file)
    file.forEach((data, index) => {
        formdata.append(`file[]`, data);
    });
    areas.forEach((data, index) => {
        formdata.append(`areas[]`, data);
    });
    buildings.forEach((data, index) => {
        formdata.append(`buildings[]`, data);
    });
    formdata.append("token", localStorage.getItem("token"))

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
    };


    fetch(`https://api.eagleiq.online/api/manager/create-site`, requestOptions)
        .then(res => res.json())
        .then(data => {
            if (data.error) {
                dispatch({
                    type: FETCH_ERROR
                })

                for (var key in data.error) {
                    if (data.error.hasOwnProperty(key)) {
                        console.info(data.error[key])
                    }
                }


            } else {
                dispatch({
                    type: ADD_NEW_SITE,
                    payload: data
                })
                dispatch({
                    type: SET_SWEET_ALERT
                })
                history.push('/')
                history.push('/admin/manage-sites')

            }

        }

        )
}

export const updateSite = (raw, history) => dispatch => {

    dispatch({
        type: SET_IS_LOADING
    })

    const {
        site_id,
        company_id,
        client_id,
        site_name,
        emergency_contact_no,
        emergency_phone_no,
        out_of_hours,
        alarm_call,
        lat,
        lan,
        location,
        town,
        post_code,
        address,
        email,
        client_contact,
        file,
        areas,
        buildings,
    } = raw || {}

    var myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Cookie", "XSRF-TOKEN=eyJpdiI6IjYvTlR3dUc3ZGJMaUxmRGJsTnk2R1E9PSIsInZhbHVlIjoiSTl6c29mQTg1bk9TWEtQWldlU3NmNDk3TnNJZVBVY2dOSFQ2TktIZFZrc2NJd2wyQXNxVlpYdk55UXQ4TTFLeVBjbXd5WVF6WUtLQTF3ci9na0F6a0hJN0ltMkdES21qUGx5b0hHbHJ2UlBhMGpMVU1acWVMS1k2RVZqWDVjRUUiLCJtYWMiOiIxNzVjZDZkNmRlNGM2OGJhMzIwNDc3Mzk1OTgxZjIwYzIwY2FmZTA0YmRmMzAyM2FkN2RmYjQ1NjU1MWZlZjM2In0%3D; laravel_session=eyJpdiI6Imd0S1hsWVBDejVJT1d3VVlNS1NrMVE9PSIsInZhbHVlIjoiRXpjNjBPbSsvRHROUkI0Z2R1ZDhsMGcyWG9GS2tZU3YzR3AwS3ltT0xOWm1CeURBNXZpaHREanFpTzdUZVFMTWlHd1ZNdFUxK2k1NTIxcmlIVjVGMXlyOHU0cEs3dG1ka2o5SW55ViszL2Y2blNuV0dxRDBxUUl6WWNFY0lZaUQiLCJtYWMiOiJhMzUyMGI4OWZkZjgwOTI0YjIwMjk1YmFlNzRjZjllODM0ZTU4YzFlZjE3OTdlZTg5YzM4ZTc5YTY5MzBkYmU1In0%3D");

    var formdata = new FormData();
    formdata.append("site_id", site_id)
    formdata.append("company_id", company_id)
    formdata.append("client_id", client_id)
    formdata.append("site_name", site_name)
    formdata.append("emergency_contact_no", emergency_contact_no)
    formdata.append("emergency_phone_no", emergency_phone_no)
    formdata.append("out_of_hours", out_of_hours)
    formdata.append("alarm_call", alarm_call)
    formdata.append("lat", lat)
    formdata.append("lan", lan)
    formdata.append("location", location)
    formdata.append("town", town)
    formdata.append("post_code", post_code)
    formdata.append("address", address)
    formdata.append("email", email)
    formdata.append("client_contact", client_contact)
    // formdata.append("file", file)
    formdata.append("token", localStorage.getItem("token"))

    file.forEach((data, index) => {
        formdata.append(`file[]`, data);
    });
    areas.forEach((data, index) => {
        formdata.append(`areas[]`, data);
    });
    buildings.forEach((data, index) => {
        formdata.append(`buildings[]`, data);
    });

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
    };

    fetch(`https://api.eagleiq.online/api/manager/update-site`, requestOptions)
        .then(res => res.json())
        .then(data => {
            if (data.error) {
                dispatch({
                    type: FETCH_ERROR
                })
                for (var key in data.error) {
                    if (data.error.hasOwnProperty(key)) {
                        console.info(data.error[key])
                    }
                }
            } else {
                dispatch({
                    type: UPDATE_SITE,
                    payload: data
                })
                dispatch({
                    type: SET_SWEET_ALERT
                })
                dispatch({
                    type: GET_USER_PROFILE,
                    payload: {}
                })
                history.push('/')
                history.push('/admin/manage-sites')
            }

        }

        )
}
export const deleteSite = (id, history) => dispatch => {
    dispatch({
        type: SET_IS_LOADING
    })
    var formData = new FormData();


    formData.append("token", `${localStorage.getItem('token')}`);
    formData.append("site_id", id);

    fetch(`https://api.eagleiq.online/api/manager/delete-site`, {
        method: 'POST',
        body: formData
    })
        .then(res => res.json())
        .then(data => {
            if (data.error) {
                dispatch({
                    type: FETCH_ERROR
                })
                for (var key in data.error) {
                    if (data.error.hasOwnProperty(key)) {
                        console.info(data.error[key])
                    }
                }


            } else {
                dispatch({
                    type: DELETE_SITE,
                    payload: data
                })
                dispatch({
                    type: SET_SWEET_ALERT
                })
                history.push('/')
                history.push('/admin/manage-sites')
            }

        }).catch((error) => {
            console.error(error)
        })
}
// Stocks
export const getAllStocks = () => {
    return async (dispatch) => {
        dispatch({ type: CHANGE_GLOBAL_LOADER, payload: true })

        var myHeaders = new Headers();
        myHeaders.append("Cookie", "XSRF-TOKEN=eyJpdiI6Ikc5bCtzZ0s1cHo3cEFqeDZ3dzNybVE9PSIsInZhbHVlIjoicWhpcXJCalRlWjB1d3VIaEZYOTVvcWl0dEpDVFhBZnZOTjlPTFZSeGRMUWVjSGlGQ0FxbDBDcnZ6Ni9NdGZwVURtMGxWT1B3aEgwbGJJQVdLVzdqeXcwdjEwS3lPdTB3UzM1T2xHZnFQMjhLOUhaSERTUmVtcmhpWk01bmwybkwiLCJtYWMiOiJjMGFiYzk4MTE5OGNlODc2ZDBmNDQ2NTkxNmM1ZWM1MzdjMDYxYTI3NmVlZGVkMzNiOTQ0YjMyNWRlZTE5ZTNkIn0%3D; laravel_session=eyJpdiI6ImRlVVJNak55c1ZicXJVWmU2d3pWWVE9PSIsInZhbHVlIjoibEQzR1BrSUZNN2dFd1ZVL1lROE4zZG5MOXRSQ0RXWStpQ3hHRFQ3ZGVkOS9HcDdJLzRXemVxZnFwdVh1aWdlYjdMck1MQWtqQ2RjaERmL0RBN0ZqM0dkN2t6WmdyZU96L2ROSEZmOGlxb1FMamtqZlJoSXZsTjdybWRTeGc4MW8iLCJtYWMiOiI0NDdlODExZDlhZTUxNWUyYmE0YjdmZjJkODFjM2I5MDlhOTRjZTgxNzlmZWY5MjVmNjZkOTljOWRlZDVlMzU3In0%3D");

        var formdata = new FormData();
        formdata.append("token", `${localStorage.getItem('token')}`);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        let data = await fetch(`https://api.eagleiq.online/api/manager/all-stocks`, requestOptions)
            .then(res => res.json())
            .catch((error) => {
                console.error(error)
            })

        dispatch({ type: GET_ALL_STOCKS, payload: data })
        dispatch({ type: CHANGE_GLOBAL_LOADER, payload: false })
    }
}
// Stock Requests

export const getStockRequests = () => {
    return async (dispatch) => {
        dispatch({ type: CHANGE_GLOBAL_LOADER, payload: true })
        console.log('action')
        var myHeaders = new Headers();
        myHeaders.append("Cookie", "XSRF-TOKEN=eyJpdiI6Ikc5bCtzZ0s1cHo3cEFqeDZ3dzNybVE9PSIsInZhbHVlIjoicWhpcXJCalRlWjB1d3VIaEZYOTVvcWl0dEpDVFhBZnZOTjlPTFZSeGRMUWVjSGlGQ0FxbDBDcnZ6Ni9NdGZwVURtMGxWT1B3aEgwbGJJQVdLVzdqeXcwdjEwS3lPdTB3UzM1T2xHZnFQMjhLOUhaSERTUmVtcmhpWk01bmwybkwiLCJtYWMiOiJjMGFiYzk4MTE5OGNlODc2ZDBmNDQ2NTkxNmM1ZWM1MzdjMDYxYTI3NmVlZGVkMzNiOTQ0YjMyNWRlZTE5ZTNkIn0%3D; laravel_session=eyJpdiI6ImRlVVJNak55c1ZicXJVWmU2d3pWWVE9PSIsInZhbHVlIjoibEQzR1BrSUZNN2dFd1ZVL1lROE4zZG5MOXRSQ0RXWStpQ3hHRFQ3ZGVkOS9HcDdJLzRXemVxZnFwdVh1aWdlYjdMck1MQWtqQ2RjaERmL0RBN0ZqM0dkN2t6WmdyZU96L2ROSEZmOGlxb1FMamtqZlJoSXZsTjdybWRTeGc4MW8iLCJtYWMiOiI0NDdlODExZDlhZTUxNWUyYmE0YjdmZjJkODFjM2I5MDlhOTRjZTgxNzlmZWY5MjVmNjZkOTljOWRlZDVlMzU3In0%3D");

        var formdata = new FormData();
        formdata.append("token", `${localStorage.getItem('token')}`);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        let data = await fetch(`https://api.eagleiq.online/api/manager/all-stock-request`, requestOptions)
            .then(res => res.json())
            .catch((error) => {
                console.error(error)
            })

        dispatch({ type: GET_STOCK_REQUESTS, payload: data })
        dispatch({ type: CHANGE_GLOBAL_LOADER, payload: false })
    }
}
export const changeStockStatus = (id, status, history) => {
    return async (dispatch) => {
        dispatch({ type: CHANGE_GLOBAL_LOADER, payload: true })

        var formData = new FormData();

        formData.append("id", id);
        formData.append("status", status);
        formData.append("token", `${localStorage.getItem('token')}`);


        let data = await fetch(`https://api.eagleiq.online/api/manager/update-stock-status`, { method: 'POST', body: formData })
            .then(res => res.json())
            .catch((error) => {
                console.error(error)
            })

        if (data.error) {
            dispatch({
                type: FETCH_ERROR
            })
            for (var key in data.error) {
                if (data.error.hasOwnProperty(key)) {
                    console.info(data.error[key])
                }
            }
        } else {
            dispatch({
                type: CHANGE_STOCK_STATUS,
                payload: data
            })
            dispatch({
                type: SET_SWEET_ALERT
            })
            history.push('/')
            history.push('/admin/stock-requests')
        }
        dispatch({ type: CHANGE_GLOBAL_LOADER, payload: false })
    }
}
export const addNewStock = (formData, history) => dispatch => {
    dispatch({
        type: SET_IS_LOADING
    })


    formData.append("token", `${localStorage.getItem('token')}`);


    fetch(`https://api.eagleiq.online/api/manager/create-stock`, {
        method: 'POST',
        body: formData
    })
        .then(res => res.json())
        .then(data => {
            if (data.error) {
                dispatch({
                    type: FETCH_ERROR
                })

                for (var key in data.error) {
                    if (data.error.hasOwnProperty(key)) {
                        console.info(data.error[key])
                    }
                }


            } else {
                dispatch({
                    type: ADD_NEW_STOCK,
                    payload: data
                })
                dispatch({
                    type: SET_SWEET_ALERT
                })
                history.push('/')
                history.push('/admin/stock-requests')
            }

        }).catch((error) => {
            console.error(error)
        })
}
export const addStockCategory = (formData, history) => dispatch => {


    formData.append("token", `${localStorage.getItem('token')}`);


    fetch(`https://api.eagleiq.online/api/manager/create-stock-cat`, {
        method: 'POST',
        body: formData
    })
        .then(res => res.json())
        .then(data => {
            if (data.error) {

                for (var key in data.error) {
                    if (data.error.hasOwnProperty(key)) {
                        console.info(data.error[key])
                    }
                }


            } else {
                dispatch({
                    type: ADD_STOCK_CATEGORY,
                    payload: data
                })
                history.push('/')
                history.push('/admin/stock-requests')
            }

        }).catch((error) => {
            console.error(error)
        })
}
export const updateStock = (formData, history) => dispatch => {
    dispatch({
        type: SET_IS_LOADING
    })

    var myHeaders = new Headers();
    myHeaders.append("Cookie", "XSRF-TOKEN=eyJpdiI6Imlad3BlMTk2QnBjaE4rMXlMVzk3VEE9PSIsInZhbHVlIjoiYTZoUW40dXdTSnZQWmtsOHdweWJFcGUrQnFCUXpxdE5VRG9DMXVDQ2s2RElMU2ZmWFEzKzMyRUtreExQeGtXSWFvRTBaOGdlcUZEYUpmRTcrenFGdUl4L0ZyVTJqTGZZK0hBaUhUVURydDdtL2VrSDc1MWpUNG1RdGZrMjg4UHoiLCJtYWMiOiJkZWUyMTNiZTQ0NGU0NjFhY2JhOGVhZDY0MTIyZTJmODVlZGFjOGMyNWViNDE0YzljOGY0NTJlZDU2YjI4ZDZhIn0%3D; laravel_session=eyJpdiI6InJ3SXhEQ1NkSnBkSFhuZ0lUdHZKK0E9PSIsInZhbHVlIjoicGV0SFFBL0VDRU9yN1NabFc5dFhsaUxUVFcwb051alhMWFRPSW1veSt3L2NzWmpxdExtMGZCUHlZdTd3ZkNLREFPdlV2ckwwd0QrcFJ2VEFWT2VlVHpyNlFlR3YrdmpzeG00akxOWURoTWNzSXhvcnh4YndQV3gzU0NXZ09VUVMiLCJtYWMiOiIwMGZhNWM2M2Q1ZTg3Y2FjN2YzNzI5MjUxNTY3ZDBhNmEzMWIyZmQzZDc3YzAzN2IyN2RiZTg1OTQ3NTJiOTZiIn0%3D");

    formData.append("token", `${localStorage.getItem('token')}`);


    fetch(`https://api.eagleiq.online/api/manager/update-stock`, {
        method: 'POST',
        body: formData
    })
        .then(res => res.json())
        // .then(data => dispatch({
        //     type: UPDATE_MANAGER,
        //     payload: data
        // })

        .then(data => {
            if (data.error) {
                dispatch({
                    type: FETCH_ERROR
                })
                for (var key in data.error) {
                    if (data.error.hasOwnProperty(key)) {
                        console.info(data.error[key])
                    }
                }
            } else {
                dispatch({
                    type: UPDATE_STOCK,
                    payload: data
                })
                dispatch({
                    type: SET_SWEET_ALERT
                })
                history.push('/')
                history.push('/admin/stock-requests')
            }

        }).catch((error) => {
            console.error(error)
        })
}
export const deleteStock = (id, history) => dispatch => {
    dispatch({
        type: SET_IS_LOADING
    })

    var formData = new FormData();


    formData.append("token", `${localStorage.getItem('token')}`);
    formData.append("stock_id", id);

    fetch(`https://api.eagleiq.online/api/manager/delete-stock`, {
        method: 'POST',
        body: formData
    })
        .then(res => res.json())
        .then(data => {
            if (data.error) {

                for (var key in data.error) {
                    if (data.error.hasOwnProperty(key)) {
                        console.info(data.error[key])
                    }
                }
                dispatch({
                    type: FETCH_ERROR
                })

            } else {
                dispatch({
                    type: DELETE_STOCK,
                    payload: data
                })
                dispatch({
                    type: SET_SWEET_ALERT
                })
                history.push('/')
                history.push('/admin/stock-requests')
            }

        }).catch((error) => {
            console.error(error)
        })
}
export const getAllStockCategories = () => dispatch => {
    var myHeaders = new Headers();
    myHeaders.append("Cookie", "XSRF-TOKEN=eyJpdiI6Ikc5bCtzZ0s1cHo3cEFqeDZ3dzNybVE9PSIsInZhbHVlIjoicWhpcXJCalRlWjB1d3VIaEZYOTVvcWl0dEpDVFhBZnZOTjlPTFZSeGRMUWVjSGlGQ0FxbDBDcnZ6Ni9NdGZwVURtMGxWT1B3aEgwbGJJQVdLVzdqeXcwdjEwS3lPdTB3UzM1T2xHZnFQMjhLOUhaSERTUmVtcmhpWk01bmwybkwiLCJtYWMiOiJjMGFiYzk4MTE5OGNlODc2ZDBmNDQ2NTkxNmM1ZWM1MzdjMDYxYTI3NmVlZGVkMzNiOTQ0YjMyNWRlZTE5ZTNkIn0%3D; laravel_session=eyJpdiI6ImRlVVJNak55c1ZicXJVWmU2d3pWWVE9PSIsInZhbHVlIjoibEQzR1BrSUZNN2dFd1ZVL1lROE4zZG5MOXRSQ0RXWStpQ3hHRFQ3ZGVkOS9HcDdJLzRXemVxZnFwdVh1aWdlYjdMck1MQWtqQ2RjaERmL0RBN0ZqM0dkN2t6WmdyZU96L2ROSEZmOGlxb1FMamtqZlJoSXZsTjdybWRTeGc4MW8iLCJtYWMiOiI0NDdlODExZDlhZTUxNWUyYmE0YjdmZjJkODFjM2I5MDlhOTRjZTgxNzlmZWY5MjVmNjZkOTljOWRlZDVlMzU3In0%3D");

    var formdata = new FormData();
    formdata.append("token", `${localStorage.getItem('token')}`);

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
    };

    fetch(`https://api.eagleiq.online/api/manager/all-stock-cats`, requestOptions)
        .then(res => res.json())
        .then(data => dispatch({
            type: GET_ALL_STOCK_CATEGORIES,
            payload: data
        }))
        .catch((error) => {
            console.error(error)
        })

}
// Videos
export const getAllVideos = () => {
    return async (dispatch) => {
        dispatch({ type: CHANGE_GLOBAL_LOADER, payload: true })

        var myHeaders = new Headers();
        myHeaders.append("Cookie", "XSRF-TOKEN=eyJpdiI6Ii85T0Zrb0FjRytLbUNQbjNGcFJXT1E9PSIsInZhbHVlIjoiOWtGb3R4VUJGcEd2V2JySlk1bmlaSC83K3M3b0Z0SU1rYUlpMWJsK29BRDVNOGZoSE1mUCt4UjFPUHV4dGJzajBkVE9sK3lScEhyQ0dSUmxHQUJFSit1ejBIVng0Q3ZqMDQ4Mkh6a2xvVEtTbm95amtwSnF2K2VCUlpCcFZDcDUiLCJtYWMiOiI1MTZjYjYxZmIxNTFkYmFkZWQzZGNjMmUwYWM3YjY5MDc0N2I3ZmYyNTViNTNkOGQ1MjJkOTMwMGY1Yjg0ZDM5In0%3D; laravel_session=eyJpdiI6IjlhRnZkVEJ1U0FyNFpHazhhSFVXYUE9PSIsInZhbHVlIjoiUlpxZ1JidCtZUlVtRm0wQUk5dXdGcWExdEdQL0lKSkdxLytzRVl3WExsdklHVENaTklXMzZ5MWlZYlloWktHU1NqMEV4V3ljd0E3SjhRb1l4N1lzUmxWdmx4N3JNQXEzd254WmtiQVFFYUFjYkg0SlU1UGFWcTJ2ZWIrT08vL3IiLCJtYWMiOiI5OWU0MDBjMGNiYTcwNGFlYWUyNWQ1OWExMjdlZjU1MTY0MjdmODNhOTk2NzdkY2RhMzM2NTgxYTlmYTQ0ZWQ3In0%3D");

        var formdata = new FormData();
        formdata.append("token", `${localStorage.getItem('token')}`);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        let data = await fetch(`https://api.eagleiq.online/api/manager/all-videos`, requestOptions)
            .then(res => res.json())
            .catch((error) => {
                console.error(error)
            })

        dispatch({ type: GET_ALL_VIDEOS, payload: data })
        dispatch({ type: CHANGE_GLOBAL_LOADER, payload: false })
    }
}
export const getVideoCategories = () => dispatch => {
    var myHeaders = new Headers();
    myHeaders.append("Cookie", "XSRF-TOKEN=eyJpdiI6Ii85T0Zrb0FjRytLbUNQbjNGcFJXT1E9PSIsInZhbHVlIjoiOWtGb3R4VUJGcEd2V2JySlk1bmlaSC83K3M3b0Z0SU1rYUlpMWJsK29BRDVNOGZoSE1mUCt4UjFPUHV4dGJzajBkVE9sK3lScEhyQ0dSUmxHQUJFSit1ejBIVng0Q3ZqMDQ4Mkh6a2xvVEtTbm95amtwSnF2K2VCUlpCcFZDcDUiLCJtYWMiOiI1MTZjYjYxZmIxNTFkYmFkZWQzZGNjMmUwYWM3YjY5MDc0N2I3ZmYyNTViNTNkOGQ1MjJkOTMwMGY1Yjg0ZDM5In0%3D; laravel_session=eyJpdiI6IjlhRnZkVEJ1U0FyNFpHazhhSFVXYUE9PSIsInZhbHVlIjoiUlpxZ1JidCtZUlVtRm0wQUk5dXdGcWExdEdQL0lKSkdxLytzRVl3WExsdklHVENaTklXMzZ5MWlZYlloWktHU1NqMEV4V3ljd0E3SjhRb1l4N1lzUmxWdmx4N3JNQXEzd254WmtiQVFFYUFjYkg0SlU1UGFWcTJ2ZWIrT08vL3IiLCJtYWMiOiI5OWU0MDBjMGNiYTcwNGFlYWUyNWQ1OWExMjdlZjU1MTY0MjdmODNhOTk2NzdkY2RhMzM2NTgxYTlmYTQ0ZWQ3In0%3D");

    var formdata = new FormData();
    formdata.append("token", `${localStorage.getItem('token')}`);

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
    };

    fetch(`https://api.eagleiq.online/api/manager/all-video-cats`, requestOptions)
        .then(res => res.json())
        .then(data => dispatch({
            type: GET_VIDEO_CATEGORIES,
            payload: data
        }))
        .catch((error) => {
            console.error(error)
        })

}
export const addNewVideo = (formData, history) => dispatch => {

    dispatch(
        {
            type: SET_IS_LOADING
        }
    )



    formData.append("token", `${localStorage.getItem('token')}`);


    fetch(`https://api.eagleiq.online/api/manager/create-video`, {
        method: 'POST',
        body: formData
    })
        .then(res => res.json())
        .then(data => {
            if (data.error) {
                dispatch({
                    type: FETCH_ERROR
                })

                for (var key in data.error) {
                    if (data.error.hasOwnProperty(key)) {
                        console.info(data.error[key])
                    }
                }
            } else {
                dispatch({
                    type: ADD_NEW_VIDEO,
                    payload: data
                })
                dispatch({
                    type: SET_SWEET_ALERT
                })
                history.push('/')
                history.push('/admin/VideoTutorials')
            }

        }).catch((error) => {
            console.error(error)
        })
}
export const addVideoCategory = (formData, history) => dispatch => {


    formData.append("token", `${localStorage.getItem('token')}`);


    fetch(`https://api.eagleiq.online/api/manager/create-video-cat`, {
        method: 'POST',
        body: formData
    })
        .then(res => res.json())
        .then(data => {
            if (data.error) {
                dispatch({
                    type: FETCH_ERROR
                })

                for (var key in data.error) {
                    if (data.error.hasOwnProperty(key)) {
                        console.info(data.error[key])
                    }
                }
            } else {
                dispatch({
                    type: ADD_VIDEO_CATEGORY,
                    payload: data
                })
                dispatch({
                    type: SET_SWEET_ALERT
                })
                history.push('/')
                history.push('/admin/VideoTutorials')
            }

        }).catch((error) => {
            console.error(error)
        })
}
export const updateVideo = (formData, history) => dispatch => {
    dispatch(
        {
            type: SET_IS_LOADING
        }
    )

    var myHeaders = new Headers();
    myHeaders.append("Cookie", "XSRF-TOKEN=eyJpdiI6Imlad3BlMTk2QnBjaE4rMXlMVzk3VEE9PSIsInZhbHVlIjoiYTZoUW40dXdTSnZQWmtsOHdweWJFcGUrQnFCUXpxdE5VRG9DMXVDQ2s2RElMU2ZmWFEzKzMyRUtreExQeGtXSWFvRTBaOGdlcUZEYUpmRTcrenFGdUl4L0ZyVTJqTGZZK0hBaUhUVURydDdtL2VrSDc1MWpUNG1RdGZrMjg4UHoiLCJtYWMiOiJkZWUyMTNiZTQ0NGU0NjFhY2JhOGVhZDY0MTIyZTJmODVlZGFjOGMyNWViNDE0YzljOGY0NTJlZDU2YjI4ZDZhIn0%3D; laravel_session=eyJpdiI6InJ3SXhEQ1NkSnBkSFhuZ0lUdHZKK0E9PSIsInZhbHVlIjoicGV0SFFBL0VDRU9yN1NabFc5dFhsaUxUVFcwb051alhMWFRPSW1veSt3L2NzWmpxdExtMGZCUHlZdTd3ZkNLREFPdlV2ckwwd0QrcFJ2VEFWT2VlVHpyNlFlR3YrdmpzeG00akxOWURoTWNzSXhvcnh4YndQV3gzU0NXZ09VUVMiLCJtYWMiOiIwMGZhNWM2M2Q1ZTg3Y2FjN2YzNzI5MjUxNTY3ZDBhNmEzMWIyZmQzZDc3YzAzN2IyN2RiZTg1OTQ3NTJiOTZiIn0%3D");

    formData.append("token", `${localStorage.getItem('token')}`);


    fetch(`https://api.eagleiq.online/api/manager/update-video`, {
        method: 'POST',
        body: formData
    })
        .then(res => res.json())
        .then(data => {



            if (data.error) {
                dispatch({
                    type: FETCH_ERROR
                })
                for (var key in data.error) {
                    if (data.error.hasOwnProperty(key)) {
                        console.info(data.error[key])
                    }
                }
            } else {


                dispatch({
                    type: UPDATE_VIDEO,
                    payload: data
                })
                dispatch({
                    type: SET_SWEET_ALERT
                })
                history.push('/')
                history.push('/admin/VideoTutorials')
            }

        }).catch((error) => {
            console.error(error)
        })
}
export const deleteVideo = (id, history) => dispatch => {
    dispatch(
        {
            type: SET_IS_LOADING
        }
    )

    var formData = new FormData();


    formData.append("token", `${localStorage.getItem('token')}`);
    formData.append("video_id", id);

    fetch(`https://api.eagleiq.online/api/manager/delete-video`, {
        method: 'POST',
        body: formData
    })
        .then(res => res.json())
        .then(data => {
            if (data.error) {
                dispatch({
                    type: FETCH_ERROR
                })
                for (var key in data.error) {
                    if (data.error.hasOwnProperty(key)) {
                        console.info(data.error[key])
                    }
                }


            } else {
                dispatch({
                    type: DELETE_VIDEO,
                    payload: data
                })
                dispatch({
                    type: SET_SWEET_ALERT
                })
                history.push('/')
                history.push('/admin/VideoTutorials')
            }

        }).catch((error) => {
            console.error(error)
        })
}
// JOBS

export const getAllJobs = () => {
    return async (dispatch) => {
        console.log("GETALLJOBS")
        dispatch({ type: CHANGE_GLOBAL_LOADER, payload: true })

        var myHeaders = new Headers();
        myHeaders.append("Cookie", "XSRF-TOKEN=eyJpdiI6Ii85T0Zrb0FjRytLbUNQbjNGcFJXT1E9PSIsInZhbHVlIjoiOWtGb3R4VUJGcEd2V2JySlk1bmlaSC83K3M3b0Z0SU1rYUlpMWJsK29BRDVNOGZoSE1mUCt4UjFPUHV4dGJzajBkVE9sK3lScEhyQ0dSUmxHQUJFSit1ejBIVng0Q3ZqMDQ4Mkh6a2xvVEtTbm95amtwSnF2K2VCUlpCcFZDcDUiLCJtYWMiOiI1MTZjYjYxZmIxNTFkYmFkZWQzZGNjMmUwYWM3YjY5MDc0N2I3ZmYyNTViNTNkOGQ1MjJkOTMwMGY1Yjg0ZDM5In0%3D; laravel_session=eyJpdiI6IjlhRnZkVEJ1U0FyNFpHazhhSFVXYUE9PSIsInZhbHVlIjoiUlpxZ1JidCtZUlVtRm0wQUk5dXdGcWExdEdQL0lKSkdxLytzRVl3WExsdklHVENaTklXMzZ5MWlZYlloWktHU1NqMEV4V3ljd0E3SjhRb1l4N1lzUmxWdmx4N3JNQXEzd254WmtiQVFFYUFjYkg0SlU1UGFWcTJ2ZWIrT08vL3IiLCJtYWMiOiI5OWU0MDBjMGNiYTcwNGFlYWUyNWQ1OWExMjdlZjU1MTY0MjdmODNhOTk2NzdkY2RhMzM2NTgxYTlmYTQ0ZWQ3In0%3D");
        var formdata = new FormData();
        formdata.append("token", `${localStorage.getItem('token')}`);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };
        let data = await fetch(`https://api.eagleiq.online/api/manager/all-jobs`, requestOptions)
            .then(res => {
                console.log("GETALLJOBS RES")
                console.log(res)
                return res.json()
            })
            .catch((error) => {
                console.error("ERROR")
                console.error(error)
            })

        dispatch({ type: GET_ALL_JOBS, payload: data })

        dispatch({ type: CHANGE_GLOBAL_LOADER, payload: false })
    }
}
export const addNewJob = (raw, history) => dispatch => {
    dispatch({
        type: IS_CREATING_JOB
    })
    const formData = new FormData()
    formData.append("token", `${localStorage.getItem('token')}`);
    var myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Cookie", "XSRF-TOKEN=eyJpdiI6Inl6cU1hZ0hNQ0pOZXppSDd5T1M0WVE9PSIsInZhbHVlIjoiQVkyeDc4U2ZYMHk0dW9zVnhSOEJxMUJtMjdseFYraTVGc2FxTStUQUlDU2hWaGtRdFdlZFlFdjRqT2Mvb1huQ1RieG81OTJhKzk3eXBzbnhBMUdUcDVQL1FWZEkvbG15VkpjN2oyRHBPT1psTENjcnZMTjRhSG1KRXA2VTN5Q2YiLCJtYWMiOiI3ZmRlYWZlODg0MzJjODk3ZDJlNTA4MzM2MWQzYmJjYjQ2YjM0YWUxMzk4MjE4NmJhYThiMjNlMTY3MzM3YWY1In0%3D; laravel_session=eyJpdiI6IlUzN0N6ZVZVamptdjFKY0hod0E3SVE9PSIsInZhbHVlIjoiMUFQdDYyK0g1Y21tc2E3QU1STVhRdnh1Nkg0UVVTUnBZa0dsTkRwKytKYTNSaytZd0dyWlVnWnZFb2NOZXM0bXRhampYbk5LR05wSGh4djhTamFra2ZrYktkRzJJYyt3SmdMcFZiYllJT2daRVk5N0hhUVBERElad0lWd3hlV0ciLCJtYWMiOiIzYTZhNTM5Y2Q0MTlmNTY4N2ZkY2JlOTFhZDFmZTQ2ODJlM2YwNDc3Mzc5N2NkNGFhODJjMjUxMDcxYzcwM2VhIn0%3D");
    formData.append("title", raw.title)
    formData.append("code", raw.code)
    formData.append("company_id", raw.company_id)
    formData.append("manager_id", raw.manager_id)
    formData.append("site_id", raw.site_id)
    formData.append("client_id", raw.client_id)
    // formData.append("attachment", raw.attachment)
    raw.file.forEach((data, index) => {
        formData.append(`file[]`, data);
    });
    formData.append("start_date", raw.start_date)
    formData.append("summary", raw.summary)

    raw.areas.forEach((data, index) => {
        formData.append(`areas[${index}][area_id]`, data.area_id)
        data?.tasks.forEach((data1, index1) => {
            formData.append(`areas[${index}][tasks][${index1}][task]`, data.tasks[index1].task)
            formData.append(`areas[${index}][tasks][${index1}][day_of]`, data.tasks[index1].day_of)
            formData.append(`areas[${index}][tasks][${index1}][frequency]`, data.tasks[index1].frequency)
        })
    });

    raw.employees.forEach((data, index) => {
        formData.append(`employees[][id]`, data.id);
    });


    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formData,
        redirect: 'follow'
    };
    fetch("https://api.eagleiq.online/api/manager/create-job", requestOptions)
        .then(res => {
            let response = res
            return response.json()
        })
        .then(data => {
            if (data.error) {
                dispatch({
                    type: FETCH_ERROR
                })
                for (var key in data.error) {
                    if (data.error.hasOwnProperty(key)) {
                        console.info(data.error[key])
                    }
                }
            } else {
                dispatch({
                    type: ADD_NEW_JOB,
                    payload: data
                })
                dispatch({
                    type: SET_SWEET_ALERT
                })
                history.push('/')
                history.push('/admin/manage-jobs')
            }

        })
        .catch(error => console.log('error', error));
}
export const deleteJob = (id, history) => dispatch => {

    dispatch({
        type: SET_IS_LOADING
    })

    var formData = new FormData();


    formData.append("token", `${localStorage.getItem('token')}`);
    formData.append("job_id", id);

    fetch(`https://api.eagleiq.online/api/manager/delete-job`, {
        method: 'POST',
        body: formData
    })
        .then(res => res.json())
        .then(data => {
            if (data.error) {
                dispatch({
                    type: FETCH_ERROR
                })
                for (var key in data.error) {
                    if (data.error.hasOwnProperty(key)) {
                        console.info(data.error[key])
                    }
                }


            } else {
                dispatch({
                    type: DELETE_JOB,
                    payload: data
                })
                dispatch({
                    type: SET_SWEET_ALERT
                })
                history.push('/')
                history.push('/admin/manage-jobs')
            }

        }).catch((error) => {
            console.error(error)
        })
}
export const uploadAnyFile = (formdata, history) => dispatch => {

    formdata.append("token", `${localStorage.getItem('token')}`);
    dispatch({
        type: SET_IS_LOADING
    })


    fetch(`https://api.eagleiq.online/api/manager/upload-file`, {
        method: 'POST',
        body: formdata
    })
        .then(res => res.json())
        .then(data => {
            if (data.error) {
                dispatch({
                    type: FETCH_ERROR
                })

                for (var key in data.error) {
                    if (data.error.hasOwnProperty(key)) {
                        console.info(data.error[key])
                    }
                }


            } else {
                dispatch({
                    type: UPLOAD_FILE,
                    payload: data
                })
                // dispatch({
                //     type: SET_SWEET_ALERT
                // })
                // history.push('/')
                // history.push('/admin/manage-jobs')
            }

        }).catch((error) => {
            console.error(error)
        })
}
// Leave Requests
export const getAllLeaveRequests = () => {
    return async (dispatch) => {
        var myHeaders = new Headers();
        myHeaders.append("Cookie", "XSRF-TOKEN=eyJpdiI6Ii85T0Zrb0FjRytLbUNQbjNGcFJXT1E9PSIsInZhbHVlIjoiOWtGb3R4VUJGcEd2V2JySlk1bmlaSC83K3M3b0Z0SU1rYUlpMWJsK29BRDVNOGZoSE1mUCt4UjFPUHV4dGJzajBkVE9sK3lScEhyQ0dSUmxHQUJFSit1ejBIVng0Q3ZqMDQ4Mkh6a2xvVEtTbm95amtwSnF2K2VCUlpCcFZDcDUiLCJtYWMiOiI1MTZjYjYxZmIxNTFkYmFkZWQzZGNjMmUwYWM3YjY5MDc0N2I3ZmYyNTViNTNkOGQ1MjJkOTMwMGY1Yjg0ZDM5In0%3D; laravel_session=eyJpdiI6IjlhRnZkVEJ1U0FyNFpHazhhSFVXYUE9PSIsInZhbHVlIjoiUlpxZ1JidCtZUlVtRm0wQUk5dXdGcWExdEdQL0lKSkdxLytzRVl3WExsdklHVENaTklXMzZ5MWlZYlloWktHU1NqMEV4V3ljd0E3SjhRb1l4N1lzUmxWdmx4N3JNQXEzd254WmtiQVFFYUFjYkg0SlU1UGFWcTJ2ZWIrT08vL3IiLCJtYWMiOiI5OWU0MDBjMGNiYTcwNGFlYWUyNWQ1OWExMjdlZjU1MTY0MjdmODNhOTk2NzdkY2RhMzM2NTgxYTlmYTQ0ZWQ3In0%3D");

        var formdata = new FormData();
        formdata.append("token", `${localStorage.getItem('token')}`);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        let data = await fetch(`https://api.eagleiq.online/api/manager/leave-requests`, requestOptions)
            .then(res => res.json())
            .catch((error) => {
                console.error(error)
            })

        dispatch({ type: GET_ALL_LEAVE_REQUESTS, payload: data })

        dispatch({ type: CHANGE_GLOBAL_LOADER, payload: false })
    }
}
export const changeLeaveStatus = (id, status, history) => dispatch => {


    dispatch(
        {
            type: SET_IS_LOADING
        }
    )

    var formData = new FormData();

    formData.append("leave_id", id);
    formData.append("status", status);
    formData.append("token", `${localStorage.getItem('token')}`);


    fetch(`https://api.eagleiq.online/api/manager/update-leave-status`, {
        method: 'POST',
        body: formData
    })
        .then(res => res.json())
        .then(data => {
            if (data.error) {
                dispatch({
                    type: FETCH_ERROR
                })

                for (var key in data.error) {
                    if (data.error.hasOwnProperty(key)) {
                        console.info(data.error[key])
                    }
                }


            } else {
                dispatch({
                    type: CHANGE_LEAVE_STATUS,
                    payload: data
                })
                dispatch({
                    type: SET_SWEET_ALERT
                })
                history.push('/')
                history.push('/admin/leave-requests')
            }

        }).catch((error) => {
            console.error(error)
        })
}
export const addNewLeave = (formData, history) => dispatch => {
    dispatch({
        type: SET_IS_LOADING
    })


    formData.append("token", `${localStorage.getItem('token')}`);


    fetch(`https://api.eagleiq.online/api/manager/apply-for-leave`, {
        method: 'POST',
        body: formData
    })
        .then(res => res.json())
        .then(data => {
            if (data.error) {
                dispatch({
                    type: FETCH_ERROR
                })

                for (var key in data.error) {
                    if (data.error.hasOwnProperty(key)) {
                        console.info(data.error[key])
                    }
                }


            } else {
                dispatch({
                    type: ADD_LEAVE,
                    payload: data
                })
                dispatch({
                    type: SET_SWEET_ALERT
                })
                history.push('/')
                history.push('/admin/leave-requests')
            }

        }).catch((error) => {
            console.error(error)
        })
}
// Timesheets
export const getTimesheets = () => {
    return async (dispatch) => {
        dispatch({ type: CHANGE_GLOBAL_LOADER, payload: true })

        var myHeaders = new Headers();
        myHeaders.append("Cookie", "XSRF-TOKEN=eyJpdiI6Ii85T0Zrb0FjRytLbUNQbjNGcFJXT1E9PSIsInZhbHVlIjoiOWtGb3R4VUJGcEd2V2JySlk1bmlaSC83K3M3b0Z0SU1rYUlpMWJsK29BRDVNOGZoSE1mUCt4UjFPUHV4dGJzajBkVE9sK3lScEhyQ0dSUmxHQUJFSit1ejBIVng0Q3ZqMDQ4Mkh6a2xvVEtTbm95amtwSnF2K2VCUlpCcFZDcDUiLCJtYWMiOiI1MTZjYjYxZmIxNTFkYmFkZWQzZGNjMmUwYWM3YjY5MDc0N2I3ZmYyNTViNTNkOGQ1MjJkOTMwMGY1Yjg0ZDM5In0%3D; laravel_session=eyJpdiI6IjlhRnZkVEJ1U0FyNFpHazhhSFVXYUE9PSIsInZhbHVlIjoiUlpxZ1JidCtZUlVtRm0wQUk5dXdGcWExdEdQL0lKSkdxLytzRVl3WExsdklHVENaTklXMzZ5MWlZYlloWktHU1NqMEV4V3ljd0E3SjhRb1l4N1lzUmxWdmx4N3JNQXEzd254WmtiQVFFYUFjYkg0SlU1UGFWcTJ2ZWIrT08vL3IiLCJtYWMiOiI5OWU0MDBjMGNiYTcwNGFlYWUyNWQ1OWExMjdlZjU1MTY0MjdmODNhOTk2NzdkY2RhMzM2NTgxYTlmYTQ0ZWQ3In0%3D");

        var formdata = new FormData();
        formdata.append("token", `${localStorage.getItem('token')}`);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        let data = await fetch(`https://api.eagleiq.online/api/manager/time-sheet`, requestOptions)
            .then(res => res.json())
            .catch((error) => {
                console.error(error)
            })

        dispatch({ type: GET_ALL_TIMESHEETS, payload: data })

        dispatch({ type: CHANGE_GLOBAL_LOADER, payload: false })
    }
}

export const getUserSitesById = (userId) => {
    return async (dispatch) => {
        dispatch({ type: CHANGE_GLOBAL_LOADER, payload: true })

        var myHeaders = new Headers();
        myHeaders.append("Cookie", "XSRF-TOKEN=eyJpdiI6Ii85T0Zrb0FjRytLbUNQbjNGcFJXT1E9PSIsInZhbHVlIjoiOWtGb3R4VUJGcEd2V2JySlk1bmlaSC83K3M3b0Z0SU1rYUlpMWJsK29BRDVNOGZoSE1mUCt4UjFPUHV4dGJzajBkVE9sK3lScEhyQ0dSUmxHQUJFSit1ejBIVng0Q3ZqMDQ4Mkh6a2xvVEtTbm95amtwSnF2K2VCUlpCcFZDcDUiLCJtYWMiOiI1MTZjYjYxZmIxNTFkYmFkZWQzZGNjMmUwYWM3YjY5MDc0N2I3ZmYyNTViNTNkOGQ1MjJkOTMwMGY1Yjg0ZDM5In0%3D; laravel_session=eyJpdiI6IjlhRnZkVEJ1U0FyNFpHazhhSFVXYUE9PSIsInZhbHVlIjoiUlpxZ1JidCtZUlVtRm0wQUk5dXdGcWExdEdQL0lKSkdxLytzRVl3WExsdklHVENaTklXMzZ5MWlZYlloWktHU1NqMEV4V3ljd0E3SjhRb1l4N1lzUmxWdmx4N3JNQXEzd254WmtiQVFFYUFjYkg0SlU1UGFWcTJ2ZWIrT08vL3IiLCJtYWMiOiI5OWU0MDBjMGNiYTcwNGFlYWUyNWQ1OWExMjdlZjU1MTY0MjdmODNhOTk2NzdkY2RhMzM2NTgxYTlmYTQ0ZWQ3In0%3D");

        var formdata = new FormData();
        formdata.append("token", `${localStorage.getItem('token')}`);
        formdata.append("manager_id", userId);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        let data = await fetch(`https://api.eagleiq.online/api/manager/get-sites`, requestOptions)
            .then(res => res.json())
            .catch((error) => {
                console.error(error)
            })
        dispatch({ type: GET_SITES_BY_USER_ID, payload: data })

        dispatch({ type: CHANGE_GLOBAL_LOADER, payload: false })
    }
}
export const addNewTimeSheet = (formData, history) => dispatch => {
    dispatch({
        type: SET_IS_LOADING
    })


    formData.append("token", `${localStorage.getItem('token')}`);


    fetch(`https://api.eagleiq.online/api/manager/create-timesheet`, {
        method: 'POST',
        body: formData
    })
        .then(res => res.json())
        .then(data => {
            if (data.error) {
                dispatch({
                    type: FETCH_ERROR
                })

                for (var key in data.error) {
                    if (data.error.hasOwnProperty(key)) {
                        console.info(data.error[key])
                    }
                }


            } else {
                dispatch({
                    type: ADD_TIMESHEET,
                    payload: data
                })
                dispatch({
                    type: SET_SWEET_ALERT
                })
                history.push('/')
                history.push('/admin/time-sheets')
            }

        }).catch((error) => {
            console.error(error)
        })
}
export const updateTimesheet = (formData, history) => dispatch => {
    dispatch(
        {
            type: SET_IS_LOADING
        }
    )

    var myHeaders = new Headers();
    myHeaders.append("Cookie", "XSRF-TOKEN=eyJpdiI6Imlad3BlMTk2QnBjaE4rMXlMVzk3VEE9PSIsInZhbHVlIjoiYTZoUW40dXdTSnZQWmtsOHdweWJFcGUrQnFCUXpxdE5VRG9DMXVDQ2s2RElMU2ZmWFEzKzMyRUtreExQeGtXSWFvRTBaOGdlcUZEYUpmRTcrenFGdUl4L0ZyVTJqTGZZK0hBaUhUVURydDdtL2VrSDc1MWpUNG1RdGZrMjg4UHoiLCJtYWMiOiJkZWUyMTNiZTQ0NGU0NjFhY2JhOGVhZDY0MTIyZTJmODVlZGFjOGMyNWViNDE0YzljOGY0NTJlZDU2YjI4ZDZhIn0%3D; laravel_session=eyJpdiI6InJ3SXhEQ1NkSnBkSFhuZ0lUdHZKK0E9PSIsInZhbHVlIjoicGV0SFFBL0VDRU9yN1NabFc5dFhsaUxUVFcwb051alhMWFRPSW1veSt3L2NzWmpxdExtMGZCUHlZdTd3ZkNLREFPdlV2ckwwd0QrcFJ2VEFWT2VlVHpyNlFlR3YrdmpzeG00akxOWURoTWNzSXhvcnh4YndQV3gzU0NXZ09VUVMiLCJtYWMiOiIwMGZhNWM2M2Q1ZTg3Y2FjN2YzNzI5MjUxNTY3ZDBhNmEzMWIyZmQzZDc3YzAzN2IyN2RiZTg1OTQ3NTJiOTZiIn0%3D");

    formData.append("token", `${localStorage.getItem('token')}`);


    fetch(`https://api.eagleiq.online/api/manager/update-timesheet`, {
        method: 'POST',
        body: formData
    })
        .then(res => res.json())
        .then(data => {



            if (data.error) {
                dispatch({
                    type: FETCH_ERROR
                })
                for (var key in data.error) {
                    if (data.error.hasOwnProperty(key)) {
                        console.info(data.error[key])
                    }
                }
            } else {


                dispatch({
                    type: UPDATE_TIMESHEET,
                    payload: data
                })
                dispatch({
                    type: SET_SWEET_ALERT
                })
                history.push('/')
                history.push('/admin/time-sheets')
            }

        }).catch((error) => {
            console.error(error)
        })
}
export const updateTimsheetStatus = (id, status, history) => dispatch => {


    dispatch(
        {
            type: SET_IS_LOADING
        }
    )

    var formData = new FormData();

    formData.append("timesheet_id", id);
    formData.append("status", status);
    formData.append("token", `${localStorage.getItem('token')}`);


    fetch(`https://api.eagleiq.online/api/manager/update-timesheet-status`, {
        method: 'POST',
        body: formData
    })
        .then(res => res.json())
        .then(data => {
            if (data.error) {
                dispatch({
                    type: FETCH_ERROR
                })

                for (var key in data.error) {
                    if (data.error.hasOwnProperty(key)) {
                        console.info(data.error[key])
                    }
                }


            } else {
                dispatch({
                    type: UPDATE_TIMESHEET_STATUS,
                    payload: data
                })
                dispatch({
                    type: SET_SWEET_ALERT
                })
                history.push('/')
                history.push('/admin/time-sheets')
            }

        }).catch((error) => {
            console.error(error)
        })
}
export const deleteTimesheet = (id, history) => dispatch => {
    dispatch(
        {
            type: SET_IS_LOADING
        }
    )

    var formData = new FormData();


    formData.append("token", `${localStorage.getItem('token')}`);
    formData.append("timesheet_id", id);

    fetch(`https://api.eagleiq.online/api/manager/delete-timesheet`, {
        method: 'POST',
        body: formData
    })
        .then(res => res.json())
        .then(data => {
            if (data.error) {
                dispatch({
                    type: FETCH_ERROR
                })
                for (var key in data.error) {
                    if (data.error.hasOwnProperty(key)) {
                        console.info(data.error[key])
                    }
                }


            } else {
                dispatch({
                    type: DELETE_TIMESHEET,
                    payload: data
                })
                dispatch({
                    type: SET_SWEET_ALERT
                })
                history.push('/')
                history.push('/admin/time-sheets')
            }

        }).catch((error) => {
            console.error(error)
        })
}
// Admin Profile
export const updateAdminProfile = (formdata, history) => dispatch => {
    dispatch(
        {
            type: SET_IS_LOADING
        }
    )
    var myHeaders = new Headers();
    myHeaders.append("Cookie", "XSRF-TOKEN=eyJpdiI6IlFlMHhWMEZZN0hONGdrbnphdzJoQ3c9PSIsInZhbHVlIjoiVHZXcnp1Z3ZrUjBSZWhvd0NIOXhxUG5GN01hOEVFcXNXTEJXYUsyVXhoTEN6cXF5N1RmeVpCK3lQdkIvejVLSld3cWNvSnptT1RvR21rWFJPUDI0ZEZYREMrM3pYdkNZdzBvYXBDSkZQUUpKU04yMG1nQlZtcG1pWGJJWjZaWnoiLCJtYWMiOiJiMzE3OTQ2NzExODAwZjNkZmUyYjg2ZWYzOTRiMjRiZTcwNGE0OGEyMjJiNWJkMjgzZTM5NDBiZjkwZTY1YjgwIn0%3D; laravel_session=eyJpdiI6Im9WTTJnWUZjNHBTMW95YUsyT3hIQnc9PSIsInZhbHVlIjoiL1U0c0NVK05aZUwzQktZdkpPcTFXNStKZTFWSU5qRWVnL1d2SnYyRFJvamc0ZXROYklmbURYSFY3K0prekQ0MEd3YlQ2ekc2UFhZVCs4VDBreDlxTTFWM2VkRlBmaTlkREtjQTJtSEZGUEQzQXR0OEVmNDRUTmdHRXFEYUlvVEMiLCJtYWMiOiI2MjJkZjc5ZWMzOTgyMzQxMzM2YmU4YTc0Zjg0ZTI5YzFiODhiMDFmYmExYmNkNTE5ODk0MTU3MjA3YWQ5MDllIn0%3D");

    formdata.append("token", `${localStorage.getItem('token')}`);

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
    };

    fetch(`https://api.eagleiq.online/api/manager/update-profile`, requestOptions)
        .then(res => res.json())
        .then(data => {
            if (data.error) {
                dispatch({
                    type: FETCH_ERROR
                })
                for (var key in data.error) {
                    if (data.error.hasOwnProperty(key)) {
                        console.info(data.error[key])
                    }
                }


            } else {
                dispatch({
                    type: UPDATE_ADMIN_PROFILE,
                    payload: data
                })
                dispatch({
                    type: SET_SWEET_ALERT
                })
                history.push('/')
                history.push('/admin/edit-profile')
            }

        }).catch((error) => {
            console.error(error)
        })

}

export const updateProfilePicture = (formdata, history) => dispatch => {
    var myHeaders = new Headers();
    myHeaders.append("Cookie", "XSRF-TOKEN=eyJpdiI6IlFlMHhWMEZZN0hONGdrbnphdzJoQ3c9PSIsInZhbHVlIjoiVHZXcnp1Z3ZrUjBSZWhvd0NIOXhxUG5GN01hOEVFcXNXTEJXYUsyVXhoTEN6cXF5N1RmeVpCK3lQdkIvejVLSld3cWNvSnptT1RvR21rWFJPUDI0ZEZYREMrM3pYdkNZdzBvYXBDSkZQUUpKU04yMG1nQlZtcG1pWGJJWjZaWnoiLCJtYWMiOiJiMzE3OTQ2NzExODAwZjNkZmUyYjg2ZWYzOTRiMjRiZTcwNGE0OGEyMjJiNWJkMjgzZTM5NDBiZjkwZTY1YjgwIn0%3D; laravel_session=eyJpdiI6Im9WTTJnWUZjNHBTMW95YUsyT3hIQnc9PSIsInZhbHVlIjoiL1U0c0NVK05aZUwzQktZdkpPcTFXNStKZTFWSU5qRWVnL1d2SnYyRFJvamc0ZXROYklmbURYSFY3K0prekQ0MEd3YlQ2ekc2UFhZVCs4VDBreDlxTTFWM2VkRlBmaTlkREtjQTJtSEZGUEQzQXR0OEVmNDRUTmdHRXFEYUlvVEMiLCJtYWMiOiI2MjJkZjc5ZWMzOTgyMzQxMzM2YmU4YTc0Zjg0ZTI5YzFiODhiMDFmYmExYmNkNTE5ODk0MTU3MjA3YWQ5MDllIn0%3D");

    formdata.append("token", `${localStorage.getItem('token')}`);

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
    };

    fetch(`https://api.eagleiq.online/api/manager/update-pic`, requestOptions)
        .then(res => res.json())
        .then(data => {
            if (data.error) {
                dispatch({
                    type: FETCH_ERROR
                })
                for (var key in data.error) {
                    if (data.error.hasOwnProperty(key)) {
                        console.info(data.error[key])
                    }
                }

            } else {
                history.push('/')
                history.push('/admin/edit-profile')
            }
        }
        )
}
// USER PROFILE
export const getUserProfile = (formdata) => dispatch => {



    var myHeaders = new Headers();
    myHeaders.append("Cookie", "XSRF-TOKEN=eyJpdiI6IndVSTRZR3VQRlkybGtQcnVKcUpuUVE9PSIsInZhbHVlIjoiVUg3ZnVubEQyNEFRWlpDYWdYNTIreWNIcmNkbDltWVpDaTNRWGNodXNmMVNCdm9zam5XWDlzVlo4R0JhSzZ6emdPRW1MYmlITmgwVDlZUFZVWXhxSHk4aXRjZFgxak1qbVIxVThyVlQwK1NKRzJzcHhRZ3QrZnlIUktOT1lJRFgiLCJtYWMiOiJhMmY1ZDA2OThlYWQ3YTRlMmE3YzAzZmZlZWQ1ZTU2ZTA3NjM4NjYyNTE0ODNjOTU2NmYwNGFmNzc1NWZmOWZiIn0%3D; laravel_session=eyJpdiI6IlhUZ3pXaXhUbGVIZjZUZVlMM0Y3dGc9PSIsInZhbHVlIjoiWWg5SE9XNkJ3OG1VcUs2RThwdU0wY3F5RkdTRmhsTzNzRURBVFlCVFBCWXQyUmRjNkNLUnVFQk03cjJXWGsrWXdrb3pPcHcxZjdLVDB1Q2hSb243VVhnMi9wRCtXeXhITjJPMkYzVE9QVXRoNi9aTE9YZTF3Mlh4ZFJhTEZxR0giLCJtYWMiOiJkMjZiMzY5YzZlMzMwYTdiYjgyNDRhYTRkMGQ5NzdjNzIyMjRiNWFjNThhMWQ3MzM0MDJhOTIxZWU1ZThmYzZhIn0%3D");


    formdata.append("token", `${localStorage.getItem('token')}`);

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
    };

    fetch(`https://api.eagleiq.online/api/manager/user-profile`, requestOptions)
        .then(res => res.json())
        .then(data => dispatch({
            type: GET_USER_PROFILE,
            payload: data
        })).catch((error) => {
            console.error(error)
        })

}

export const emptyUserProfile = (formdata) => dispatch => {
    dispatch({
        type: 'EMPTY_USER_PROFILE',
    })
}

export const removeSweetAlert = () => dispatch => {
    dispatch({
        type: REMOVE_SWEET_ALERT
    })

}

// Today Jobs
export const getTodayJobs = () => dispatch => {

    var myHeaders = new Headers();
    myHeaders.append("Cookie", "XSRF-TOKEN=eyJpdiI6Imh4bG1BdzZhc2NrZ1Z3NlhCNEtGSFE9PSIsInZhbHVlIjoicHdhNmxscUdJM1phUzVRTktqNU4wRnJGT0dISUMwOWJuYnhjUVI5ejZlQXozWUxCY050eXRyMEJDZk51WkszUklOcmplRGhGNkZqd0UrRzlBZkJzK2FBcDdERGtxV2pUKzJnallyR1AvL21IQ0h4Y014RzZpUmNSbEVjT25iZjMiLCJtYWMiOiI3MWM2NjcyZTA1NTIyMjllOTdmOWIwZGE3YjM5YTY2NDBhYWU1MTQ5N2M4YjQ2MTJjZjMxNzYxNDNkZDdlZTZiIn0%3D; laravel_session=eyJpdiI6IjNZbHJnTE1Ld0cvaisrQU4ya2Y3VkE9PSIsInZhbHVlIjoiZ0RlMVJLMjZGZ1h0VmNocWVHeVZYQWlHaUxDNkZxS0M4T0lLNS9UL1dKVHpsTFBqTXhEV1pYY1RHaTU2NUNtSmNLOEY4K0ZaWXloSjh1WUJIejBOeSthT0RRbko2aW9rVW4ydER2aE5GOVpDWERzU3BFNTR2ZjBMZ1plVkxLcngiLCJtYWMiOiIzMTkwZDAxM2JjOTdhMGI3MmYwN2NhMmVjMmRjMzJhYjQxNTNiNjU2Y2FlN2E3ZmY3ZGUzMGQxNGI0ZjZlYTNmIn0%3D");

    var formdata = new FormData();
    formdata.append("token", `${localStorage.getItem('token')}`);

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
    };


    fetch(`https://api.eagleiq.online/api/manager/today_jobs`, requestOptions)
        .then(res => res.json())
        .then(data => dispatch({
            type: GET_TODAY_JOBS,
            payload: data
        }))

}

// Today Tasks
export const getTodayTasks = () => dispatch => {

    var myHeaders = new Headers();
    myHeaders.append("Cookie", "XSRF-TOKEN=eyJpdiI6Imh4bG1BdzZhc2NrZ1Z3NlhCNEtGSFE9PSIsInZhbHVlIjoicHdhNmxscUdJM1phUzVRTktqNU4wRnJGT0dISUMwOWJuYnhjUVI5ejZlQXozWUxCY050eXRyMEJDZk51WkszUklOcmplRGhGNkZqd0UrRzlBZkJzK2FBcDdERGtxV2pUKzJnallyR1AvL21IQ0h4Y014RzZpUmNSbEVjT25iZjMiLCJtYWMiOiI3MWM2NjcyZTA1NTIyMjllOTdmOWIwZGE3YjM5YTY2NDBhYWU1MTQ5N2M4YjQ2MTJjZjMxNzYxNDNkZDdlZTZiIn0%3D; laravel_session=eyJpdiI6IjNZbHJnTE1Ld0cvaisrQU4ya2Y3VkE9PSIsInZhbHVlIjoiZ0RlMVJLMjZGZ1h0VmNocWVHeVZYQWlHaUxDNkZxS0M4T0lLNS9UL1dKVHpsTFBqTXhEV1pYY1RHaTU2NUNtSmNLOEY4K0ZaWXloSjh1WUJIejBOeSthT0RRbko2aW9rVW4ydER2aE5GOVpDWERzU3BFNTR2ZjBMZ1plVkxLcngiLCJtYWMiOiIzMTkwZDAxM2JjOTdhMGI3MmYwN2NhMmVjMmRjMzJhYjQxNTNiNjU2Y2FlN2E3ZmY3ZGUzMGQxNGI0ZjZlYTNmIn0%3D");

    var formdata = new FormData();
    formdata.append("token", `${localStorage.getItem('token')}`);

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
    };


    fetch(`https://api.eagleiq.online/api/manager/today_task`, requestOptions)
        .then(res => res.json())
        .then(data => dispatch({
            type: GET_TODAY_TASKS,
            payload: data
        }))

}

export const getAllAreas = () => {
    return async (dispatch) => {

        var myHeaders = new Headers();
        myHeaders.append("Cookie", "XSRF-TOKEN=eyJpdiI6IjYvTlR3dUc3ZGJMaUxmRGJsTnk2R1E9PSIsInZhbHVlIjoiSTl6c29mQTg1bk9TWEtQWldlU3NmNDk3TnNJZVBVY2dOSFQ2TktIZFZrc2NJd2wyQXNxVlpYdk55UXQ4TTFLeVBjbXd5WVF6WUtLQTF3ci9na0F6a0hJN0ltMkdES21qUGx5b0hHbHJ2UlBhMGpMVU1acWVMS1k2RVZqWDVjRUUiLCJtYWMiOiIxNzVjZDZkNmRlNGM2OGJhMzIwNDc3Mzk1OTgxZjIwYzIwY2FmZTA0YmRmMzAyM2FkN2RmYjQ1NjU1MWZlZjM2In0%3D; laravel_session=eyJpdiI6Imd0S1hsWVBDejVJT1d3VVlNS1NrMVE9PSIsInZhbHVlIjoiRXpjNjBPbSsvRHROUkI0Z2R1ZDhsMGcyWG9GS2tZU3YzR3AwS3ltT0xOWm1CeURBNXZpaHREanFpTzdUZVFMTWlHd1ZNdFUxK2k1NTIxcmlIVjVGMXlyOHU0cEs3dG1ka2o5SW55ViszL2Y2blNuV0dxRDBxUUl6WWNFY0lZaUQiLCJtYWMiOiJhMzUyMGI4OWZkZjgwOTI0YjIwMjk1YmFlNzRjZjllODM0ZTU4YzFlZjE3OTdlZTg5YzM4ZTc5YTY5MzBkYmU1In0%3D");
        ;
        myHeaders.append("Accept", "application/json");

        var formdata = new FormData();
        formdata.append("token", `${localStorage.getItem('token')}`);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        let data = await fetch(`https://api.eagleiq.online/api/manager/get-site-areas`, requestOptions)
            .then(res => res.json())

        dispatch({ type: GET_ALL_AREAS, payload: data })
    }
}

export const addNewArea = (data, toggleModal) => dispatch => {

    var myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Cookie", "XSRF-TOKEN=eyJpdiI6IjYvTlR3dUc3ZGJMaUxmRGJsTnk2R1E9PSIsInZhbHVlIjoiSTl6c29mQTg1bk9TWEtQWldlU3NmNDk3TnNJZVBVY2dOSFQ2TktIZFZrc2NJd2wyQXNxVlpYdk55UXQ4TTFLeVBjbXd5WVF6WUtLQTF3ci9na0F6a0hJN0ltMkdES21qUGx5b0hHbHJ2UlBhMGpMVU1acWVMS1k2RVZqWDVjRUUiLCJtYWMiOiIxNzVjZDZkNmRlNGM2OGJhMzIwNDc3Mzk1OTgxZjIwYzIwY2FmZTA0YmRmMzAyM2FkN2RmYjQ1NjU1MWZlZjM2In0%3D; laravel_session=eyJpdiI6Imd0S1hsWVBDejVJT1d3VVlNS1NrMVE9PSIsInZhbHVlIjoiRXpjNjBPbSsvRHROUkI0Z2R1ZDhsMGcyWG9GS2tZU3YzR3AwS3ltT0xOWm1CeURBNXZpaHREanFpTzdUZVFMTWlHd1ZNdFUxK2k1NTIxcmlIVjVGMXlyOHU0cEs3dG1ka2o5SW55ViszL2Y2blNuV0dxRDBxUUl6WWNFY0lZaUQiLCJtYWMiOiJhMzUyMGI4OWZkZjgwOTI0YjIwMjk1YmFlNzRjZjllODM0ZTU4YzFlZjE3OTdlZTg5YzM4ZTc5YTY5MzBkYmU1In0%3D");

    var formdata = new FormData();
    formdata.append("area", data.area)
    formdata.append("token", `${localStorage.getItem('token')}`);

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
    };

    fetch(`https://api.eagleiq.online/api/manager/store-site-areas`, requestOptions)
        .then(res => res.json())
        .then(data => {
            console.log("data ===> ", data)
            if (data.status_code == 200) {
                toggleModal()
                dispatch(getAllAreas())
            } else if (data.error) {
                dispatch({
                    type: FETCH_ERROR
                })
            } else {
                dispatch({
                    type: SET_SWEET_ALERT
                })
            }
        }
        )
}

export const getAllBuildings = () => {
    return async (dispatch) => {

        var myHeaders = new Headers();
        myHeaders.append("Cookie", "XSRF-TOKEN=eyJpdiI6IjYvTlR3dUc3ZGJMaUxmRGJsTnk2R1E9PSIsInZhbHVlIjoiSTl6c29mQTg1bk9TWEtQWldlU3NmNDk3TnNJZVBVY2dOSFQ2TktIZFZrc2NJd2wyQXNxVlpYdk55UXQ4TTFLeVBjbXd5WVF6WUtLQTF3ci9na0F6a0hJN0ltMkdES21qUGx5b0hHbHJ2UlBhMGpMVU1acWVMS1k2RVZqWDVjRUUiLCJtYWMiOiIxNzVjZDZkNmRlNGM2OGJhMzIwNDc3Mzk1OTgxZjIwYzIwY2FmZTA0YmRmMzAyM2FkN2RmYjQ1NjU1MWZlZjM2In0%3D; laravel_session=eyJpdiI6Imd0S1hsWVBDejVJT1d3VVlNS1NrMVE9PSIsInZhbHVlIjoiRXpjNjBPbSsvRHROUkI0Z2R1ZDhsMGcyWG9GS2tZU3YzR3AwS3ltT0xOWm1CeURBNXZpaHREanFpTzdUZVFMTWlHd1ZNdFUxK2k1NTIxcmlIVjVGMXlyOHU0cEs3dG1ka2o5SW55ViszL2Y2blNuV0dxRDBxUUl6WWNFY0lZaUQiLCJtYWMiOiJhMzUyMGI4OWZkZjgwOTI0YjIwMjk1YmFlNzRjZjllODM0ZTU4YzFlZjE3OTdlZTg5YzM4ZTc5YTY5MzBkYmU1In0%3D");
        ;
        myHeaders.append("Accept", "application/json");

        var formdata = new FormData();
        formdata.append("token", `${localStorage.getItem('token')}`);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        let data = await fetch(`https://api.eagleiq.online/api/manager/get-site-buildings`, requestOptions)
            .then(res => res.json())

        dispatch({ type: GET_ALL_BUILDINGS, payload: data })
    }
}

export const addNewBuilding = (data, toggleModal) => dispatch => {

    var myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Cookie", "XSRF-TOKEN=eyJpdiI6IjYvTlR3dUc3ZGJMaUxmRGJsTnk2R1E9PSIsInZhbHVlIjoiSTl6c29mQTg1bk9TWEtQWldlU3NmNDk3TnNJZVBVY2dOSFQ2TktIZFZrc2NJd2wyQXNxVlpYdk55UXQ4TTFLeVBjbXd5WVF6WUtLQTF3ci9na0F6a0hJN0ltMkdES21qUGx5b0hHbHJ2UlBhMGpMVU1acWVMS1k2RVZqWDVjRUUiLCJtYWMiOiIxNzVjZDZkNmRlNGM2OGJhMzIwNDc3Mzk1OTgxZjIwYzIwY2FmZTA0YmRmMzAyM2FkN2RmYjQ1NjU1MWZlZjM2In0%3D; laravel_session=eyJpdiI6Imd0S1hsWVBDejVJT1d3VVlNS1NrMVE9PSIsInZhbHVlIjoiRXpjNjBPbSsvRHROUkI0Z2R1ZDhsMGcyWG9GS2tZU3YzR3AwS3ltT0xOWm1CeURBNXZpaHREanFpTzdUZVFMTWlHd1ZNdFUxK2k1NTIxcmlIVjVGMXlyOHU0cEs3dG1ka2o5SW55ViszL2Y2blNuV0dxRDBxUUl6WWNFY0lZaUQiLCJtYWMiOiJhMzUyMGI4OWZkZjgwOTI0YjIwMjk1YmFlNzRjZjllODM0ZTU4YzFlZjE3OTdlZTg5YzM4ZTc5YTY5MzBkYmU1In0%3D");

    var formdata = new FormData();
    formdata.append("building", data.building)
    formdata.append("token", `${localStorage.getItem('token')}`);

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
    };

    fetch(`https://api.eagleiq.online/api/manager/store-site-buildings`, requestOptions)
        .then(res => res.json())
        .then(data => {
            console.log("data ===> ", data)
            if (data.status_code == 200) {
                toggleModal()
                dispatch(getAllBuildings())
            } else if (data.error) {
                dispatch({
                    type: FETCH_ERROR
                })
            } else {
                dispatch({
                    type: SET_SWEET_ALERT
                })
            }
        }
        )
}