
import React, { useEffect, useState, useRef } from "react";
import Select from "react-select";
import { Link, useHistory } from 'react-router-dom'
import Datetime from "react-datetime";
import Creatable, { makeCreatableSelect } from 'react-select/creatable';
import { Editor } from '@tinymce/tinymce-react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import CreatableSelect from 'react-select/creatable';
import { Multiselect } from 'multiselect-react-dropdown';
import { connect } from 'react-redux';
import {
    getAllClients,
    getAllSites,
    getAllStaff,
    addNewJob,
    uploadAnyFile,
    getAllManagers,
    getSiteProfile
} from "actions/profileActions";
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';


// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    Label,
    FormGroup,
    Form,
    Input,
    FormText,
    Row,
    Col,
    TabContent,
    TabPane,
    Nav,
    NavItem,
    NavLink, Table
} from "reactstrap";

// core components
import PanelHeader from "components/PanelHeader/PanelHeader.js";
import { getUserProfile } from "actions/profileActions";
import randomstring from 'randomstring';

function Spinner() {
    return (
        <div class="spinner-border text-primary" role="status">
            <span class="sr-only">Loading...</span>
        </div>
    );
}



const AddNewJob = ({ getAllClients, allClients, getAllSites, allSites, getAllStaff, allStaff, addNewJob, uploadedFile, uploadAnyFile, allManagers, getAllManagers, isLoading, isCreatingJob, getUserProfile, userProfile, getSiteProfile, siteProfile, user }) => {






    const history = useHistory();
    const [loading, setLoading] = useState(true);

    // Loading Spinner
    const [loadingSpinner, setLoadingSpinner] = useState(false)
    const [creatingJobSpinner, setcreatingJobSpinner] = useState(false)

    const [contractNumber, setContractNumber] = useState(randomstring.generate({
        length: 5,
        charset: 'alphanumeric'
    }))
    useEffect(() => {
        setContractNumber(randomstring.generate({
            length: 5,
            charset: 'alphanumeric'
        }))

    }, [])

    useEffect(() => {

        setLoadingSpinner(isLoading)

    }, [isLoading])

    useEffect(() => {

        setcreatingJobSpinner(isCreatingJob)

    }, [isCreatingJob])

    // Set Page
    const [staff, setStaff] = useState('')
    const [managers, setManagers] = useState('')

    useEffect(() => {
        getAllClients()
        getAllSites()
        getAllStaff()
        getAllManagers()
        setStaff(allStaff.staff)
        setManagers(allManagers.managers)

    }, [])

    // Form Values
    const [token, setToken] = useState(localStorage.getItem('token'))
    const [title, setTitle] = useState('')
    const [company_id, setcompany_id] = useState('1')
    const [site_id, setsite_id] = useState('')
    const [client_id, setclient_id] = useState('')
    const [manager_id, setmanager_id] = useState('')
    const [start_date, setStartDate] = useState('')
    const [summary, setSummary] = useState('')
    const [employees, setEmployees] = useState([])

    const [selectedEmployees, setSelectedEmployees] = useState('')
    const onSelect = (selectedItem, selectedList) => {
        setSelectedEmployees(selectedItem)
    }



    // GET CLIENT NAME FROM CLIENT_ID

    useEffect(() => {
        if (client_id === '' || client_id === null || client_id === undefined) {
            return null
        } else {
            var formdata = new FormData();
            formdata.append("id", client_id);
            getUserProfile(formdata)
            // console.log(client_id)
        }
    }, [client_id])
    // GET SITE NAME FROM SITE_ID

    useEffect(() => {
        if (site_id === '' || site_id === null || site_id === undefined) {
            return null
        } else {
            var formdata = new FormData();
            formdata.append("site_id", site_id);
            getSiteProfile(formdata)
        }
    }, [site_id])



    // ------------------ Tabs ----------------------------------------------------------------

    // Active Tabs
    const [hTabs, sethTabs] = useState(1);
    const [editMode, setEditMode] = useState(false);
    const [toggleView, setToggleView] = useState('0')

    const hideInput = (title) => {
        if (title === '') {
            window.alert('Title Cannot be Empty')
            setEditMode(true)
        } else {
            setEditMode(false)


        }

    }

    // Task Frequency

    const [taskFrequency, setTaskFrequency] = useState('')




    // Set Tasks
    const [tasks, setTasks] = useState([
        {
            task: '',
            day_of: [],
            frequency: '',
        }
    ])

    // Set Areas
    // Changed for Dropdown
    // const [areas, setAreas] = useState(
    //     [
    //         {

    //             id: '1',
    //             title: 'Area 1',
    //             area_attachement: '',
    //             tasks: tasks

    //         },


    //     ]
    // )
    const [areas, setAreas] = useState([])
    const [site_access, setSiteAccess] = useState([])
    // Changed for Dropdown
    const onSelect1 = (selectedItem, selectedList) => {

        setAreas(selectedItem)
        console.log(areas)

    }
    const onSelect2 = (selectedItem, selectedList) => {

        setSiteAccess(selectedItem)

    }
    // Add New Area
    const setNewArea = () => {
        setAreas([...areas, {
            id: `${areas.length++ + 1}`,
            title: `Area ${areas.length++}`,
            tasks: [{
                task: '',
                day_of: [],
                frequency: '',
            }]
        }])

    }




    // Setting up form


    // OnChange
    const onChange = index => e => {
        console.log('index', index)
        console.log('propert', e.target.name)

        let newArr = [...areas]
        newArr[index][e.target.name] = e.target.value;
        setAreas(newArr)
    }

    // Set Attachement
    const setAttachement = (e) => {
        var formData = new FormData();
        formData.append("file", e.target.files[0])

        uploadAnyFile(formData)

        console.log(uploadedFile)

        // let newArr = [...areas]
        // newArr[0][e.target.name] = uploadedFile.path;
        // setAreas(newArr)
        // console.log(areas)


    }

    const [image, setImage] = useState([]);
    const [selectedImages, setSelectedImages] = useState([]);
    const handleImageChange = (e) => {
        const files = e.target.files;

        const newFiles = Array.from(files).map(file => ({
            file,
            preview: file.type.startsWith('image/') ? URL.createObjectURL(file) : null,
        }));

        setImage(prevFiles => [...prevFiles, ...newFiles]);
        setSelectedImages([...selectedImages, ...files]);
    };


    const editorRef = useRef(null); //Tiny MCE
    const changeTask = (content, index, index1) => {

        let newArr = [...areas]

        newArr[index]['tasks'][index1]['task'] = content;

        setAreas(newArr)
    }

    const [selectedDays, setSelectedDays] = useState(null)

    const setDayOf = (selectedItem) => {

        setSelectedDays(selectedItem)

    }
    const [index, setIndex] = useState(null)
    const [index1, setIndex1] = useState(null)


    const changeDayOf = (selectedItem) => {
        let newArr = [...areas]

        if (selectedItem) {
            newArr[index]['tasks'][index1]['day_of'] = selectedItem.map((item) => { return item.name })


        }
        console.log(newArr)

        setAreas(newArr)


    }
    const changeDayOf1 = (index, index1) => e => {



        let newArr = [...areas]


        newArr[index]['tasks'][index1]['day_of'].push(e.target.value)

        console.log(newArr)

        setAreas(newArr)



    }

    const changeFrequency = (index, index1) => e => {
        setTaskFrequency(e.target.value)
        let newArr = [...areas]
        console.log(newArr[index]['tasks'][index1]['day_of'])

        newArr[index]['tasks'][index1]['frequency'] = e.target.value
        console.log(newArr)

        setAreas(newArr)

    }



    const addNewTask = (index) => {
        console.log(index)
        const newObj = {
            task: '',
            day_of: [],
            frequency: '',
        }
        let newArr = [...areas]
        console.log(newArr)
        newArr[index]['tasks'].push(newObj)
        setAreas(newArr)
        setTaskFrequency('')

    }






    // ------------------ Tabs End ----------------------------------------------------------------


    // On Submit




    const addJobSubmit = (e) => {
        e.preventDefault()
        console.log(areas)

        if (selectedEmployees.length === 0) {
            window.alert('Please assign atleast 1 employee to jo')
        } else {
            selectedEmployees.map(employee => (employees.push({ id: employee.id })))

            // let newArr = [...areas]
            // newArr[0]['area_attachement'] = uploadedFile.path;
            // setAreas(newArr)
            const newJob = { token, title, company_id, manager_id, site_id, client_id, start_date, summary, areas, employees, uploadedFile }
            console.log(newJob)


            var raw = {
                "token": token,
                "title": title,
                "code": 'DV-' + contractNumber,
                "company_id": company_id,
                "manager_id": user.id,
                "site_id": site_id,
                "client_id": client_id,
                "file": selectedImages,
                "start_date": start_date,
                "summary": summary,
                "areas": areas,
                "employees": employees
            }
            console.log(raw)
            addNewJob(raw, history)

        }






    }


    // Toggle View with Validation

    const showAreasForm = () => {
        if (title === '') {
            window.alert('Please assign a title')
        } else if (client_id === '') {
            window.alert('Please assign a client')
        } else if (site_id === '') {
            window.alert('Please assign a site')
        } else if (start_date === '') {
            window.alert('Please add date')
        }


        else if (selectedEmployees.length === 0) {
            window.alert('Please assign atleast 1 employee to jo')
        } else if (areas.length === 0) {
            window.alert('Please select atleast 1 area for job')
        } else if (site_access.length === 0) {
            window.alert('Please select provided security access for area')
        }
        else (
            setToggleView('1')
        )


    }

    const showEmployeesForm = () => {
    }

    return (
        <>
            <PanelHeader size="sm" />
            <div className="content" >
                <Row >
                    <Col md={12} className="ml-auto mr-auto">




                        <Card >
                            <Form onSubmit={e => addJobSubmit(e)} encType="multipart/form-data" className="p-4">

                                {
                                    toggleView === '0' ? (
                                        <React.Fragment>
                                            <div className="d-flex align-items-center justify-content-between">
                                                <h5>Add New Job</h5>

                                            </div>
                                            <label>Contract Number</label>
                                            <FormGroup>
                                                <Input type="text" readOnly defaultValue={'DV-' + contractNumber} />
                                            </FormGroup>
                                            <label>Contract Title</label>
                                            <FormGroup>
                                                <Input type="text" required onChange={e => setTitle(e.target.value)} />
                                            </FormGroup>
                                            <label>Client</label>
                                            <FormGroup>
                                                <select value={client_id} name="client_id" required onChange={e => setclient_id(e.target.value)} className="form-control">
                                                    <option value=""></option>
                                                    {
                                                        allClients.clients.map(client => (
                                                            <option value={client.id} key={client.id}>{client?.company_name}</option>
                                                        ))
                                                    }
                                                </select>
                                            </FormGroup>
                                            {/* <label>Manager</label>
                                            <FormGroup>
                                                <select value={manager_id} required name="manager_id" onChange={e => setmanager_id(e.target.value)} className="form-control">
                                                    <option value=""></option>
                                                    {
                                                        allManagers.managers.map(manager => (
                                                            <option value={manager.id} key={manager.id}>{manager.fname + ' ' + manager.lname}</option>
                                                        ))
                                                    }
                                                </select>
                                            </FormGroup> */}
                                            <label>Site</label>
                                            <FormGroup>
                                                <select value={site_id} required name="site_id" onChange={e => setsite_id(e.target.value)} className="form-control">
                                                    <option value=""></option>
                                                    {
                                                        allSites?.sites?.map?.(site => (
                                                            <option value={site.id} key={site.id}>{site.site_name}</option>
                                                        ))
                                                    }
                                                </select>
                                            </FormGroup>
                                            <label htmlFor="">Start Date</label>
                                            <FormGroup>
                                                <input type="date" onChange={e => setStartDate(e.target.value)} className="form-control" />

                                            </FormGroup>
                                            <label>Areas to be Cleaned</label>
                                            <FormGroup>
                                                <Multiselect
                                                    options={(site_id && siteProfile?.areas?.length > 0) ? siteProfile?.areas?.map(data => ({
                                                        ...data,
                                                        tasks: [],
                                                        title: data?.get_area?.name
                                                    })) : []}
                                                    isObject={true}
                                                    required
                                                    displayValue="title"
                                                    showCheckbox={true}
                                                    closeOnSelect={false}
                                                    avoidHighlightFirstOption={true}
                                                    showArrow={true}
                                                    onSelect={onSelect1}
                                                    onRemove={onSelect1}
                                                    className="form-control"

                                                />
                                            </FormGroup>
                                            <label>Building Access & Security</label>
                                            <FormGroup>
                                                <Multiselect
                                                    options={(site_id && siteProfile?.buildings?.length > 0) ? siteProfile?.buildings?.map(data => ({
                                                        id: data?.get_building?.id,
                                                        title: data?.get_building?.name
                                                    })) : []}
                                                    isObject={true}
                                                    required
                                                    displayValue="title"
                                                    showCheckbox={true}
                                                    closeOnSelect={false}
                                                    avoidHighlightFirstOption={true}
                                                    showArrow={true}
                                                    onSelect={onSelect2}
                                                    onRemove={onSelect2}
                                                    className="form-control"

                                                />
                                            </FormGroup>
                                            <label>Employees</label>
                                            <FormGroup>
                                                <Multiselect
                                                    options={allStaff.staff}
                                                    isObject={true}
                                                    required
                                                    displayValue="fname"
                                                    showCheckbox={true}
                                                    closeOnSelect={false}
                                                    avoidHighlightFirstOption={true}
                                                    showArrow={true}
                                                    onSelect={onSelect}
                                                    onRemove={onSelect}

                                                />
                                            </FormGroup>

                                            <div className="text-right">
                                                <Button onClick={() => showAreasForm()} color="info">Next</Button>


                                            </div>

                                        </React.Fragment>
                                    ) : toggleView === '1' ? (
                                        <React.Fragment>
                                            <div className="d-flex align-items-center justify-content-between">
                                                <h5>  <i className="fas fa-arrow-circle-left text-info" style={{ cursor: 'pointer', fontSize: '18px' }} onClick={() => setToggleView('0')}></i> Add New Job</h5>

                                                {
                                                    loadingSpinner ? (
                                                        <button class="btn btn-info" type="button" disabled>
                                                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                            Uploading File...
                                                        </button>

                                                    ) : (

                                                        <div className="text-right">
                                                            < input
                                                                type="file"
                                                                name="area_attachement"
                                                                className=""
                                                                multiple
                                                                accept="image/jpeg, image/png, application/pdf"
                                                                onChange={e => handleImageChange(e)}
                                                            />

                                                            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                                                {image.map((fileItem, index) => (
                                                                    <div key={index} style={{ margin: '10px' }}>
                                                                        {fileItem.preview ? (
                                                                            <img
                                                                                src={fileItem.preview}
                                                                                alt={`Uploaded Image ${index}`}
                                                                                style={{ width: '100px', height: '100px' }}
                                                                            />
                                                                        ) : (
                                                                            <div>
                                                                                <span style={{ fontWeight: 'bold' }}>{fileItem.file.name} selected</span>
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                ))}
                                                            </div>

                                                        </div>
                                                    )
                                                }



                                            </div>
                                            <Row className="mt-5">

                                                <Col lg={12} md={12} xs={12} className=" ">

                                                    <Nav pills className="nav-pills-info">
                                                        {
                                                            areas.map((area, index) => (
                                                                <NavItem>
                                                                    <NavLink className={hTabs === `${area.id}` ? "active" : ""} onClick={() => sethTabs(`${area.id}`)} >
                                                                        {
                                                                            editMode && hTabs === area.id ? (<input value={area.title} name="title" required className="form-control border border-info bg-transparent text-white" onChange={onChange(index)} />) : area.title
                                                                        }

                                                                    </NavLink>
                                                                </NavItem>


                                                            ))
                                                        }
                                                    </Nav>

                                                    <TabContent activeTab={hTabs} className="tab-space">

                                                        {
                                                            areas.length > 0 && (
                                                                areas.map((area, index) => (
                                                                    <TabPane tabId={`${area.id}`} style={{ height: '600px', position: 'relative', overflowX: 'hidden', overflowY: 'auto' }}>
                                                                        {
                                                                            area.tasks.map((task, index1) => (

                                                                                <div className="row my-3">
                                                                                    <div className="col-md-6">
                                                                                        {/* <input className="form-control" required onChange={changeTask(index, index1)} /> */}
                                                                                        <Editor
                                                                                            onInit={(evt, editor) => editorRef.current = editor}
                                                                                            apiKey='ig44t7sd2d9z3w3i5xaq9rrm9x3gvwhujfrxt0y3wlx8uxya'
                                                                                            onPostRender={() => {
                                                                                                <div class="spinner-border text-primary" role="status">
                                                                                                    <span class="sr-only">Loading...</span>
                                                                                                </div>
                                                                                            }}
                                                                                            // onInit={() => console.log('rendered')}
                                                                                            initialValue=""
                                                                                            required
                                                                                            init={{
                                                                                                height: 200,
                                                                                                menubar: false,
                                                                                                plugins: [
                                                                                                    'advlist autolink lists link image charmap print preview anchor',
                                                                                                    'searchreplace visualblocks code fullscreen',
                                                                                                    'insertdatetime media table paste code help wordcount'
                                                                                                ],
                                                                                                toolbar: 'undo redo | formatselect | ' +
                                                                                                    'bold italic backcolor | alignleft aligncenter ' +
                                                                                                    'alignright alignjustify | bullist numlist outdent indent | ' +
                                                                                                    'removeformat | help',
                                                                                                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'

                                                                                            }}
                                                                                            onEditorChange={(content) => changeTask(content, index, index1)}
                                                                                        />
                                                                                        {/* <textarea name="" className="basic-example" required onChange={changeTask(index, index1)} cols="30" rows="10"></textarea> */}
                                                                                    </div>
                                                                                    <div className="col-md-3">
                                                                                        <label htmlFor="">Select Frequency</label>
                                                                                        <select name="" id="" className="form-control" value={areas[index]['tasks'][index1]['frequency']}
                                                                                            onChange={changeFrequency(index, index1)}
                                                                                            onClick={() => { setIndex(index); setIndex1(index1) }}
                                                                                        >
                                                                                            <option value="">Select</option>
                                                                                            <option value="weekly">Day(s) of the Week</option>
                                                                                            <option value="daily">Daily</option>
                                                                                            <option value="monthly">Once every month</option>
                                                                                            <option value="weekend">Weekend</option>
                                                                                            <option value="fortnightly">Fortnightly</option>
                                                                                        </select>

                                                                                    </div>
                                                                                    <div className="col-md-3">

                                                                                        {
                                                                                            areas[index]['tasks'][index1]['frequency'] === 'weekly' ? (
                                                                                                <>

                                                                                                    <label htmlFor="">Select Day(s)</label>
                                                                                                    <Multiselect
                                                                                                        options={[
                                                                                                            {
                                                                                                                id: '1',
                                                                                                                name: 'Monday'
                                                                                                            },
                                                                                                            {
                                                                                                                id: '2',
                                                                                                                name: 'Tuesday'
                                                                                                            },
                                                                                                            {
                                                                                                                id: '3',
                                                                                                                name: 'Wednesday'
                                                                                                            },
                                                                                                            {
                                                                                                                id: '4',
                                                                                                                name: 'Thursday'
                                                                                                            },
                                                                                                            {
                                                                                                                id: '5',
                                                                                                                name: 'Friday'
                                                                                                            },

                                                                                                        ]}
                                                                                                        isObject={true}
                                                                                                        required
                                                                                                        displayValue="name"
                                                                                                        showCheckbox={true}
                                                                                                        closeOnSelect={false}
                                                                                                        avoidHighlightFirstOption={true}
                                                                                                        showArrow={true}
                                                                                                        onSelect={changeDayOf}
                                                                                                        onRemove={changeDayOf}
                                                                                                        className="form-control"

                                                                                                    />
                                                                                                </>

                                                                                            ) : areas[index]['tasks'][index1]['frequency'] === 'monthly' ? (
                                                                                                <>
                                                                                                    <label htmlFor="">Select Day</label>
                                                                                                    <input type="text" list="days" required className="form-control" onChange={changeDayOf1(index, index1)} placeholder="Select Day" />
                                                                                                    <datalist id="days" >
                                                                                                        <option>1st</option>
                                                                                                        <option>15th</option>
                                                                                                        <option>30th</option>

                                                                                                    </datalist>
                                                                                                </>

                                                                                            ) : areas[index]['tasks'][index1]['frequency'] === 'fortnightly' ? (
                                                                                                <>
                                                                                                    <label htmlFor="">Select Time</label>
                                                                                                    <input type="time" onChange={changeDayOf1(index, index1)} className="form-control" />
                                                                                                </>
                                                                                            ) : ''
                                                                                        }

                                                                                    </div>
                                                                                </div>
                                                                            ))
                                                                        }
                                                                        <Link className="btn btn-info" onClick={() => addNewTask(index)} >   <i className="fas fa-plus-circle mr-2" ></i>Add New Task</Link>
                                                                    </TabPane>
                                                                )))
                                                        }
                                                    </TabContent>
                                                </Col>
                                            </Row>
                                            <div className="text-right">
                                                <Link onClick={() => setToggleView('2')} className="btn btn-info">Next</Link>


                                            </div>
                                        </React.Fragment>

                                    ) : toggleView === '2' ? (
                                        <React.Fragment>
                                            <div className="d-flex align-items-center justify-content-between">
                                                <h5>  <i className="fas fa-arrow-circle-left text-info" style={{ cursor: 'pointer', fontSize: '18px' }} onClick={() => setToggleView('1')}></i> Add New Job</h5>


                                            </div>

                                            <Row>
                                                {/* <Col md="6">
                                                    <label htmlFor="">Client</label>
                                                    <FormGroup>
                                                        <Input readOnly defaultValue={`${userProfile.fname} ${userProfile.lname}`} />

                                                    </FormGroup>
                                                </Col>
                                                <Col md="6">
                                                    <label htmlFor="">Site</label>
                                                    <FormGroup>
                                                        <Input readOnly defaultValue={siteProfile.site_name} />

                                                    </FormGroup>
                                                </Col> */}
                                                <Table>
                                                    <tbody>
                                                        <tr>
                                                            <td className="font-weight-bold">CLIENT NAME</td>
                                                            <td>{`${userProfile.fname} ${userProfile.lname}`}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="font-weight-bold">TEAM NAMES</td>
                                                            <td>{selectedEmployees.map((employee, index) => (
                                                                <span key={index}>  {employee.fname + ' ' + employee.lname} ,</span>
                                                            ))}</td>
                                                        </tr>
                                                        {/* <tr>
                                                            <td className="font-weight-bold">WEEK</td>
                                                            <td>5 DAYS PER WEEK</td>
                                                        </tr> */}
                                                    </tbody>
                                                </Table>
                                                <Table>

                                                    <thead>
                                                        <tr>
                                                            {/* <th>Item</th> */}
                                                            <th className="font-weight-normal">Area</th>
                                                            <td className="font-weight-bold">Task</td>
                                                            <td className="font-weight-bold">Frequency</td>
                                                            <td className="font-weight-bold">Daily</td>
                                                            <td className="font-weight-bold">Mon</td>
                                                            <td className="font-weight-bold">Tue</td>
                                                            <td className="font-weight-bold">Wed</td>
                                                            <td className="font-weight-bold">Thu</td>
                                                            <td className="font-weight-bold">Fri</td>
                                                            <td className="font-weight-bold">Weekend</td>
                                                            <td className="font-weight-bold">Monthly</td>
                                                            <td className="font-weight-bold">Fortnightly</td>
                                                        </tr>
                                                    </thead>
                                                    <tbody>

                                                        {
                                                            areas.map((area, index) => (
                                                                <>
                                                                    <tr key={index} className="border">
                                                                        <th rowSpan={area.tasks.length + 1} className="font-weight-normal border">{area.title}</th>

                                                                    </tr>
                                                                    {
                                                                        area.tasks.map((task, index) => (
                                                                            <tr >

                                                                                <td className="border">{ReactHtmlParser(task.task)}</td>
                                                                                <td className="text-capitalize border">{task.frequency}</td>
                                                                                <td className="border">
                                                                                    {task.frequency === 'daily' ? (<i className="fas fa-check"></i>) : task.frequency === 'fortnightly' ? (task.day_of) : ''}


                                                                                </td>
                                                                                <td className="border">{task.frequency === 'weekly' ? (task.day_of.includes('Monday') ? (<i className="fas fa-check"></i>) : '') : ''}</td>
                                                                                <td className="border">{task.frequency === 'weekly' ? (task.day_of.includes('Tuesday') ? (<i className="fas fa-check"></i>) : '') : ''}</td>
                                                                                <td className="border">{task.frequency === 'weekly' ? (task.day_of.includes('Wednesday') ? (<i className="fas fa-check"></i>) : '') : ''}</td>
                                                                                <td className="border">{task.frequency === 'weekly' ? (task.day_of.includes('Thursday') ? (<i className="fas fa-check"></i>) : '') : ''}</td>
                                                                                <td className="border">{task.frequency === 'weekly' ? (task.day_of.includes('Friday') ? (<i className="fas fa-check"></i>) : '') : ''}</td>
                                                                                <td className="border">{task.frequency === 'weekend' ? (<i className="fas fa-check"></i>) : ''}</td>
                                                                                <td className="border">{task.frequency === 'monthly' ? (<span>{task?.day_of} of every month</span>) : ''}</td>
                                                                                <td className="border">{task?.frequency === 'fortnightly' ? (<span>{task.day}</span>) : ''}</td>
                                                                            </tr>

                                                                        ))
                                                                    }
                                                                </>


                                                            ))
                                                        }


                                                    </tbody>
                                                </Table>



                                                <Col md="12">
                                                    <label>Summary</label>
                                                    <FormGroup>
                                                        <textarea name="summary" value={summary} onChange={e => setSummary(e.target.value)} id="" cols="30" rows="10" className="form-control border rounded">

                                                        </textarea>
                                                    </FormGroup>
                                                </Col>


                                            </Row>

                                            <div className="text-right">
                                                {
                                                    loadingSpinner ? (
                                                        <button class="btn btn-info" type="button" disabled>
                                                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                            Uploading File...
                                                        </button>

                                                    ) : isCreatingJob ? (
                                                        <button class="btn btn-info" type="button" disabled>
                                                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                            Creating Job...
                                                        </button>
                                                    )

                                                        :

                                                        (

                                                            <Button type="submit" color="info">Create Job</Button>



                                                        )
                                                }


                                            </div>
                                        </React.Fragment>


                                    )



                                        : null
                                }









                            </Form>
                        </Card>
                    </Col>
                </Row>

            </div>
        </>
    );
}

const mapStateToProps = state => ({
    allClients: state.profileReducer.allClients,
    allSites: state.profileReducer.allSites,
    allStaff: state.profileReducer.allStaff,
    uploadedFile: state.profileReducer.uploadedFile,
    allManagers: state.profileReducer.allManagers,
    isLoading: state.profileReducer.isLoading,
    isCreatingJob: state.profileReducer.isCreatingJob,
    userProfile: state.profileReducer.userProfile.user,
    siteProfile: state.profileReducer.siteProfile.site,
    user: state.authReducer.user

})

export default connect(mapStateToProps, { getAllClients, getAllSites, getAllStaff, addNewJob, uploadAnyFile, getAllManagers, getUserProfile, getSiteProfile })(AddNewJob);
