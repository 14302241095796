
import React, { useState, useEffect } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import Select from "react-select";
import { Link, useHistory } from 'react-router-dom'
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import { connect } from 'react-redux';
import {
    getAllStocks,
    addNewStock,
    updateStock,
    deleteStock,
    getAllStockCategories,
    addStockCategory,
    removeSweetAlert,
    getStockRequests,
    changeStockStatus
} from "actions/profileActions";
import SweetAlert from "react-bootstrap-sweetalert";

// react component used to create sweet alerts
// reactstrap components
import {

    Card,
    Container, Col,
    Row, Modal,
    ModalBody,
    ModalFooter,
    ModalHeader, Button, FormGroup, CardHeader, Form, Input, Label, UncontrolledTooltip

} from "reactstrap";
// core components
import PanelHeader from "components/PanelHeader/PanelHeader.js";

const pagination = paginationFactory({
    page: 1,
    alwaysShowAllBtns: true,
    showTotal: true,
    withFirstAndLast: false,
    sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
        <div className="dataTables_length " id="datatable-basic_length">
            <label className="d-flex justify-content-start">
                Show{" "}
                {
                    <select
                        name="datatable-basic_length"
                        aria-controls="datatable-basic"
                        className="form-control"
                        onChange={(e) => onSizePerPageChange(e.target.value)}
                    >
                        <option value="10">10</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                    </select>
                }{" "}
                entries.
            </label>
        </div>
    ),
});


const { SearchBar } = Search;


const StockRequests = ({ getAllStocks, allStocks, addNewStock, updateStock, deleteStock, getAllStockCategories, allStockCategories, addStockCategory, removeSweetAlert, isLoading, deletedStock, updatedStock, newStock, sweetAlert, getStockRequests, allstockRequests, changeStockStatus, stockStatus }) => {


    useEffect(() => {

        getStockRequests()


    }, [])

    // Sweet Alert

    const [alert, setAlert] = React.useState(null);
    React.useEffect(() => {
        return function cleanup() {
            var id = window.setTimeout(null, 0);
            while (id--) {
                window.clearTimeout(id);
            }
        };
    }, []);

    useEffect(() => {
        if (sweetAlert) {
            successAlert()


        } else {
            hideAlert()
        }
    }, [sweetAlert])

    const successAlert = () => {
        setAlert(
            <SweetAlert
                success
                style={{ display: "block", marginTop: "-100px" }}
                title="Success!"
                onConfirm={() => hideAlert()}
                onCancel={() => hideAlert()}
                confirmBtnBsStyle="info"
            >
                {stockStatus ? stockStatus : null}
            </SweetAlert>
        );
    }
    const hideAlert = () => {
        setAlert(null);
        removeSweetAlert()

    };






    const history = useHistory();
    const [modalClassic, setModalClassic] = React.useState(false);
    const toggleModalClassic = () => {
        setModalClassic(!modalClassic);
    };
    const [modalClassic1, setModalClassic1] = React.useState(false);
    const toggleModalClassic1 = () => {
        setModalClassic1(!modalClassic1);
    };
    const [modalClassic2, setModalClassic2] = React.useState(false);
    const toggleModalClassic2 = () => {
        setModalClassic2(!modalClassic2);
    };
    const [modalClassic3, setModalClassic3] = React.useState(false);
    const toggleModalClassic3 = () => {
        setModalClassic3(!modalClassic3);
    };








    const [userProfile, setUserProfile] = useState([])
    const setModal = (row) => {
        toggleModalClassic()
        setUserProfile(row)


    }

    const setUpdateProfile = (row) => {
        toggleModalClassic2()
        setUserProfile(row)


    }



    var [formData, setFormData] = useState({
        id: '',
        title: '',
        quantity: '',
        company_id: '1',
        category_id: '',
        status: '1',
        name: ''



    })

    var { id, title, company_id, quantity, category_id, status, name } = formData;
    const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value })

    const updateStaffSubmit = e => {
        e.preventDefault();

        if (id === '') {
            id = userProfile.id
        }
        if (title === '') {
            title = userProfile.title
        }
        if (company_id === '') {
            company_id = userProfile.company_id
        }

        if (quantity === '') {
            quantity = userProfile.quantity
        }

        if (status === '') {
            status = userProfile.status
        }

        var formData = new FormData();
        formData.append("stock_id", userProfile.id);
        formData.append("title", title);
        formData.append("company_id", company_id);
        formData.append("status", status);

        formData.append("quantity", quantity);
        updateStock(formData, history)

        setFormData({
            id: '',
            title: '',
            quantity: '',
            company_id: '1',
            category_id: '',
            status: '1'

        })

    }






    function statusFormatter(cell, row) {


        return (
            <span>   {cell === 0 ? 'Pending' : cell === 1 ? 'Accepted' : cell === 2 ? 'Rejected' : null} </span>
        );
    }

    return (
        <>
            <PanelHeader size="sm" />
            <div className="content">

                {alert}

                <Row>
                    <div className="col">
                        <Card>

                            {
                                isLoading ? (
                                    <div className="text-center">
                                        <div class="spinner-border text-info text-center" role="status">
                                            <span class="sr-only">Loading...</span>
                                        </div>
                                    </div>
                                ) : (

                                    allstockRequests !== undefined && allstockRequests.length > 0 ? (
                                        <>

                                            <ToolkitProvider
                                                data={allstockRequests}
                                                keyField="id"
                                                bootstrap4

                                                columns={[
                                                    {
                                                        dataField: "user.fname",
                                                        text: "Employee",
                                                        sort: true,
                                                    },
                                                    {
                                                        dataField: "site.site_name",
                                                        text: "Site",
                                                        sort: true,
                                                    },
                                                    {
                                                        dataField: "category.name",
                                                        text: "Category",
                                                        sort: true,
                                                    },

                                                    {
                                                        dataField: "title",
                                                        text: "Stock",
                                                        sort: true,
                                                    },

                                                    // {
                                                    //     dataField: "company",
                                                    //     text: "Staff",
                                                    //     sort: true,
                                                    // },
                                                    {
                                                        dataField: "quantity",
                                                        text: "Quantity",
                                                        sort: true,
                                                    },
                                                    {
                                                        dataField: "status",
                                                        text: "Status",
                                                        sort: true,
                                                        formatter: statusFormatter

                                                    },


                                                    {
                                                        dataField: 'df2',
                                                        isDummyField: true,
                                                        text: 'Action',
                                                        formatter: (cellContent, row) => {
                                                            return (
                                                                <h5 className="d-flex justify-content-start">
                                                                    {
                                                                        row.status === 0 ? (
                                                                            <div>
                                                                                <span className="btn btn-success btn-sm m-1" onClick={() => changeStockStatus(row.id, 1, history)} >Accept</span>
                                                                                <span className="btn btn-danger btn-sm m-1" onClick={() => changeStockStatus(row.id, 2, history)} > Reject</span>
                                                                            </div>


                                                                        ) : row.status === 1 ? (
                                                                            <div>
                                                                                {
                                                                                    isLoading ? (
                                                                                        <div class="spinner-border text-primary" role="status">
                                                                                            <span class="sr-only">Loading...</span>
                                                                                        </div>
                                                                                    ) : (
                                                                                        <span className="btn btn-danger btn-sm m-1" onClick={() => changeStockStatus(row.id, 2, history)} > Reject</span>
                                                                                    )

                                                                                }

                                                                            </div>

                                                                        ) : row.status === 2 ? (
                                                                            <div>
                                                                                {
                                                                                    isLoading ? (
                                                                                        <div class="spinner-border text-primary" role="status">
                                                                                            <span class="sr-only">Loading...</span>
                                                                                        </div>
                                                                                    ) : (
                                                                                        <span className="btn btn-success btn-sm m-1" onClick={() => changeStockStatus(row.id, 1, history)} > Accept</span>
                                                                                    )
                                                                                }

                                                                            </div>
                                                                        )


                                                                            : null
                                                                    }

                                                                </h5>
                                                            );

                                                        }
                                                    }


                                                ]}
                                                search
                                            >
                                                {(props) => (
                                                    <div className="py-4 ">
                                                        <div
                                                            id="datatable-basic_filter"
                                                            className="dataTables_filter px-4 pb-1 d-flex  align-items-center justify-content-between"
                                                        >

                                                            <label>
                                                                Search:
                                                                <SearchBar
                                                                    className="form-control"
                                                                    placeholder=""
                                                                    {...props.searchProps}
                                                                />
                                                            </label>
                                                            {/* <div>
                                                            <Link className="btn btn-info btn-sm" onClick={toggleModalClassic3}>Add New Category</Link>
                                                            <Link className="btn btn-info btn-sm" onClick={setAddStockModal}>Add New Stock</Link>
                            </div> */}


                                                        </div>
                                                        <BootstrapTable
                                                            {...props.baseProps}
                                                            bootstrap4={true}
                                                            pagination={pagination}
                                                            bordered={false}
                                                        />
                                                    </div>
                                                )}
                                            </ToolkitProvider>




                                        </>

                                    ) : (
                                        <>
                                            <table className="table">
                                                <thead>
                                                    <tr>
                                                        <th>Employee</th>
                                                        <th>Site</th>
                                                        <th>Category</th>
                                                        <th>Stock</th>
                                                        <th>Quantity</th>
                                                        <th>Status</th>
                                                    </tr>
                                                </thead>


                                            </table>
                                            <p className="text-center">No Data Found</p>

                                        </>
                                    )

                                )

                            }


                        </Card>

                    </div>


                </Row>

            </div >

        </>
    );
}

const mapStateToProps = state => ({
    allStocks: state.profileReducer.allStocks,
    allStockCategories: state.profileReducer.allStockCategories.categories,
    isLoading: state.profileReducer.isLoading,
    sweetAlert: state.profileReducer.sweetAlert,
    newStock: state.profileReducer.newStock.message,
    deletedStock: state.profileReducer.deletedStock.message,
    updatedStock: state.profileReducer.updatedStock.message,
    allstockRequests: state.profileReducer.stockRequests.stockRecord,
    stockStatus: state.profileReducer.stockStatus.message
})

export default connect(mapStateToProps, { getAllStocks, updateStock, addNewStock, deleteStock, getAllStockCategories, addStockCategory, removeSweetAlert, getStockRequests, changeStockStatus })(StockRequests);






















