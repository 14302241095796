import { CHANGE_ADD_NOTE, CHANGE_GLOBAL_LOADER, SELECTED_JOB_DATA, CHANGE_GET_ALL_NOTES } from "./types";

export const ChangeSelectedJobData = (val) => {
    return async (dispatch) => {
        dispatch({ type: SELECTED_JOB_DATA, payload: val })
    }
}

export const addNotes = (val) => {
    return async (dispatch) => {
        // dispatch({ type: CHANGE_GLOBAL_LOADER, payload: true })

        // var myHeaders = new Headers();
        // myHeaders.append("Authorization", "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOlwvXC9kZXZhbHRvbi5lc3F1YWxsLmNvbVwvYXBpXC9hZG1pblwvbG9naW4iLCJpYXQiOjE2NDY4OTM4MDIsImV4cCI6MTY0Njg5NzQwMiwibmJmIjoxNjQ2ODkzODAyLCJqdGkiOiJsZ01Oa0NhblBCbG5reG1EIiwic3ViIjoxLCJwcnYiOiJkZjg4M2RiOTdiZDA1ZWY4ZmY4NTA4MmQ2ODZjNDVlODMyZTU5M2E5In0.mJdMK2TM08puESsy_uVBnBn6EsHMNLNaKPa23Bgwnb4");

        // var formdata = new FormData();
        // formdata.append("job_id", val.jobId);
        // formdata.append("sender", val.senderId);
        // formdata.append("notes", val.note);
        // formdata.append("attachments", val.attachments);

        // var requestOptions = {
        //   method: 'POST',
        //   headers: myHeaders,
        //   body: formdata,
        //   redirect: 'follow'
        // };

        // fetch("https://api.eagleiq.online/api/admin/add_notes", requestOptions)
        //   .then(response => response.json())
        //   .then(data => {
        //     dispatch({ type: CHANGE_ADD_NOTE, payload: data })
        //     dispatch({ type: CHANGE_GLOBAL_LOADER, payload: false })
        //     console.log(data)
        //   })
        //   .catch(error => { console.log('error', error); dispatch({ type: CHANGE_GLOBAL_LOADER, payload: true }) });

        var myHeaders = new Headers();
        myHeaders.append("Authorization", "1|cD7ZRnIB6yp7Datsz9q4cOzTLrzrjWVuFqtAH1Vg");

        var formdata = new FormData();
        formdata.append("job_id", val.jobId);
        formdata.append("notes", val.note);
        formdata.append("token", localStorage.getItem("token"));
        formdata.append("file", val.attachments);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        fetch("https://api.eagleiq.online/api/manager/add_notes", requestOptions)
            .then(response => response.json())
            .then(data => {
                dispatch({ type: CHANGE_ADD_NOTE, payload: data })
                // dispatch({ type: CHANGE_GLOBAL_LOADER, payload: false })
                console.log(data)
            })
            .catch(error => {
                console.log('error', error);
                //  dispatch({ type: CHANGE_GLOBAL_LOADER, payload: true }) 
            });
    }
}

export const getAllNotes = (val) => {
    return async (dispatch) => {
        // dispatch({ type: CHANGE_GLOBAL_LOADER, payload: true })

        var myHeaders = new Headers();
        // myHeaders.append("Authorization", "Bearer 1|cD7ZRnIB6yp7Datsz9q4cOzTLrzrjWVuFqtAH1Vg");

        var formdata = new FormData();
        formdata.append("job_id", val);
        formdata.append("token", localStorage.getItem("token"));

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        fetch("https://api.eagleiq.online/api/manager/get_notes", requestOptions)
            .then(response => response.json())
            .then(data => {
                dispatch({ type: CHANGE_GET_ALL_NOTES, payload: data })
                // dispatch({ type: CHANGE_GLOBAL_LOADER, payload: false })
                console.log(data)
            })
            .catch(error => {
                console.log('error', error);
                // dispatch({ type: CHANGE_GLOBAL_LOADER, payload: true })
            });
    }
}