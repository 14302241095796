
import React, { useState, useEffect } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import Select from "react-select";
import { Link, useHistory } from 'react-router-dom'
import img from "assets/img/james.jpg"
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import { connect } from 'react-redux';
import { getAllVideos, addNewVideo, getVideoCategories, updateVideo, deleteVideo, addVideoCategory, removeSweetAlert } from "actions/profileActions";
import SweetAlert from "react-bootstrap-sweetalert";

// react component used to create sweet alerts
// reactstrap components
import {

    Card,
    Container, Col,
    Row, Modal,
    ModalBody,
    ModalFooter,
    ModalHeader, Button, FormGroup, CardHeader, Form, Input, Label, UncontrolledTooltip

} from "reactstrap";
// core components
import PanelHeader from "components/PanelHeader/PanelHeader.js";


const pagination = paginationFactory({
    page: 1,
    alwaysShowAllBtns: true,
    showTotal: true,
    withFirstAndLast: false,
    sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
        <div className="dataTables_length " id="datatable-basic_length">
            <label className="d-flex justify-content-start">
                Show{" "}
                {
                    <select
                        name="datatable-basic_length"
                        aria-controls="datatable-basic"
                        className="form-control"
                        onChange={(e) => onSizePerPageChange(e.target.value)}
                    >
                        <option value="10">10</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                    </select>
                }{" "}
                entries.
            </label>
        </div>
    ),
});


const { SearchBar } = Search;


const VideoTutorials = ({ getAllVideos, allVideos, addNewVideo, getVideoCategories, videoCategories, updateVideo, deleteVideo, addVideoCategory, isLoading, sweetAlert, successMsg, videoAddedMsg, videoDeletedMsg, removeSweetAlert }) => {
    const history = useHistory()
    const [modalClassic, setModalClassic] = React.useState(false);
    const toggleModalClassic = () => {
        setModalClassic(!modalClassic);
    };
    const [modalClassic3, setModalClassic3] = React.useState(false);
    const toggleModalClassic3 = () => {
        setModalClassic3(!modalClassic3);
    };
    const singleFileRef = React.useRef();

    const handleSingleFileInput = (e) => {
        singleFileRef.current.click(e);
    };

    const singleFileRef1 = React.useRef();

    const handleSingleFileInput1 = (e) => {
        singleFileRef1.current.click(e);
    };


    const [videos, setVideos] = useState([]);

    useEffect(() => {
        getAllVideos();
        getVideoCategories();

    }, [])

    // Loading Spinner
    const [loadingSpinner, setLoadingSpinner] = useState(false)

    useEffect(() => {

        setLoadingSpinner(isLoading)

    }, [isLoading])

    // Sweet Alert

    const [alert, setAlert] = React.useState(null);
    React.useEffect(() => {
        return function cleanup() {
            var id = window.setTimeout(null, 0);
            while (id--) {
                window.clearTimeout(id);
            }
        };
    }, []);

    useEffect(() => {
        if (sweetAlert) {
            successAlert()


        } else {
            hideAlert()
        }
    }, [sweetAlert])

    const successAlert = () => {
        setAlert(
            <SweetAlert
                success
                style={{ display: "block", marginTop: "-100px" }}
                title="Success!"
                onConfirm={() => hideAlert()}
                onCancel={() => hideAlert()}
                confirmBtnBsStyle="info"
            >
                {successMsg ? successMsg : videoAddedMsg ? videoAddedMsg : videoDeletedMsg ? videoDeletedMsg : null}
            </SweetAlert>
        );
    }
    const hideAlert = () => {
        setAlert(null);
        removeSweetAlert()
    };




    useEffect(() => {
        if (allVideos.length !== 0) {
            setVideos(allVideos.videos)



        } else {
            return null
        }
    })

    //  ADd Video


    const [formdata, setFormdata] = useState({
        title: '',
        src_file: '',
        icon: '',
        category_id: '',
        company_id: '1',
        name: '',
        description: '',
        status: '1',




    })

    var { title, src_file, icon, category_id, company_id, name, description, status } = formdata;
    const onChange = e => setFormdata({ ...formdata, [e.target.name]: e.target.value })
    const onChange1 = e => setFormdata({ ...formdata, [e.target.name]: e.target.files[0] })

    const addVideoSubmit = (e) => {
        e.preventDefault()
        var formData = new FormData();
        formData.append("title", title)
        formData.append("company_id", company_id)
        formData.append("category_id", category_id)
        formData.append("src_file", src_file)
        formData.append("icon", icon)
        formData.append("status", status)

        addNewVideo(formData, history)

        setFormdata({
            title: '',
            src_file: '',
            icon: '',
            category_id: '',
            company_id: '1',
            status: ''

        })

    }
    function iconFormatter(cell, row) {


        return (
            <span>   {cell === 0 ? 'InActive' : 'Active'} </span>
        );
    }

    // View Video

    const [modalClassic1, setModalClassic1] = React.useState(false);
    const toggleModalClassic1 = () => {
        setModalClassic1(!modalClassic1);
    };

    const [userProfile, setUserProfile] = useState([])
    const setModal = (row) => {
        toggleModalClassic1()
        setUserProfile(row)


    }

    // Update Video

    const [modalClassic2, setModalClassic2] = React.useState(false);
    const toggleModalClassic2 = () => {
        setModalClassic2(!modalClassic2);
    };

    const setUpdateProfile = (row) => {
        toggleModalClassic2()
        setUserProfile(row)


    }

    const updateVideoSubmit = e => {
        e.preventDefault();

        if (title === '') {
            title = userProfile.title
        }
        if (company_id === '') {
            company_id = userProfile.company_id
        }
        if (category_id === '') {
            category_id = userProfile.category_id
        }

        if (src_file === '') {
            src_file = userProfile.src_file
        }
        if (icon === '') {
            icon = userProfile.icon
        }





        var formData = new FormData();
        formData.append("video_id", userProfile.id)
        formData.append("title", title)
        formData.append("company_id", company_id)
        formData.append("category_id", category_id)
        formData.append("src_file", src_file)
        formData.append("icon", icon)
        formData.append("status", status)

        updateVideo(formData, history)
        setFormdata({
            title: '',
            src_file: '',
            icon: '',
            category_id: '',
            company_id: '1',
        })

    }

    // Add Category
    const addCategorySubmit = e => {
        e.preventDefault();
        var formData = new FormData();
        formData.append("name", name);
        formData.append("company_id", company_id);
        formData.append("description", description);
        formData.append("status", status);


        addVideoCategory(formData, history)

        setFormdata({
            name: '',
            description: '',
            status: '1'

        })

    }

    return (
        <>
            <PanelHeader size="sm" />
            <div className="content">
                {alert}




                <Row>
                    <div className="col">
                        <Card>

                            <Container>

                                <ToolkitProvider
                                    data={videos}
                                    keyField="id"
                                    bootstrap4

                                    columns={[

                                        // {
                                        //     dataField: 'df2',
                                        //     isDummyField: true,
                                        //     text: '',
                                        //     formatter: (cellContent, row) => {
                                        //         return (
                                        //             <img src={img} className="img-fluid" width="30" alt="" />
                                        //         );

                                        //     }
                                        // },
                                        {
                                            dataField: "title",
                                            text: "Video Title",
                                            sort: true,
                                            formatter: (cellContent, row) => {
                                                return (
                                                    <div>
                                                        <img src={`https://api.eagleiq.online/${row.icon}`} className="img-fluid mr-2" width="30" alt="" />
                                                        {row.title}

                                                    </div>
                                                );

                                            }
                                        },
                                        {
                                            dataField: "category.name",
                                            text: "Category",
                                            sort: true,


                                        },
                                        {
                                            dataField: "created_at",
                                            text: "Date Added",
                                            sort: true,
                                        },

                                        {
                                            dataField: "status",
                                            text: "Status",
                                            sort: true,
                                            formatter: iconFormatter

                                        },


                                        {
                                            dataField: 'df2',
                                            isDummyField: true,
                                            text: 'Action',
                                            formatter: (cellContent, row) => {
                                                return (
                                                    <h5 className="">
                                                        <span className="btn btn-info btn-sm m-1" onClick={() => setModal(row)}> <i className="fas fa-eye"></i></span>
                                                        <span className="btn btn-info btn-sm m-1" onClick={() => setUpdateProfile(row)} > <i className="fas fa-edit"></i></span>
                                                        {
                                                            loadingSpinner ? (
                                                                <div class="spinner-border text-info" role="status">
                                                                    <span class="sr-only">Loading...</span>
                                                                </div>

                                                            ) : (
                                                                <span className="btn btn-danger btn-sm m-1" onClick={() => deleteVideo(row.id, history)} > <i className="fas fa-times"></i></span>
                                                            )
                                                        }
                                                        {/* <span className="btn btn-danger btn-sm m-1" onClick={() => deleteVideo(row.id, history)} > <i className="fas fa-times"></i></span> */}

                                                    </h5>
                                                );

                                            }
                                        }


                                    ]}
                                    search
                                >
                                    {(props) => (
                                        <div className="py-4 ">
                                            <div
                                                id="datatable-basic_filter"
                                                className="dataTables_filter px-4 pb-1 d-flex  align-items-center justify-content-between"
                                            >

                                                <label>
                                                    Search:
                                                    <SearchBar
                                                        className="form-control"
                                                        placeholder=""
                                                        {...props.searchProps}
                                                    />
                                                </label>
                                                <div>
                                                    <Button color="info" size="sm" onClick={toggleModalClassic3}>Add Category</Button>
                                                    <Button color="info" size="sm" onClick={toggleModalClassic}>Add New Video</Button>


                                                </div>
                                                {/* <Link className="btn btn-info btn-sm" to="/admin/add-client">Add Video</Link> */}

                                            </div>
                                            <BootstrapTable
                                                {...props.baseProps}
                                                bootstrap4={true}
                                                pagination={pagination}
                                                bordered={false}
                                            />
                                        </div>
                                    )}
                                </ToolkitProvider>
                            </Container>
                        </Card>

                        <Card
                            style={{
                                padding: '32px'
                            }}
                        >
                            <h3>FAQs</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Tempora voluptate incidunt sunt obcaecati vitae mollitia quas laudantium enim officia. Voluptate totam, quis provident alias quam perspiciatis? Voluptatum, optio porro esse ab vitae velit provident animi veniam, nam ad qui alias corrupti numquam enim blanditiis cum eius perferendis, vero adipisci ipsa.</p>
                            <h3
                                style={{
                                    marginTop: '32px'
                                }}
                            >Forms</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Tempora voluptate incidunt sunt obcaecati vitae mollitia quas laudantium enim officia. Voluptate totam, quis provident alias quam perspiciatis? Voluptatum, optio porro esse ab vitae velit provident animi veniam, nam ad qui alias corrupti numquam enim blanditiis cum eius perferendis, vero adipisci ipsa.</p>
                        </Card>

                    </div>


                </Row>
                {/* Add Video Modal */}
                <Modal
                    isOpen={modalClassic}
                    toggle={toggleModalClassic}
                    className="text-center"
                >
                    <ModalHeader
                        className="justify-content-center uppercase title"
                        toggle={toggleModalClassic}
                        tag="h4"
                    >
                        Add New Video
                    </ModalHeader>
                    <ModalBody>
                        <Form onSubmit={e => addVideoSubmit(e)} encType="multipart/form-data">
                            <FormGroup className="text-left">
                                <label>Video Title</label>

                                <Input name="title" value={title} onChange={onChange} />
                            </FormGroup>
                            <FormGroup className="text-left">
                                <label>Category</label>


                                <select name="category_id" value={category_id} required id="" onChange={onChange} className="form-control">
                                    <option value="">Select</option>
                                    {
                                        modalClassic ? (
                                            videoCategories.map(category => (
                                                <option value={category.id}>{category.name}</option>
                                            ))

                                        ) : <option value="">No Categories Found</option>
                                    }
                                </select>
                            </FormGroup>
                            <FormGroup className="text-left">
                                <label>Status</label>


                                <select name="status" value={status} id="" onChange={onChange} className="form-control">
                                    <option value="">Select</option>
                                    <option value="1">Active</option>
                                    <option value="0">InActive</option>

                                </select>
                            </FormGroup>
                            <FormGroup className="form-file-upload form-file-simple text-left">

                                <Input
                                    type="text"
                                    className="inputFileVisible btn btn-info bg-info white-input-field text-white rounded"
                                    placeholder="Select Video"
                                    onClick={(e) => handleSingleFileInput(e)}
                                    defaultValue={src_file?.name}
                                />
                                <input
                                    type="file"
                                    className="inputFileHidden"
                                    style={{ zIndex: -1 }}
                                    ref={singleFileRef}
                                    // onChange={(e) => addSingleFile(e)}
                                    name="src_file"
                                    onChange={e => onChange1(e)}
                                    accept="video/mp4,video/x-m4v,video/*"

                                />
                            </FormGroup>

                            <FormGroup className="form-file-upload form-file-simple">

                                <Input
                                    type="text"
                                    className="inputFileVisible btn btn-info bg-info white-input-field text-white rounded"
                                    placeholder="Select Video Icon"
                                    onClick={(e) => handleSingleFileInput1(e)}
                                    defaultValue={icon?.name}
                                />
                                <input
                                    type="file"
                                    className="inputFileHidden"
                                    style={{ zIndex: -1 }}
                                    ref={singleFileRef1}
                                    // onChange={(e) => addSingleFile(e)}
                                    name="icon"
                                    onChange={e => onChange1(e)}

                                />
                            </FormGroup>
                            <div className="text-center">
                                {
                                    loadingSpinner ? (
                                        <div class="spinner-border text-info" role="status">
                                            <span class="sr-only">Loading...</span>
                                        </div>

                                    ) : (
                                        <Button color="info" type="submit" >
                                            Add Video
                                        </Button>
                                    )
                                }
                            </div>

                        </Form>
                    </ModalBody>


                </Modal>

                {/* View Video Modal */}
                <Modal
                    isOpen={modalClassic1}
                    toggle={toggleModalClassic1}
                    className="text-center"
                    size="lg"
                >
                    <ModalHeader
                        className="justify-content-start uppercase title text-left"
                        toggle={toggleModalClassic1}
                        tag="h6"
                    >
                        {userProfile.title}
                    </ModalHeader>
                    <ModalBody>
                        <table className="table table-bordered">
                            <tbody>
                                <tr className="border">
                                    <td className="border-0 font-weight-bold">Title</td>
                                    <td className="border-0">{userProfile.title}</td>
                                    <td className="border-0 font-weight-bold">Status</td>
                                    <td className="border-0">{userProfile.status === '1' ? 'Active' : 'InActive'}</td>

                                </tr>

                            </tbody>
                        </table>
                        <div className="container">
                            <video width="320" height="240" controls>
                                <source src={`https://api.eagleiq.online/${userProfile.src_file}`} type="video/mp4" />
                                <source src={`https://api.eagleiq.online/${userProfile.src_file}`} type="video/ogg" />
                                Your browser does not support the video tag.
                            </video>


                        </div>

                    </ModalBody>
                    {/* <div className="text-right">
                        <Button color="primary" onClick={toggleModalClassic}>
                            Next
                        </Button>
                    </div> */}

                </Modal>


                {/* Update Video Modal */}
                <Modal
                    isOpen={modalClassic2}
                    toggle={toggleModalClassic2}
                    className="text-center"
                >
                    <ModalHeader
                        className="justify-content-center uppercase title"
                        toggle={toggleModalClassic2}
                        tag="h4"
                    >
                        Update Video
                    </ModalHeader>
                    <ModalBody>
                        <Form onSubmit={e => updateVideoSubmit(e)} encType="multipart/form-data">
                            <FormGroup className="text-left">
                                <label>Video Title</label>

                                <Input name="title" placeholder={userProfile.title} value={title} onChange={onChange} />
                            </FormGroup>
                            <FormGroup className="text-left">
                                <label>Category</label>


                                <select name="category_id" value={category_id} id="" onChange={onChange} className="form-control">
                                    <option value="">Select</option>
                                    {
                                        modalClassic2 ? (
                                            videoCategories.map(category => (
                                                <option value={category.id}>{category.name}</option>
                                            ))

                                        ) : <option value="">No Categories Found</option>
                                    }
                                </select>
                            </FormGroup>
                            <FormGroup className="text-left">
                                <label>Status</label>


                                <select name="status" value={status} id="" onChange={onChange} className="form-control">
                                    <option value="">Select</option>
                                    <option value="1">Active</option>
                                    <option value="0">InActive</option>

                                </select>
                            </FormGroup>
                            <FormGroup className="form-file-upload form-file-simple text-left">

                                <Input
                                    type="text"
                                    className="inputFileVisible btn btn-info bg-info white-input-field text-white rounded"
                                    placeholder="Select Video"
                                    onClick={(e) => handleSingleFileInput(e)}
                                    defaultValue={src_file?.name}
                                />
                                <input
                                    type="file"
                                    className="inputFileHidden"
                                    style={{ zIndex: -1 }}
                                    ref={singleFileRef}
                                    // onChange={(e) => addSingleFile(e)}
                                    name="src_file"
                                    onChange={e => onChange1(e)}
                                    accept="video/mp4,video/x-m4v,video/*"

                                />
                            </FormGroup>

                            <FormGroup className="form-file-upload form-file-simple">

                                <Input
                                    type="text"
                                    className="inputFileVisible btn btn-info bg-info white-input-field text-white rounded"
                                    placeholder="Select Video Icon"
                                    onClick={(e) => handleSingleFileInput1(e)}
                                    defaultValue={icon?.name}
                                />
                                <input
                                    type="file"
                                    className="inputFileHidden"
                                    style={{ zIndex: -1 }}
                                    ref={singleFileRef1}
                                    // onChange={(e) => addSingleFile(e)}
                                    name="icon"
                                    onChange={e => onChange1(e)}

                                />
                            </FormGroup>
                            <div className="text-center">

                                {
                                    loadingSpinner ? (
                                        <div class="spinner-border text-info" role="status">
                                            <span class="sr-only">Loading...</span>
                                        </div>

                                    ) : (
                                        <Button color="info" type="submit" >
                                            Update Video
                                        </Button>
                                    )
                                }


                            </div>

                        </Form>
                    </ModalBody>


                </Modal>


                {/* Add Category Modal */}

                <Modal
                    isOpen={modalClassic3}
                    toggle={toggleModalClassic3}
                    className="text-center"
                    size="lg"
                >
                    <ModalHeader
                        className="justify-content-start uppercase title text-left"
                        toggle={toggleModalClassic3}
                        tag="h6"
                    >
                        Add Video Category
                    </ModalHeader>
                    <ModalBody>
                        <Form onSubmit={e => addCategorySubmit(e)} encType="multipart/form-data">
                            <Row>


                                <Col md="12" className="mb-2">
                                    <FormGroup className="text-left">
                                        <Label className="text-left font-weight-bold"> Category Name </Label>
                                        <Input type="text" value={name} name="name" onChange={onChange} />
                                    </FormGroup>
                                </Col>
                                <Col md="12" className="mb-2">
                                    <FormGroup className="text-left">
                                        <Label className="text-left font-weight-bold"> Description </Label>
                                        <Input type="text" value={description} name="description" onChange={onChange} />
                                    </FormGroup>
                                </Col>
                                <Col md="12" className="mb-2">
                                    <FormGroup className="text-left">
                                        <Label className="text-left font-weight-bold"> Status </Label>
                                        <select name="status" value={status} id="" className="form-control">
                                            <option value=""></option>
                                            <option value="1">Active</option>
                                            <option value="0">InActive</option>
                                        </select>
                                    </FormGroup>
                                </Col>





                            </Row>
                            <div className="text-center">
                                <Button color="info" type="submit"  >
                                    Add
                                </Button>
                            </div>

                        </Form>

                    </ModalBody>


                </Modal>
            </div>

        </>
    );
}

const mapStateToProps = state => ({
    allVideos: state.profileReducer.allVideos,
    videoCategories: state.profileReducer.videoCategories.categories,
    isLoading: state.profileReducer.isLoading,
    sweetAlert: state.profileReducer.sweetAlert,
    successMsg: state.profileReducer.updatedVideo.message,
    videoAddedMsg: state.profileReducer.newVideo.message,
    videoDeletedMsg: state.profileReducer.deletedVideo.message
})

export default connect(mapStateToProps, { getAllVideos, addNewVideo, getVideoCategories, updateVideo, deleteVideo, addVideoCategory, removeSweetAlert })(VideoTutorials);






















