import {
    USER_LOADED,
    AUTH_ERROR,
    LOGIN_FAIL,
    LOGIN_SUCCESS,
    LOGOUT,
    CHANGE_LOADING_AUTH,
    CHANGE_IS_AUTHENTICATED,
} from "../actions/types";
import setAuthToken from "../utils/setAuthToken";

const initialState = {
    token: localStorage.getItem('token'),
    isAuthenticated: null,
    user: null,
    loading: false,

}

export default function (state = initialState, { type, payload }) {
    switch (type) {
        case CHANGE_IS_AUTHENTICATED:
            return {
                ...state,
                isAuthenticated: payload
            }
        case CHANGE_LOADING_AUTH:
            return {
                ...state,
                loading: payload
            }
        case USER_LOADED:
            return {
                ...state,
                isAuthenticated: true,
                user: payload,
                loading: false
            }
        case LOGIN_SUCCESS:
            localStorage.setItem('token', payload.access_token)
            return {
                ...state,
                isAuthenticated: true,
                user: payload,
                loading: false,
            }
        case AUTH_ERROR:
        case LOGIN_FAIL:
        case LOGOUT:
            localStorage.removeItem('token')
            return {

                ...state,
                isAuthenticated: false,
                token: null,
                user: null,
                loading: false,
            }
        default:
            return state;
    }
}