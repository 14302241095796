import {
    addNewClient,
    deleteClient,
    getAllClients,
    removeSweetAlert,
    updateClient
} from "actions/profileActions";
import PanelHeader from "components/PanelHeader/PanelHeader.js";
import { postalCodeErrTxt, postalCodeRegex } from "config/constants";
import React, {
    useEffect, useState
} from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import BootstrapTable from "react-bootstrap-table-next";
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
    Search
} from "react-bootstrap-table2-toolkit";
import InputMask from 'react-input-mask';
import {
    connect
} from 'react-redux';
import {
    Link, useHistory
} from 'react-router-dom';
import {
    Button,
    Card,
    Col,
    Container,
    Form,
    FormGroup,
    Input, Label,
    Modal,
    ModalBody,
    ModalHeader,
    Row
} from "reactstrap";

const pagination = paginationFactory({
    page: 1,
    alwaysShowAllBtns: true,
    showTotal: true,
    withFirstAndLast: false,
    sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
        <div className="dataTables_length " id="datatable-basic_length">
            <label className="d-flex justify-content-start">
                Show{" "}
                {
                    <select
                        name="datatable-basic_length"
                        aria-controls="datatable-basic"
                        className="form-control"
                        onChange={(e) => onSizePerPageChange(e.target.value)}
                    >
                        <option value="10">10</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                    </select>
                }{" "}
                entries.
            </label>
        </div>
    ),
});


const { SearchBar } = Search;


const ManageClients = ({
    getAllClients,
    allClients,
    updateClient,
    deleteClient,
    removeSweetAlert,
    clientAddedMsg,
    isLoading,
    sweetAlert,
    deletedMsg
}) => {


    const [alert, setAlert] = React.useState(null);
    React.useEffect(() => {
        return function cleanup() {
            var id = window.setTimeout(null, 0);
            while (id--) {
                window.clearTimeout(id);
            }
        };
    }, []);

    useEffect(() => {
        if (sweetAlert) {
            successAlert()


        } else {
            hideAlert()
        }
    }, [sweetAlert])

    const successAlert = () => {
        setAlert(
            <SweetAlert
                success
                style={{ display: "block", marginTop: "-100px" }}
                title="Success!"
                onConfirm={() => hideAlert()}
                onCancel={() => hideAlert()}
                confirmBtnBsStyle="info"
            >
                {clientAddedMsg ? clientAddedMsg : deletedMsg ? deletedMsg : null}
            </SweetAlert>
        );
    }
    const hideAlert = () => {
        setAlert(null);
        removeSweetAlert()
    };


    const history = useHistory()
    const [modalClassic, setModalClassic] = React.useState(false);
    const toggleModalClassic = () => {
        setModalClassic(!modalClassic);
    };

    const [modalClassic1, setModalClassic1] = React.useState(false);
    const toggleModalClassic1 = () => {
        setModalClassic1(!modalClassic1);
    };
    const [modalClassic2, setModalClassic2] = React.useState(false);
    const toggleModalClassic2 = () => {
        setModalClassic2(!modalClassic1);
    };

    const [clients, setClients] = React.useState([]);

    const [userProfile, setUserProfile] = useState([])



    const setModal = (row) => {
        toggleModalClassic1()
        setUserProfile(row)
    }

    const setUpdateProfile = (row) => {
        toggleModalClassic()
        setUserProfile(row)
        setFormData({
            company_name: row?.company_name,
            fname: row?.fname,
            lname: row?.lname,
            email: row?.email,
            address: row?.address,
            post_code: row?.post_code,
            town: row?.town,
            phone_no: row?.phone_no,
            mobile_no: row?.mobile_no,
            password: row?.password,
            notes: row?.notes,
            division: row?.division,
            service_team: row?.service_team,
            invoice_date: row?.invoice_date,
            visits: row?.visits,
            file: row?.attachments,
            date_signed: row?.date_signed,
            status: row?.status,
        })
    }

    useEffect(() => {
        getAllClients();

    }, [])



    useEffect(() => {
        if (allClients.length !== 0) {
            setClients(allClients.clients)


        } else {
            return null
        }
    })


    var [formData, setFormData] = useState({
        company_name: '',
        fname: '',
        lname: '',
        email: '',
        address: '',
        post_code: '',
        town: '',
        phone_no: '',
        mobile_no: '',
        password: ''
    })
    const [errors, setErrors] = useState({})

    var {
        company_name,
        fname,
        lname,
        address,
        post_code,
        mobile_no,
        phone_no,
        email,
        town,
    } = formData;

    const onChange = e => {

        if (e.target.name == "post_code") {

            const result
                = postalCodeRegex
                    .test(
                        e.target.value
                    )

            if (!result) {
                setFormData((prev) => ({
                    ...prev,
                    [e.target.name]:
                        null
                }))
                setErrors((prev) => ({
                    ...prev,
                    [e.target.name]:
                        postalCodeErrTxt
                }))
            } else {
                setFormData((prev) => ({
                    ...prev,
                    [e.target.name]:
                        e.target.value
                }))
                setErrors((prev) => ({
                    ...prev,
                    [e.target.name]:
                        null
                }))

            }
        } else
            setFormData((prev) => ({
                ...prev,
                [e.target.name]:
                    e.target.value
            }))
    }

    const updateStaffSubmit = e => {
        e.preventDefault();

        if (company_name === '') {
            company_name = userProfile.company_name
        }
        if (fname === '') {
            fname = userProfile.fname
        }
        if (lname === '') {
            lname = userProfile.lname
        }
        if (address === '') {
            address = userProfile.address
        }
        if (post_code === '') {
            post_code = userProfile.post_code
        }
        if (mobile_no === '') {
            mobile_no = userProfile.mobile_no
        }
        if (phone_no === '') {
            phone_no = userProfile.phone_no
        }
        if (email === '') {
            email = userProfile.email
        }
        if (town === '') {
            town = userProfile.town
        }




        var formData = new FormData();
        formData.append("client_id", userProfile.id);
        formData.append("fname", fname);
        formData.append("company_name", company_name);
        formData.append("lname", lname);
        formData.append("address", address);
        formData.append("post_code", post_code);
        formData.append("mobile_no", mobile_no);
        formData.append("phone_no", phone_no);
        formData.append("email", email);
        formData.append("town", town);
        updateClient(formData, history)

        setFormData({
            company_name: '',
            fname: '',
            lname: '',
            email: '',
            address: '',
            post_code: '',
            town: '',
            phone_no: '',
            mobile_no: '',
            password: ''

        })

    }



    return (
        <>
            <PanelHeader size="sm" />
            <div className="content">

                {alert}

                <Row>
                    <div className="col">
                        <Card>

                            <Container>


                                <ToolkitProvider
                                    data={clients}
                                    keyField="id"
                                    bootstrap4

                                    columns={[
                                        {
                                            dataField: "fname",
                                            text: "Name",
                                            sort: true,
                                        },
                                        {
                                            dataField: "post_code",
                                            text: "Postal Code",
                                            sort: true,
                                        },
                                        {
                                            dataField: "email",
                                            text: "Email",
                                            sort: true,
                                        },
                                        {
                                            dataField: "phone_no",
                                            text: "Contact",
                                            sort: true,
                                        },


                                        {
                                            dataField: 'df2',
                                            isDummyField: true,
                                            text: 'Action',
                                            formatter: (cellContent, row) => {
                                                return (
                                                    <h5 className="d-flex justify-content-center">
                                                        <span className="btn btn-info btn-sm m-1" onClick={() => setModal(row)} > <i className="fas fa-eye"></i></span>
                                                        <span className="btn btn-info btn-sm m-1" onClick={() => setUpdateProfile(row)}> <i className="fas fa-edit"></i></span>
                                                        {
                                                            isLoading ? (
                                                                <div class="spinner-border text-info" role="status">
                                                                    <span class="sr-only">Loading...</span>
                                                                </div>
                                                            ) : (
                                                                <span className="btn btn-danger btn-sm m-1" onClick={() => deleteClient(row.id, history)} > <i className="fas fa-times"></i></span>
                                                            )
                                                        }


                                                    </h5>
                                                );

                                            }
                                        }


                                    ]}
                                    search
                                >
                                    {(props) => (
                                        <div className="py-4 ">
                                            <div
                                                id="datatable-basic_filter"
                                                className="dataTables_filter px-4 pb-1 d-flex  align-items-center justify-content-between"
                                            >

                                                <label>
                                                    Search:
                                                    <SearchBar
                                                        className="form-control"
                                                        placeholder=""
                                                        {...props.searchProps}
                                                    />
                                                </label>
                                                <Link className="btn btn-info btn-sm" to="/admin/add-client">Add New Client</Link>

                                            </div>
                                            <BootstrapTable
                                                {...props.baseProps}
                                                bootstrap4={true}
                                                pagination={pagination}
                                                bordered={false}
                                            />
                                        </div>
                                    )}
                                </ToolkitProvider>
                            </Container>
                        </Card>

                    </div>


                </Row>


                {/* View Profile Modal */}
                <Modal
                    isOpen={modalClassic1}
                    toggle={toggleModalClassic1}
                    className="text-center"
                    size="lg"
                >
                    <ModalHeader
                        className="justify-content-center uppercase title text-left"
                        toggle={toggleModalClassic1}
                        tag="h6"
                    >
                        {userProfile.fname + ' ' + userProfile.lname}
                    </ModalHeader>
                    <ModalBody>
                        <table className="table table-bordered">
                            <tbody>
                                <tr className="border">
                                    <td className="border-0 font-weight-bold">First Name</td>
                                    <td className="border-0">{userProfile.fname}</td>
                                    <td className="border-0 font-weight-bold">Last Name</td>
                                    <td className="border-0">{userProfile.lname}</td>

                                </tr>
                                <tr className="border">
                                    <td className="border-0 font-weight-bold">Email</td>
                                    <td className="border-0">{userProfile.email}</td>
                                    <td className="border-0 font-weight-bold">Address</td>
                                    <td className="border-0">{userProfile.address}</td>
                                </tr>
                                <tr className="border">
                                    <td className="border-0 font-weight-bold">Phone No</td>
                                    <td className="border-0">{userProfile.phone_no}</td>
                                    <td className="border-0 font-weight-bold">Postal Code</td>
                                    <td className="border-0">{userProfile.post_code}</td>
                                </tr>
                                <tr className="border">
                                    <td className="border-0 font-weight-bold">Company Name</td>
                                    <td className="border-0">{userProfile.company_name}</td>
                                    <td className="border-0 font-weight-bold">File</td>
                                    <td className="border-0">
                                        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                            {
                                                userProfile?.attachments?.map(data => (
                                                    <div
                                                        key={data?.id}
                                                        style={{
                                                            margin: '4px'
                                                        }}
                                                    >
                                                        {
                                                            data?.path?.includes('.pdf')
                                                                ?
                                                                <a target="_blank" href={`https://api.eagleiq.online/${data?.path}`}>See Attachment</a>
                                                                :
                                                                <img
                                                                    src={`https://api.eagleiq.online/${data?.path}`}
                                                                    alt="Profile"
                                                                    style={{ width: '80px', height: '80px' }}
                                                                />
                                                        }
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                    </ModalBody>
                    {/* <div className="text-right">
                        <Button color="primary" onClick={toggleModalClassic}>
                            Next
                        </Button>
                    </div> */}

                </Modal>

                {/* Update Client Modal */}

                <Modal
                    isOpen={modalClassic}
                    toggle={toggleModalClassic}
                    className="text-center"
                    size="lg"
                >
                    <ModalHeader
                        className="justify-content-start uppercase title text-left"
                        toggle={toggleModalClassic}
                        tag="h6"
                    >
                        {userProfile.fname + ' ' + userProfile.lname}
                    </ModalHeader>
                    <ModalBody>
                        <Form onSubmit={e => updateStaffSubmit(e)} encType="multipart/form-data">
                            <Row>

                                <Col md="6" className="mb-2">
                                    <FormGroup className="text-left">
                                        <Label className="text-left font-weight-bold">Client Name (The Company Name)</Label>
                                        <Input type="text" placeholder={userProfile.company_name} value={company_name} name="company_name" onChange={onChange} />
                                    </FormGroup>
                                </Col>
                                <Col md="6" className="mb-2">
                                    <FormGroup className="text-left">
                                        <Label className="text-left font-weight-bold"> First Name </Label>
                                        <Input type="text" placeholder={userProfile.fname} value={fname} name="fname" onChange={onChange} />
                                    </FormGroup>
                                </Col>
                                <Col md="6" className="mb-2">
                                    <FormGroup className="text-left">
                                        <Label className="text-left font-weight-bold"> Last Name </Label>
                                        <Input type="text" placeholder={userProfile.lname} value={lname} name="lname" onChange={onChange} />
                                    </FormGroup>
                                </Col>
                                <Col md="6" className="mb-2">
                                    <FormGroup className="text-left">
                                        <Label className="text-left font-weight-bold"> Email </Label>
                                        <Input type="email" placeholder={userProfile.email} value={email} name="email" onChange={onChange} />
                                    </FormGroup>
                                </Col>
                                <Col md="6" className="mb-2">
                                    <FormGroup className="text-left">
                                        <Label className="text-left font-weight-bold"> Address </Label>
                                        <Input type="text" placeholder={userProfile.address} value={address} name="address" onChange={onChange} />
                                    </FormGroup>
                                </Col>
                                <Col md="6" className="mb-2">
                                    <FormGroup className="text-left">
                                        <Label
                                            className="text-left font-weight-bold"
                                        >
                                            Postal Code
                                        </Label>
                                        <Input
                                            placeholder={userProfile.post_code}
                                            value={post_code}
                                            name="post_code"
                                            onChange={onChange}
                                        />
                                        <p
                                            style={{
                                                fontSize: '10px',
                                                color: '#D63343'
                                            }}
                                        >
                                            {errors?.post_code}
                                        </p>
                                    </FormGroup>
                                </Col>
                                <Col md="6" className="mb-2">
                                    <FormGroup className="text-left">
                                        <Label className="text-left font-weight-bold"> Town </Label>
                                        <Input type="text" placeholder={userProfile.town} value={town} name="town" onChange={onChange} />
                                    </FormGroup>
                                </Col>
                                <Col md="6" className="mb-2">
                                    <FormGroup className="text-left">
                                        <Label className="text-left font-weight-bold"> Phone No </Label>
                                        <Input
                                            placeholder={userProfile.phone_no}
                                            value={phone_no}
                                            name="phone_no"
                                            onChange={onChange}
                                            type="text"
                                            mask="+44 99 9999 9999"
                                            tag={InputMask}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md="6" className="mb-2">
                                    <FormGroup className="text-left">
                                        <Label className="text-left font-weight-bold"> Contact No </Label>
                                        <Input
                                            placeholder={userProfile.mobile_no}
                                            value={mobile_no}
                                            name="mobile_no"
                                            onChange={onChange}
                                            type="text"
                                            mask="+44 99 9999 9999"
                                            tag={InputMask}
                                        />

                                    </FormGroup>
                                </Col>



                            </Row>
                            <div className="text-center">
                                <Button color="info" type="submit" onClick={toggleModalClassic2} >
                                    Update
                                </Button>
                            </div>

                        </Form>

                    </ModalBody>


                </Modal>
            </div>

        </>
    );
}
const mapStateToProps = state => ({
    allClients: state.profileReducer.allClients,
    isLoading: state.profileReducer.isLoading,
    sweetAlert: state.profileReducer.sweetAlert,
    clientAddedMsg: state.profileReducer.newClient.message,
    deletedMsg: state.profileReducer.deletedClient.message,


})

export default connect(mapStateToProps, { getAllClients, updateClient, addNewClient, deleteClient, removeSweetAlert })(ManageClients);






















