
import Dashboard from "views/Dashboard/Dashboard.js";
import Buttons from "views/Components/Buttons.js";
import GridSystem from "views/Components/GridSystem.js";
import Panels from "views/Components/Panels.js";
import SweetAlert from "views/Components/SweetAlertPage.js";
import Notifications from "views/Components/Notifications.js";
import Icons from "views/Components/Icons.js";
import Typography from "views/Components/Typography.js";
import RegularForms from "views/Forms/RegularForms.js";
import ExtendedForms from "views/Forms/ExtendedForms.js";
import ValidationForms from "views/Forms/ValidationForms.js";
import AddNewTask from "views/Forms/AddNewTask";
import AddNewArea from "views/Forms/AddNewArea";
import CreateSite from "views/Forms/CreateSite";
import AddNewJob from "views/Forms/AddNewJob"
import Notes from "views/Forms/Notes"

import Wizard from "views/Forms/Wizard/Wizard.js";
import RegularTables from "views/Tables/RegularTables.js";
import ExtendedTables from "views/Tables/ExtendedTables.js";
import ReactTable from "views/Tables/ReactTable.js";
import ManageTasks from "views/Tables/ManageTasks";
import ManageJobs from "views/Tables/ManageJobs";
import ManageSites from "views/Tables/ManageSite"
import GoogleMaps from "views/Maps/GoogleMaps.js";
import FullScreenMap from "views/Maps/FullScreenMap.js";
import VectorMap from "views/Maps/VectorMap.js";
import Charts from "views/Charts/Charts.js";
// import Calendar from "views/Calendar/Calendar.js";
import Widgets from "views/Widgets/Widgets.js";
import EditProfile from "views/Pages/EditProfile.js";
import MyProfile from "views/Pages/MyProfile.js";
import TimelinePage from "views/Pages/TimelinePage.js";
import RTL from "views/Pages/RTL.js";
import PricingPage from "views/Pages/PricingPage.js";
import LoginPage from "views/Pages/LoginPage.js";
import RegisterPage from "views/Pages/RegisterPage.js";
import LockScreenPage from "views/Pages/LockScreenPage.js";
import ManageSite from "views/Tables/ManageSite";
import ManageClients from "views/Tables/ManageClients";
import ManagerDatabase from "views/Tables/ManagerDatabase"
import AddClient from "views/Forms/AddClient";
import AddManager from "views/Forms/AddManager"
import StockRequests from "views/Tables/StockRequests";
import VideoTutorials from "views/Tables/VideoTutorials"
import StaffDatabase from "views/Tables/StaffDatabase";
import Alert from "views/Tables/Alert"
import PrivateRoute from "routing/PrivateRoute";
import LeaveRequests from "views/Tables/LeaveRequests";
import TimeSheets from "views/Tables/TimeSheets";
import Test from "views/Tables/Test";
import AllStocks from "views/Tables/AllStocks";
import UpdateSite from "views/Forms/UpdateSite";

let routes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "now-ui-icons design_app",
    component: Dashboard,
    layout: "/admin",
    private: true
  },
  // {
  //   path: "/test",
  //   name: "Test",
  //   icon: "fas fa-thumbtack",
  //   component: Test,
  //   layout: "/admin",
  // },
  {
    path: "/manage-jobs",
    name: "Manage Jobs",
    icon: "fas fa-thumbtack",
    component: ManageJobs,
    layout: "/admin",
  },
  // {
  //   path: "/manager-database",
  //   name: "Manager Database",
  //   icon: "fas fa-users",
  //   component: ManagerDatabase,
  //   layout: "/admin",
  //   private: true
  // },
  {
    path: "/staff-database",
    name: "Team",
    icon: "fas fa-user-friends",
    component: StaffDatabase,
    layout: "/admin",
    private: true
  },
  {
    path: "/manage-clients",
    name: "Clients",
    icon: "fas fa-users",
    component: ManageClients,
    layout: "/admin",
    private: true
  },
  {
    path: "/manage-sites",
    name: " Sites",
    icon: "fas fa-building",
    component: ManageSite,
    layout: "/admin",
    private: true,
  },

  {
    collapse: true,
    path: "/pages",
    name: "Supplies",
    state: "openStocks",
    icon: "fas fa-cog",
    views: [
      {
        path: "/all-stocks",
        name: "All Supplies",
        mini: "S",
        component: AllStocks,
        layout: "/admin",
        private: true
      },
      {
        path: "/stock-requests",
        name: "Supplies Requests",
        short: "SR",
        mini: "SR",
        component: StockRequests,
        layout: "/admin",
      },


    ],
  },
  // {
  //   path: "/stock-requests",
  //   name: "Supplies",
  //   icon: "now-ui-icons objects_diamond",
  //   component: StockRequests,
  //   layout: "/admin",
  //   private: true
  // },

  {
    path: "/leave-requests",
    name: "Leave Requests",
    icon: "fas fa-user-friends",
    component: LeaveRequests,
    layout: "/admin",
    private: true
  },

  {
    path: "/time-sheets",
    name: "Timesheets",
    icon: "fas fa-clock",
    component: TimeSheets,
    layout: "/admin",
    private: true
  },

  {
    path: "/add-new-job",
    name: "Task Specifications",
    icon: "now-ui-icons objects_diamond",
    invisible: true,
    component: AddNewTask,
    layout: "/admin",
  },
  {
    path: "/add-new-area",
    name: "Task Specifications",
    icon: "now-ui-icons objects_diamond",
    invisible: true,
    component: AddNewArea,
    layout: "/admin",
    private: true,
  },


  // {
  //   path: "/manage-jobs",
  //   name: "Manage Jobs",
  //   icon: "fas fa-user-tie",
  //   component: ManageJobs,
  //   layout: "/admin",
  // },



  {
    path: "/VideoTutorials",
    name: "Knowledge Base",
    icon: "fas fa-book-open",
    component: VideoTutorials,
    layout: "/admin",
    private: true,
  },
  {
    path: "/create-site",
    name: "Create Site",
    icon: "now-ui-icons objects_diamond",
    invisible: true,
    component: CreateSite,
    layout: "/admin",
    private: true
  },
  {
    path: "/update-site",
    name: "Create Site",
    icon: "now-ui-icons objects_diamond",
    invisible: true,
    component: UpdateSite,
    layout: "/admin",
    private: true
  },
  {
    path: "/add-client",
    name: "Add Client",
    icon: "now-ui-icons objects_diamond",
    invisible: true,
    component: AddClient,
    layout: "/admin",
    private: true,
  },
  {
    path: "/add-manager",
    name: "Add Manager",
    icon: "now-ui-icons objects_diamond",
    invisible: true,
    component: AddManager,
    layout: "/admin",
    private: true,
  },
  {
    path: "/add-job",
    name: "Add New Job",
    icon: "now-ui-icons objects_diamond",
    invisible: true,
    component: AddNewJob,
    layout: "/admin",
    private: true
  },
  {
    path: "/edit-profile",
    name: "Profile",
    mini: "UP",
    component: EditProfile,
    invisible: true,
    layout: "/admin",
    private: true
  },
  {
    path: "/my-profile",
    name: "Profile",
    mini: "UP",
    component: MyProfile,
    invisible: true,
    layout: "/admin",
    private: true
  },
  {
    path: "/notes",
    name: "Notes",
    icon: "now-ui-icons objects_diamond",
    component: Notes,
    layout: "/admin",
    invisible: true,
  },
  {
    path: "/login-page",
    name: "Login Page",
    short: "Login",
    mini: "LP",
    component: LoginPage,
    invisible: true,
    layout: "/auth",
  },
  {
    path: "/register-page",
    name: "Register Page",
    short: "Register",
    mini: "RP",
    component: RegisterPage,
    layout: "/auth",
    invisible: true
  },
  {
    path: "/alert",
    name: "Alert",
    short: "Alert",
    mini: "RP",
    component: Alert,
    layout: "/admin",
    invisible: true
  },
  {
    collapse: true,
    path: "/pages",
    name: "Settings",
    state: "openPages",
    icon: "fas fa-cog",
    views: [
      {
        path: "/edit-profile",
        name: "Edit Profile",
        mini: "EP",
        component: EditProfile,
        layout: "/admin",
        private: true
      },
      {
        path: "/login-page",
        name: "Logout",
        short: "Login",
        mini: "LP",
        component: LoginPage,
        layout: "/auth",
        clickevent: true
      },


    ],
  },
  // {
  //   path: "/sweet-alert",
  //   name: "Sweet Alert",
  //   mini: "SA",
  //   component: SweetAlert,
  //   layout: "/admin",
  // },
  // {
  //   path: "/charts",
  //   name: "Charts",
  //   icon: "now-ui-icons business_chart-pie-36",
  //   component: Charts,
  //   layout: "/admin",
  // },
  // {
  //   path: "/calendar",
  //   name: "Calendar",
  //   icon: "now-ui-icons media-1_album",
  //   component: Calendar,
  //   layout: "/admin",
  // },
  // {
  //   collapse: true,
  //   path: "/pages",
  //   name: "Pages",
  //   state: "openPages",
  //   icon: "now-ui-icons design_image",
  //   views: [
  //     {
  //       path: "/timeline-page",
  //       name: "Timeline Page",
  //       mini: "TP",
  //       component: TimelinePage,
  //       layout: "/admin",
  //     },
  //     {
  //       path: "/user-page",
  //       name: "User Profile",
  //       mini: "UP",
  //       component: UserPage,
  //       layout: "/admin",
  //     },
  //     {
  //       path: "/rtl-support",
  //       name: "RTL Support",
  //       mini: "RS",
  //       component: RTL,
  //       layout: "/admin",
  //     },
  //     {
  //       path: "/register-page",
  //       name: "Register Page",
  //       short: "Register",
  //       mini: "RP",
  //       component: RegisterPage,
  //       layout: "/auth",
  //     },
  //     {
  //       path: "/login-page",
  //       name: "Login Page",
  //       short: "Login",
  //       mini: "LP",
  //       component: LoginPage,
  //       layout: "/auth",
  //     },
  //     {
  //       path: "/pricing-page",
  //       name: "Pricing Page",
  //       short: "Pricing",
  //       mini: "PP",
  //       component: PricingPage,
  //       layout: "/auth",
  //     },
  //     {
  //       path: "/lock-screen-page",
  //       name: "Lock Screen Page",
  //       short: "Lock",
  //       mini: "LSP",
  //       component: LockScreenPage,
  //       layout: "/auth",
  //     },
  //   ],
  // },
  // {
  //   collapse: true,
  //   path: "/components",
  //   name: "Components",
  //   state: "openComponents",
  //   icon: "now-ui-icons education_atom",
  //   views: [
  //     {
  //       path: "/buttons",
  //       name: "Buttons",
  //       mini: "B",
  //       component: Buttons,
  //       layout: "/admin",
  //     },
  //     {
  //       path: "/grid-system",
  //       name: "Grid System",
  //       mini: "GS",
  //       component: GridSystem,
  //       layout: "/admin",
  //     },
  //     {
  //       path: "/panels",
  //       name: "Panels",
  //       mini: "P",
  //       component: Panels,
  //       layout: "/admin",
  //     },
  //     {
  //       path: "/sweet-alert",
  //       name: "Sweet Alert",
  //       mini: "SA",
  //       component: SweetAlert,
  //       layout: "/admin",
  //     },
  //     {
  //       path: "/notifications",
  //       name: "Notifications",
  //       mini: "N",
  //       component: Notifications,
  //       layout: "/admin",
  //     },
  //     {
  //       path: "/icons",
  //       name: "Icons",
  //       mini: "I",
  //       component: Icons,
  //       layout: "/admin",
  //     },
  //     {
  //       path: "/typography",
  //       name: "Typography",
  //       mini: "T",
  //       component: Typography,
  //       layout: "/admin",
  //     },
  //   ],
  // },
  // {
  //   collapse: true,
  //   path: "/forms",
  //   name: "Forms",
  //   state: "openForms",
  //   icon: "now-ui-icons design_bullet-list-67",
  //   views: [
  //     {
  //       path: "/regular-forms",
  //       name: "Regular Forms",
  //       mini: "RF",
  //       component: RegularForms,
  //       layout: "/admin",
  //     },
  //     {
  //       path: "/extended-forms",
  //       name: "Extended Forms",
  //       mini: "EF",
  //       component: ExtendedForms,
  //       layout: "/admin",
  //     },
  //     {
  //       path: "/validation-forms",
  //       name: "Validation Forms",
  //       mini: "VF",
  //       component: ValidationForms,
  //       layout: "/admin",
  //     },
  //     {
  //       path: "/wizard",
  //       name: "Wizard",
  //       mini: "W",
  //       component: Wizard,
  //       layout: "/admin",
  //     },
  //   ],
  // },
  // {
  //   collapse: true,
  //   path: "/tables",
  //   name: "Tables",
  //   state: "openTables",
  //   icon: "now-ui-icons files_single-copy-04",
  //   views: [
  //     {
  //       path: "/regular-tables",
  //       name: "Regular Tables",
  //       mini: "RT",
  //       component: RegularTables,
  //       layout: "/admin",
  //     },
  //     {
  //       path: "/extended-tables",
  //       name: "Extended Tables",
  //       mini: "ET",
  //       component: ExtendedTables,
  //       layout: "/admin",
  //     },
  //     {
  //       path: "/react-table",
  //       name: "React Table",
  //       mini: "RT",
  //       component: ReactTable,
  //       layout: "/admin",
  //     },
  //   ],
  // },
  // {
  //   collapse: true,
  //   path: "/maps",
  //   name: "Maps",
  //   state: "openMaps",
  //   icon: "now-ui-icons location_pin",
  //   views: [
  //     {
  //       path: "/google-maps",
  //       name: "Google Maps",
  //       mini: "GM",
  //       component: GoogleMaps,
  //       layout: "/admin",
  //     },
  //     {
  //       path: "/full-screen-maps",
  //       name: "Full Screen Map",
  //       mini: "FSM",
  //       component: FullScreenMap,
  //       layout: "/admin",
  //     },
  //     {
  //       path: "/vector-maps",
  //       name: "Vector Map",
  //       mini: "VM",
  //       component: VectorMap,
  //       layout: "/admin",
  //     },
  //   ],
  // },

];

export default routes;
