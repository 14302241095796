
import React, { useEffect, useState, useRef } from "react";
import Select from "react-select";
import { Link, useHistory } from 'react-router-dom'
import Datetime from "react-datetime";
import Creatable, { makeCreatableSelect } from 'react-select/creatable';
import { Editor } from '@tinymce/tinymce-react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import CreatableSelect from 'react-select/creatable';
import { Multiselect } from 'multiselect-react-dropdown';
import { connect } from 'react-redux';
import { getAllClients, getAllSites, getAllStaff, addNewJob, uploadAnyFile, getAllManagers } from "actions/profileActions";

// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    Label,
    FormGroup,
    Form,
    Input,
    FormText,
    Row,
    Col,
    TabContent,
    TabPane,
    Nav,
    NavItem,
    NavLink,
} from "reactstrap";

// core components
import PanelHeader from "components/PanelHeader/PanelHeader.js";



const AddNewJob = ({ getAllClients, allClients, getAllSites, allSites, getAllStaff, allStaff, addNewJob, uploadedFile, uploadAnyFile, allManagers, getAllManagers }) => {
    const history = useHistory();

    // Set Page
    const [staff, setStaff] = useState('')
    const [managers, setManagers] = useState('')

    useEffect(() => {
        getAllClients()
        getAllSites()
        getAllStaff()
        getAllManagers()
        setStaff(allStaff.staff)
        setManagers(allManagers.managers)

    }, [])

    // Form Values
    const [token, setToken] = useState(localStorage.getItem('token'))
    const [title, setTitle] = useState('')
    const [company_id, setcompany_id] = useState('1')
    const [site_id, setsite_id] = useState('')
    const [client_id, setclient_id] = useState('')
    const [manager_id, setmanager_id] = useState('')
    const [start_date, setStartDate] = useState('')
    const [summary, setSummary] = useState('')
    const [employees, setEmployees] = useState([])

    const [selectedEmployees, setSelectedEmployees] = useState('')
    const onSelect = (selectedItem, selectedList) => {
        setSelectedEmployees(selectedItem)
    }



    // ------------------ Tabs ----------------------------------------------------------------

    // Active Tabs
    const [hTabs, sethTabs] = useState('1');
    const [editMode, setEditMode] = useState(false);
    const [toggleView, setToggleView] = useState('0')

    const hideInput = (title) => {
        if (title === '') {
            window.alert('Title Cannot be Empty')
            setEditMode(true)
        } else {
            setEditMode(false)


        }

    }
    // Set Tasks
    const [tasks, setTasks] = useState([
        {
            task: '',
            day_of: ''
        }
    ])

    // Set Areas
    const [areas, setAreas] = useState(
        [
            {

                area_id: '1',
                area_title: 'Area 1',
                area_attachement: '',
                tasks: tasks

            },


        ])
    // Add New Area
    const setNewArea = () => {
        setAreas([...areas, {
            area_id: `${areas.length++ + 1}`,
            area_title: `Area ${areas.length++}`,
            tasks: [{
                task: '',
                day_of: ''
            }]
        }])

    }




    // Setting up form


    // OnChange
    const onChange = index => e => {
        console.log('index', index)
        console.log('propert', e.target.name)

        let newArr = [...areas]
        newArr[index][e.target.name] = e.target.value;
        setAreas(newArr)
    }

    // Set Attachement
    const setAttachement = (e) => {
        let newArr = [...areas]
        newArr[0][e.target.name] = e.target.files[0];
        setAreas(newArr)
        console.log(areas)


    }


    const editorRef = useRef(null); //Tiny MCE
    const changeTask = (content, index, index1) => {
        console.log(content)

        let newArr = [...areas]

        newArr[index]['tasks'][index1]['task'] = content;

        setAreas(newArr)


    }
    const changeDayOf = (index, index1) => e => {

        let newArr = [...areas]
        newArr[index]['tasks'][index1]['day_of'] = e.target.value;

        setAreas(newArr)

    }
    const addNewTask = (index) => {
        console.log(index)
        const newObj = {
            task: '',
            day_of: ''
        }
        let newArr = [...areas]
        newArr[index]['tasks'].push(newObj)
        setAreas(newArr)
    }



    const singleFileRef = React.useRef();
    const handleSingleFileInput = (e) => {
        singleFileRef.current.click(e);
    };


    // ------------------ Tabs End ----------------------------------------------------------------


    // On Submit




    const addJobSubmit = (e) => {
        e.preventDefault()
        selectedEmployees.map(employee => (employees.push({ id: employee.id })))

        var raw = JSON.stringify({
            "token": token,
            "title": title,
            "company_id": company_id,
            "manager_id": manager_id,
            "site_id": site_id,
            "client_id": client_id,
            "start_date": start_date,
            "summary": summary,
            "areas": areas,
            "employees": employees
        })
        console.log(raw)
        addNewJob(raw, history)





    }

    return (
        <>
            <PanelHeader size="sm" />
            <div className="content" >
                <Row >
                    <Col md={8} className="ml-auto mr-auto">




                        <Card >
                            <Form onSubmit={e => addJobSubmit(e)} encType="multipart/form-data" className="p-4">

                                {
                                    toggleView === '0' ? (
                                        <React.Fragment>
                                            <div className="d-flex align-items-center justify-content-between">
                                                <h5>Add New Job</h5>

                                            </div>
                                            <label>Job Title</label>
                                            <FormGroup>
                                                <Input type="text" onChange={e => setTitle(e.target.value)} />
                                            </FormGroup>
                                            <label>Client</label>
                                            <FormGroup>
                                                <select value={client_id} name="client_id" onChange={e => setclient_id(e.target.value)} className="form-control">
                                                    <option value=""></option>
                                                    {
                                                        allClients.clients.map(client => (
                                                            <option value={client.id}>{client.fname + ' ' + client.lname}</option>
                                                        ))
                                                    }
                                                </select>
                                            </FormGroup>
                                            <label>Manager</label>
                                            <FormGroup>
                                                <select value={manager_id} name="manager_id" onChange={e => setmanager_id(e.target.value)} className="form-control">
                                                    <option value=""></option>
                                                    {
                                                        allManagers.managers.map(manager => (
                                                            <option value={manager.id}>{manager.fname + ' ' + manager.lname}</option>
                                                        ))
                                                    }
                                                </select>
                                            </FormGroup>
                                            <label>Site</label>
                                            <FormGroup>
                                                <select value={site_id} name="site_id" onChange={e => setsite_id(e.target.value)} className="form-control">
                                                    <option value=""></option>
                                                    {
                                                        allSites.sites.map(site => (
                                                            <option value={site.id}>{site.site_name}</option>
                                                        ))
                                                    }
                                                </select>
                                            </FormGroup>

                                            <div className="text-right">
                                                <Button onClick={() => setToggleView('1')} color="info">Next</Button>


                                            </div>

                                        </React.Fragment>
                                    ) : toggleView === '1' ? (
                                        <React.Fragment>
                                            <div className="d-flex align-items-center justify-content-between">
                                                <h5>  <i className="fas fa-arrow-circle-left text-info" style={{ cursor: 'pointer', fontSize: '18px' }} onClick={() => setToggleView('0')}></i> Add New Job</h5>

                                                <input type="text" type="file" name="area_attachement" className="" accept=".pdf,.doc" onChange={e => setAttachement(e)} />
                                            </div>
                                            <Row className="mt-5">

                                                <Col lg={12} md={12} xs={12} className=" ">

                                                    <Nav pills className="nav-pills-info">
                                                        {
                                                            areas.map((area, index) => (
                                                                <NavItem>
                                                                    <NavLink className={hTabs === `${area.area_id}` ? "active" : ""} onClick={() => sethTabs(`${area.area_id}`)} onDoubleClick={() => setEditMode(true)} onBlur={() => hideInput(area.area_title)}>
                                                                        {
                                                                            editMode && hTabs === area.area_id ? (<input value={area.area_title} name="area_title" required className="form-control border border-info bg-transparent text-white" onChange={onChange(index)} />) : area.area_title
                                                                        }

                                                                    </NavLink>
                                                                </NavItem>


                                                            ))
                                                        }
                                                        <i className="fas fa-plus-circle text-info align-items-center mt-3" onClick={setNewArea} style={{ cursor: 'pointer' }}></i>


                                                    </Nav>

                                                    <TabContent activeTab={hTabs} className="tab-space">
                                                        {
                                                            areas.map((area, index) => (
                                                                <TabPane tabId={`${area.area_id}`} style={{ height: '300px', position: 'relative', overflowX: 'hidden', overflowY: 'auto' }}>
                                                                    {
                                                                        area.tasks.map((task, index1) => (

                                                                            <div className="row my-3">
                                                                                <div className="col-md-8">
                                                                                    {/* <input className="form-control" required onChange={changeTask(index, index1)} /> */}
                                                                                    <Editor
                                                                                        onInit={(evt, editor) => editorRef.current = editor}
                                                                                        apiKey='ig44t7sd2d9z3w3i5xaq9rrm9x3gvwhujfrxt0y3wlx8uxya'
                                                                                        initialValue=""
                                                                                        required
                                                                                        init={{
                                                                                            height: 200,
                                                                                            menubar: false,
                                                                                            plugins: [
                                                                                                'advlist autolink lists link image charmap print preview anchor',
                                                                                                'searchreplace visualblocks code fullscreen',
                                                                                                'insertdatetime media table paste code help wordcount'
                                                                                            ],
                                                                                            toolbar: 'undo redo | formatselect | ' +
                                                                                                'bold italic backcolor | alignleft aligncenter ' +
                                                                                                'alignright alignjustify | bullist numlist outdent indent | ' +
                                                                                                'removeformat | help',
                                                                                            content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'

                                                                                        }}
                                                                                        onEditorChange={(content) => changeTask(content, index, index1)}
                                                                                    />
                                                                                    {/* <textarea name="" className="basic-example" required onChange={changeTask(index, index1)} cols="30" rows="10"></textarea> */}
                                                                                </div>
                                                                                <div className="col-md-4">


                                                                                    <input type="text" list="days" required className="form-control" onChange={changeDayOf(index, index1)} placeholder="Select Day" />
                                                                                    <datalist id="days" >
                                                                                        <option>Monday</option>
                                                                                        <option>Tuesday</option>
                                                                                        <option>Wednesday</option>
                                                                                        <option>Thursday</option>
                                                                                        <option>Friday</option>
                                                                                        <option>Saturday</option>
                                                                                        <option>Sunday</option>
                                                                                    </datalist>


                                                                                </div>




                                                                            </div>


                                                                        ))
                                                                    }
                                                                    <i className="fas fa-plus-circle" onClick={() => addNewTask(index)}></i>


                                                                </TabPane>



                                                            ))
                                                        }


                                                    </TabContent>
                                                </Col>
                                            </Row>
                                            <div className="text-right">
                                                <Link onClick={() => setToggleView('2')} className="btn btn-info">Nextttt</Link>


                                            </div>
                                        </React.Fragment>

                                    ) : toggleView === '2' ? (
                                        <React.Fragment>
                                            <div className="d-flex align-items-center justify-content-between">
                                                <h5>  <i className="fas fa-arrow-circle-left text-info" style={{ cursor: 'pointer', fontSize: '18px' }} onClick={() => setToggleView('1')}></i> Add New Job</h5>


                                            </div>

                                            <Row>
                                                <Col md="6">
                                                    <label htmlFor="">Client</label>
                                                    <FormGroup>
                                                        <Input readOnly defaultValue={client_id} />

                                                    </FormGroup>
                                                </Col>
                                                <Col md="6">
                                                    <label htmlFor="">Site</label>
                                                    <FormGroup>
                                                        <Input readOnly defaultValue={site_id} />

                                                    </FormGroup>
                                                </Col>

                                                <Col md="6">
                                                    <label htmlFor="">Start Date</label>
                                                    <FormGroup>
                                                        <input type="date" onChange={e => setStartDate(e.target.value)} className="form-control" />

                                                    </FormGroup>
                                                </Col>
                                                <Col md="6">
                                                    <label>Employees</label>
                                                    <FormGroup>
                                                        <Multiselect
                                                            options={staff}
                                                            isObject={true}
                                                            required
                                                            displayValue="fname"
                                                            showCheckbox={true}
                                                            closeOnSelect={false}
                                                            avoidHighlightFirstOption={true}
                                                            showArrow={true}
                                                            onSelect={onSelect}
                                                            onRemove={onSelect}

                                                        />
                                                    </FormGroup>
                                                </Col>

                                                <Col md="12">
                                                    <label>Summary</label>
                                                    <FormGroup>
                                                        <textarea name="summary" value={summary} onChange={e => setSummary(e.target.value)} id="" cols="30" rows="10" className="form-control border rounded">

                                                        </textarea>
                                                    </FormGroup>
                                                </Col>


                                            </Row>

                                            <div className="text-right">
                                                <Button type="submit" color="info">Create Job</Button>


                                            </div>
                                        </React.Fragment>


                                    )



                                        : null
                                }









                            </Form>
                        </Card>
                    </Col>
                </Row>

            </div>
        </>
    );
}

const mapStateToProps = state => ({
    allClients: state.profileReducer.allClients,
    allSites: state.profileReducer.allSites,
    allStaff: state.profileReducer.allStaff,
    uploadedFile: state.profileReducer.uploadedFile,
    allManagers: state.profileReducer.allManagers
})

export default connect(mapStateToProps, { getAllClients, getAllSites, getAllStaff, addNewJob, uploadAnyFile, getAllManagers })(AddNewJob);
