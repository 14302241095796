
import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import Select from "react-select";
import { Link, useHistory } from 'react-router-dom'
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import { connect, useDispatch } from 'react-redux';
import { getAllSites, deleteSite, updateSite, addNewSite, getUserProfile, getAllClients, removeSweetAlert } from "actions/profileActions";
import SweetAlert from "react-bootstrap-sweetalert";
import InputMask from 'react-input-mask';

import {

    Card,
    Container, Col,
    Row, Modal,
    ModalBody,
    ModalFooter,
    ModalHeader, Button, FormGroup, CardHeader, Form, Input, Label, UncontrolledTooltip

} from "reactstrap";
// core components
import PanelHeader from "components/PanelHeader/PanelHeader.js";
import { emptyUserProfile } from "actions/profileActions";
import { GET_SINGLE_SITE_DETAIL } from "actions/types";

const pagination = paginationFactory({
    page: 1,
    alwaysShowAllBtns: true,
    showTotal: true,
    withFirstAndLast: false,
    sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
        <div className="dataTables_length " id="datatable-basic_length">
            <label className="d-flex justify-content-start">
                Show{" "}
                {
                    <select
                        name="datatable-basic_length"
                        aria-controls="datatable-basic"
                        className="form-control"
                        onChange={(e) => onSizePerPageChange(e.target.value)}
                    >
                        <option value="10">10</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                    </select>
                }{" "}
                entries.
            </label>
        </div>
    ),
});


const RegularMap = () => {
    const mapRef = React.useRef(null);
    React.useEffect(() => {
        let google = window.google;
        let map = mapRef.current;
        let lat = "40.748817";
        let lng = "-73.985428";
        const myLatlng = new google.maps.LatLng(lat, lng);
        const mapOptions = {
            zoom: 13,
            center: myLatlng,
            scrollwheel: false,
        };

        map = new google.maps.Map(map, mapOptions);

        const marker = new google.maps.Marker({
            position: myLatlng,
            map: map,
            animation: google.maps.Animation.DROP,
            title: "Set Location",
        });


        const contentString =
            '<div class="info-window-content"><h2>Location</h2>' +
            "<p>Set Location</p></div>";

        const infowindow = new google.maps.InfoWindow({
            content: contentString,
        });

        google.maps.event.addListener(marker, "click", function () {
            infowindow.open(map, marker);



        });
    }, []);
    return (
        <>
            <div style={{ height: `280px` }} ref={mapRef} />
        </>
    );
};

const { SearchBar } = Search;


const ManageSite = ({ getAllSites, emptyUserProfile, allSites, allClients, deleteSite, updateSite, getUserProfile, user, getAllClients, removeSweetAlert, isLoading, deletedSite, updatedSite, addedSite, sweetAlert }) => {

    // Sweet Alert

    const dispatch = useDispatch()

    const [alert, setAlert] = React.useState(null);
    React.useEffect(() => {
        return function cleanup() {
            var id = window.setTimeout(null, 0);
            while (id--) {
                window.clearTimeout(id);
            }
        };
    }, []);

    useEffect(() => {
        if (sweetAlert) {
            successAlert()


        } else {
            hideAlert()
        }
    }, [sweetAlert])

    const successAlert = () => {
        setAlert(
            <SweetAlert
                success
                style={{ display: "block", marginTop: "-100px" }}
                title="Success!"
                onConfirm={() => hideAlert()}
                onCancel={() => hideAlert()}
                confirmBtnBsStyle="info"
            >
                {deletedSite ? deletedSite : updatedSite ? updatedSite : addedSite ? addedSite : null}
            </SweetAlert>
        );
    }
    const hideAlert = () => {
        setAlert(null);
        removeSweetAlert()
    };



    const history = useHistory()
    const [modalClassic, setModalClassic] = React.useState(false);
    const toggleModalClassic = () => {
        setModalClassic(!modalClassic);
    };
    const [modalClassic1, setModalClassic1] = React.useState(false);
    const toggleModalClassic1 = () => {
        setModalClassic1(!modalClassic1);
    };
    const [modalClassic2, setModalClassic2] = React.useState(false);
    const toggleModalClassic2 = () => {
        setModalClassic2(!modalClassic2);
    };

    const [sites, setSites] = useState([]);


    // Set Table

    useEffect(() => {
        getAllSites();


    }, [])

    useEffect(() => {
        if (allSites.length !== 0) {
            setSites(allSites.sites)


        } else {
            return null
        }
    })

    // View Site

    const [siteProfile, setSiteProfile] = useState([])
    const setModal = (row) => {
        toggleModalClassic()
        setSiteProfile(row)
    }

    // Update Site

    const setUpdateSite = (row) => {
        toggleModalClassic2()
        setSiteProfile(row)
    }

    const [formData, setFormData] = useState({

        client_id: '',
        company_id: '1',
        site_name: '',
        emergency_contact_no: '',
        emergency_phone_no: '',
        out_of_hours: '',
        alarm_call: '',
        lat: '-25.60102173347362',
        lan: '131.77642008686067',
        location: ''
    })


    var { client_id, company_id, site_name, emergency_contact_no, out_of_hours, emergency_phone_no, alarm_call, lat, lan, location } = formData;
    const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value })

    useEffect(() => {
        getAllClients()
    }, [])

    useEffect(() => {
        if (client_id === '' || client_id === null || client_id === undefined) {
            return null
        } else {
            var formdata = new FormData();
            formdata.append("id", client_id);
            getUserProfile(formdata)
        }
    }, [client_id])


    const onSubmit = e => {
        e.preventDefault();

        if (site_name === '') {
            site_name = siteProfile.site_name
        }
        if (emergency_contact_no === '') {
            emergency_contact_no = siteProfile.emergency_contact_no
        }
        if (emergency_phone_no === '') {
            emergency_phone_no = siteProfile.emergency_phone_no
        }
        if (out_of_hours === '') {
            out_of_hours = siteProfile.out_of_hours
        }
        if (alarm_call === '') {
            alarm_call = siteProfile.alarm_call
        }
        if (lat === '') {
            lat = siteProfile.lat
        }
        if (lan === '') {
            lan = siteProfile.lan
        }
        if (location === '') {
            location = siteProfile.location
        }


        var formData = new FormData();
        formData.append("site_id", siteProfile.id);
        formData.append("client_id", client_id);
        formData.append("company_id", company_id);
        formData.append("site_name", site_name);
        formData.append("emergency_contact_no", emergency_contact_no);
        formData.append("emergency_phone_no", emergency_phone_no);
        formData.append("out_of_hours", out_of_hours);
        formData.append("alarm_call", alarm_call);
        formData.append("lat", lat);
        formData.append("lan", lan);
        formData.append("location", location);  
        updateSite(formData, history)

        setFormData({
            client_id: '',
            company_id: '1',
            site_name: '',
            emergency_contact_no: '',
            emergency_phone_no: '',
            out_of_hours: '',
            alarm_call: '',
            lat: '-25.60102173347362',
            lan: '131.77642008686067',
            location: ''

        })


    }



    function iconFormatter(cell, row) {

        if (row.client) {
            return (
                <span>  {row.client.fname + ' ' + row.client.lname} </span>
            );
        } else {
            return null
        }


    }


    return (
        <>
            <PanelHeader size="sm" />
            <div className="content">
                {alert}


                <Row>
                    <div className="col">
                        <Card>

                            <Container>

                                <ToolkitProvider
                                    data={sites}
                                    keyField="id"
                                    bootstrap4

                                    columns={[
                                        {
                                            dataField: "site_name",
                                            text: "Site",
                                            sort: true,
                                        },
                                        {
                                            dataField: "client.fname",
                                            text: "Client",
                                            sort: true,
                                            formatter: iconFormatter


                                        },
                                        // {
                                        //     dataField: "company_id",
                                        //     text: "Company",
                                        //     sort: true,
                                        // },
                                        {
                                            dataField: "emergency_contact_no",
                                            text: "Contact",
                                            sort: true,
                                        },
                                        {
                                            dataField: "created_at",
                                            text: "Date Created",
                                            sort: true,
                                        },



                                        {
                                            dataField: 'df2',
                                            isDummyField: true,
                                            text: 'Action',
                                            formatter: (cellContent, row) => {
                                                return (
                                                    <h5 className="">
                                                        <span className="btn btn-info btn-sm m-1" onClick={() => setModal(row)} > <i className="fas fa-eye"></i></span>
                                                        <span className="btn btn-info btn-sm m-1" onClick={async () => {
                                                            await dispatch({type: GET_SINGLE_SITE_DETAIL, payload: row})
                                                            history.push('/admin/update-site')
                                                            }} > <i className="fas fa-edit"></i></span>

                                                        {
                                                            isLoading ? (
                                                                <div class="spinner-border text-primary" role="status">
                                                                    <span class="sr-only">Loading...</span>
                                                                </div>
                                                            ) : (
                                                                <span className="btn btn-danger btn-sm m-1" onClick={() => deleteSite(row.id, history)} > <i className="fas fa-times"></i></span>
                                                            )
                                                        }

                                                    </h5>
                                                );

                                            }
                                        }


                                    ]}
                                    search
                                >
                                    {(props) => (
                                        <div className="py-4 ">
                                            <div
                                                id="datatable-basic_filter"
                                                className="dataTables_filter px-4 pb-1 d-flex  align-items-center justify-content-between"
                                            >

                                                <label>
                                                    Search:
                                                    <SearchBar
                                                        className="form-control"
                                                        placeholder=""
                                                        {...props.searchProps}
                                                    />
                                                </label>
                                                <div
                                                    className="btn btn-info btn-sm"
                                                    style={{
                                                        cursor: 'pointer'
                                                    }}
                                                    onClick={() => {
                                                        history.push('/admin/create-site')
                                                        emptyUserProfile()
                                                    }}
                                                >
                                                    Add New Site
                                                </div>

                                            </div>
                                            <BootstrapTable
                                                {...props.baseProps}
                                                bootstrap4={true}
                                                pagination={pagination}
                                                bordered={false}
                                            />
                                        </div>
                                    )}
                                </ToolkitProvider>
                            </Container>
                        </Card>

                    </div>


                </Row>
                {/* View Profile Modal */}
                <Modal
                    isOpen={modalClassic}
                    toggle={toggleModalClassic}
                    className="text-center"
                    size="lg"
                >
                    <ModalHeader
                        className="justify-content-center uppercase title text-left"
                        toggle={toggleModalClassic}
                        tag="h6"
                    >
                        {siteProfile.site_name}
                    </ModalHeader>
                    <ModalBody>
                        <table className="table table-bordered">
                            <tbody>
                                <tr className="border">
                                    <td className="border-0 font-weight-bold">Client Name</td>
                                    <td className="border-0">{`${siteProfile.client?.fname} ${siteProfile.client?.lname}`}</td>
                                    <td className="border-0 font-weight-bold">Emergency Contact Name</td>
                                    <td className="border-0">{siteProfile.emergency_contact_no}</td>

                                </tr>
                                <tr className="border">
                                    <td className="border-0 font-weight-bold">Out of Hours</td>
                                    <td className="border-0">{siteProfile.out_of_hours}</td>
                                    <td className="border-0 font-weight-bold">Alarm Call</td>
                                    <td className="border-0">{siteProfile.alarm_call}</td>
                                </tr>
                                <tr className="border">
                                    <td className="border-0 font-weight-bold">Location</td>
                                    <td className="border-0">{siteProfile.location}</td>
                                    <td className="border-0 font-weight-bold">Status</td>
                                    <td className="border-0">{siteProfile.status === '0' ? 'InActive' : 'Active'}</td>
                                </tr>
                                <tr className="border">
                                    <td className="border-0 font-weight-bold">Address</td>
                                    <td className="border-0">{siteProfile.address}</td>
                                    <td className="border-0 font-weight-bold">Post Code</td>
                                    <td className="border-0">{siteProfile?.client?.post_code}</td>
                                </tr>
                                <tr className="border">
                                    <td className="border-0 font-weight-bold">Town</td>
                                    <td className="border-0">{siteProfile.town}</td>
                                    <td className="border-0 font-weight-bold">Emergency Phone #</td>
                                    <td className="border-0">{siteProfile.emergency_phone_no}</td>
                                </tr>
                                <tr className="border">
                                    <td className="border-0 font-weight-bold">Site Post Code</td>
                                    <td className="border-0">{siteProfile.post_code}</td>
                                    <td className="border-0 font-weight-bold">File</td>
                                    <td className="border-0">
                                        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                            {
                                                siteProfile?.attachments?.map(data => (
                                                    <div
                                                        key={data?.id}
                                                        style={{
                                                            margin: '4px'
                                                        }}
                                                    >
                                                        {
                                                            data?.path?.includes('.pdf')
                                                                ?
                                                                <a target="_blank" href={`https://api.eagleiq.online/${data?.path}`}>See Attachment</a>
                                                                :
                                                                <img
                                                                    src={`https://api.eagleiq.online/${data?.path}`}
                                                                    alt="Profile"
                                                                    style={{ width: '80px', height: '80px' }}
                                                                />
                                                        }
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                    </ModalBody>
                    {/* <div className="text-right">
                        <Button color="primary" onClick={toggleModalClassic}>
                            Next
                        </Button>
                    </div> */}

                </Modal>


                {/* Update Site Modal */}

                <Modal
                    isOpen={modalClassic2}
                    toggle={toggleModalClassic2}
                    className="text-center"
                    size="lg"
                >
                    <ModalHeader
                        className="justify-content-start uppercase title text-left"
                        toggle={toggleModalClassic2}
                        tag="h6"
                    >
                        {siteProfile.site_name}
                    </ModalHeader>
                    <ModalBody>
                        <Form onSubmit={e => onSubmit(e)} encType="multipart/form-data">
                            <Row>
                                <Col md="6">
                                    <label>Company Name</label>
                                    <FormGroup>
                                        <select name="client_id" required className="form-control" type="select" value={siteProfile.client_id} onChange={(e) => onChange(e)}>
                                            <option value="" >Select</option>

                                            {
                                                allClients.clients.map(client => (
                                                    <option value={client.id}>{client.company_name}</option>
                                                ))
                                            }
                                        </select>
                                    </FormGroup>
                                </Col>
                                <Col md="6">
                                    <label>Contact</label>
                                    <FormGroup>
                                        <Input type="number" value={user ? user.phone_no : ''} readOnly />
                                    </FormGroup>
                                </Col>
                                <Col md="12">
                                    <label>Address</label>
                                    <FormGroup>
                                        <Input type="text" value={user ? user.address : ''} readOnly />
                                    </FormGroup>
                                </Col>
                                <Col md="3">
                                    <label>Town</label>
                                    <FormGroup>
                                        <Input type="text" value={user ? user.town : ''} readOnly />
                                    </FormGroup>
                                </Col>
                                <Col md="3">
                                    <label>Postal Code</label>
                                    <FormGroup>
                                        <Input type="number" value={user ? user.post_code : ''} readOnly />
                                    </FormGroup>
                                </Col>
                                <Col md="3">
                                    <label>Telephone</label>
                                    <FormGroup>
                                        <Input type="number" value={user ? user.mobile_no : ''} readOnly />
                                    </FormGroup>
                                </Col>
                                <Col md="3">
                                    <label>Email</label>
                                    <FormGroup>
                                        <Input type="email" value={user ? user.email : ''} readOnly />
                                    </FormGroup>
                                </Col>
                                <Col md="6">
                                    <label>Site Name</label>
                                    <FormGroup>
                                        <Input type="text" placeholder={siteProfile.site_name} onChange={onChange} name="site_name" value={siteProfile.site_name} />
                                    </FormGroup>
                                </Col>
                                <Col md="6">
                                    <label>Location</label>
                                    <FormGroup>
                                        <Input type="text" placeholder={siteProfile.location} onChange={onChange} name="location" value={siteProfile.location} />
                                    </FormGroup>
                                </Col>
                                <Col md="6">
                                    <label>Post Code</label>
                                    <FormGroup>
                                        <Input
                                            type="text"
                                            value={siteProfile.post_code}
                                            name="post_code"
                                            onChange={onChange}
                                            mask="aa99 9aa"
                                            tag={InputMask}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md="6">
                                    <label>Emergency Contact Name</label>
                                    <FormGroup>
                                        <Input type="text" placeholder={siteProfile.emergency_contact_no} value={siteProfile.emergency_contact_no} name="emergency_contact_no" onChange={onChange} />
                                    </FormGroup>
                                </Col>
                                <Col md="6">
                                    <label>Emergency Phone #</label>
                                    <FormGroup>
                                        <Input
                                            type="text"
                                            placeholder={siteProfile.emergency_phone_no}
                                            value={siteProfile.emergency_phone_no}
                                            name="emergency_phone_no"
                                            onChange={onChange}
                                            mask="0 99 9999 9999"
                                            tag={InputMask}
                                            onCopy={(e) => {
                                                e.clipboardData.setData('text/plain', e.target.value.substring(1));
                                                e.preventDefault();
                                            }}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md="6">
                                    <label>Out of Hours Telephone</label>
                                    <FormGroup>
                                        <Input
                                            type="text"
                                            placeholder={siteProfile.out_of_hours}
                                            value={siteProfile.out_of_hours}
                                            name="out_of_hours"
                                            onChange={onChange}
                                            mask="0 99 9999 9999"
                                            tag={InputMask}
                                            onCopy={(e) => {
                                                e.clipboardData.setData('text/plain', e.target.value.substring(1));
                                                e.preventDefault();
                                            }}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md="6">
                                    <label>Alarm Call Out Number</label>
                                    <FormGroup>
                                        <Input
                                            type="text"
                                            placeholder={siteProfile.alarm_call}
                                            value={siteProfile.alarm_call}
                                            name="alarm_call"
                                            onChange={onChange}
                                            mask="0 99 9999 9999"
                                            tag={InputMask}
                                            onCopy={(e) => {
                                                e.clipboardData.setData('text/plain', e.target.value.substring(1));
                                                e.preventDefault();
                                            }}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md="12">
                                    <RegularMap />
                                </Col>
                            </Row>


                            <div className="text-center">
                                {
                                    isLoading ? (
                                        <div class="spinner-border text-info" role="status">
                                            <span class="sr-only">Loading...</span>
                                        </div>
                                    ) : (
                                        <Button className="btn-fill" color="info" type="submit">
                                            Update
                                        </Button>
                                    )
                                }

                            </div>
                        </Form>
                    </ModalBody>


                </Modal>
            </div>

        </>
    );
}

const mapStateToProps = state => ({
    allSites: state.profileReducer.allSites,
    user: state.profileReducer.userProfile.user,
    allClients: state.profileReducer.allClients,
    sweetAlert: state.profileReducer.sweetAlert,
    isLoading: state.profileReducer.isLoading,
    deletedSite: state.profileReducer.deletedSite.message,
    addedSite: state.profileReducer.newSite.message,
    updatedSite: state.profileReducer.updatedSite.message
})

export default connect(mapStateToProps, { getAllSites, emptyUserProfile, addNewSite, updateSite, deleteSite, getUserProfile, getAllClients, removeSweetAlert })(ManageSite);






















